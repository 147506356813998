/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react-hooks/rules-of-hooks */

import React, { useState, FC } from 'react';
import { Row, Col, Divider, Typography, Select, Tooltip } from 'antd';
import { InfoCircleOutlined, InfoCircleTwoTone } from '@ant-design/icons';

import { Checkbox } from '@droidsolutions/ui-components';

import { SubPanelProps } from './SubPanelProps';
import { LocationAccuracy } from '../DeviceSettings.types';
import { useTranslation } from 'react-i18next';
import Styled from '../Settings.styled';

export const LocationPanel: FC<SubPanelProps> = (props: SubPanelProps) => {
  const { t } = useTranslation();
  /**
   * States and Updaters.
   */
  const [location_gps_active, setlocation_gps_active] = useState<boolean>(props.settings.location_gps_active);
  const [location_accuracy, setlocation_accuracy] = useState<LocationAccuracy>(props.settings.location_accuracy);

  const LocationAccuracies: LocationAccuracy[] = ['OFF', 'SENSORS', 'NETWORK', 'HIGH'];

  /**
   * Rendering.
   */
  return (
    <>
      <Divider orientation="left">
        <Typography.Text strong>Location Services</Typography.Text>
      </Divider>

      <Styled.Row>
        <Col span={4}>
          <Tooltip title={t('gps-location-service-enabled-description')}>
            <Typography.Text>{t('gps-location-service-enabled')}</Typography.Text> <InfoCircleTwoTone />
          </Tooltip>
        </Col>
        <Col span={20}>
          <Checkbox
            label=""
            checked={location_gps_active}
            onChange={(value: boolean) => {
              setlocation_gps_active(value);
              props.settings.location_gps_active = value;
              props.updateSettings(props.settings);
            }}
          />
        </Col>
      </Styled.Row>

      <Styled.Row>
        {/* TODO: location accuracy as options list */}
        <Col span={4}>
          <Tooltip title={t('location-accuracy-description')}>
            <Typography.Text>{t('location-accuracy')}</Typography.Text> <InfoCircleTwoTone />
          </Tooltip>
        </Col>
        <Col span={20}>
          <Select
            disabled={!location_gps_active}
            value={location_accuracy}
            style={{ width: 420 }}
            onChange={(value: LocationAccuracy) => {
              setlocation_accuracy(value);
              props.settings.location_accuracy = value;
              props.updateSettings(props.settings);
            }}
          >
            {LocationAccuracies.map((accuracy, i) => (
              <Select.Option key={`locationaccuracy-${i}`} value={accuracy}>
                {accuracy}
              </Select.Option>
            ))}
          </Select>
        </Col>
      </Styled.Row>
    </>
  );
};
