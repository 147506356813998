/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react-hooks/rules-of-hooks */

import React, { useState, FC } from 'react';
import { Row, Col, Divider, Typography, Select, Tooltip } from 'antd';
import { InfoCircleOutlined, InfoCircleTwoTone } from '@ant-design/icons';

import { SubPanelProps } from './SubPanelProps';
import CountryCodes from '../../../lib/iso/iso-3611.json';
import LanguageCodes from '../../../lib/iso/iso-639-1.json';
import { useTranslation } from 'react-i18next';
import Styled from '../Settings.styled';

export const LocalizationPanel: FC<SubPanelProps> = (props: SubPanelProps) => {
  const { t } = useTranslation();
  /**
   * States and Updaters.
   */
  const [locality, setlocality] = useState<string>(props.settings.locality.toUpperCase());
  const [language, setlanguage] = useState<string>(props.settings.language);

  /**
   * Rendering.
   */
  return (
    <>
      <Divider orientation="left">
        <Typography.Text strong>{t('locality-and-language')}</Typography.Text>
      </Divider>

      <Styled.Row>
        <Col span={4}>
          <Tooltip title={t('locality-description')}>
            <Typography.Text>{t('locality')}</Typography.Text> <InfoCircleTwoTone />
          </Tooltip>
        </Col>
        <Col span={20}>
          <Select
            defaultValue={locality}
            style={{ width: 420 }}
            onChange={(value: string) => {
              setlocality(value);
              props.settings.locality = value;
              props.updateSettings(props.settings);
            }}
          >
            {CountryCodes.map((countryCode, i) => (
              <Select.Option key={`countryCode-${i}`} value={countryCode['alpha-2']}>
                {countryCode.name}
              </Select.Option>
            ))}
          </Select>
        </Col>
      </Styled.Row>

      <Styled.Row>
        <Col span={4}>
          <Tooltip title={t('language-description')}>
            <Typography.Text>{t('language')}</Typography.Text> <InfoCircleTwoTone />
          </Tooltip>
        </Col>
        <Col span={20}>
          <Select
            defaultValue={language}
            style={{ width: 420 }}
            onChange={(value: string) => {
              setlanguage(value);
              props.settings.language = value;
              props.updateSettings(props.settings);
            }}
          >
            {LanguageCodes.map((languageCode, i) => (
              <Select.Option key={`languageCode-${i}`} value={languageCode.code}>
                {languageCode.name}
              </Select.Option>
            ))}
          </Select>
        </Col>
      </Styled.Row>
    </>
  );
};
