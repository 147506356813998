import React, { FC } from 'react';
import { Typography } from 'antd';

import { PageLayout } from '../shared/layout/PageLayout';
import { useTranslation } from 'react-i18next';

export const LoginUpdatePasswordPage: FC = () => {
  const { t } = useTranslation();
  return (
    <PageLayout>
      <Typography.Title level={2}>{t('change-password')}</Typography.Title>
    </PageLayout>
  );
};
