import { styled } from "../../style/theme";

export default {
  Outer: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    align-content: flex-start;
  `,

  Inner: styled.div`
    width: 350px;
    flex-basis: 24px;
    margin: 12px 12px;
  `,

  Label: styled.div`
    font-size: 12px;
    margin-bottom: 14px;
  `,
};
