/* eslint-disable max-len */
import React, { useState, FC } from 'react';
import { Col, Row, Typography, message, Tooltip } from 'antd';
import { Checkbox, Input } from '@droidsolutions/ui-components';
import { InfoCircleOutlined, InfoCircleTwoTone } from '@ant-design/icons';

import { MDMAppConfig } from './DeviceSettings.types';
import { SmallDescription } from '../shared/SmallDescription';
import { useTranslation } from 'react-i18next';

import Styled from './Settings.styled';

export interface MDMAppConfigProps {
  settings: MDMAppConfig;
  updateSettings: (updated: MDMAppConfig) => void;
}

/**
 * Panel for the MDM Configuration.
 */
export const MDMAppConfigPanel: FC<MDMAppConfigProps> = (props: MDMAppConfigProps) => {
  const { t } = useTranslation();

  // stateful field values and updaters
  const [apkUrl, setApkUrl] = useState<string>(props.settings.apk_url);
  const [packageName, setPackageName] = useState<string>(props.settings.package_name);
  const [versionCode, setVersionCode] = useState<number>(props.settings.version_code);
  const [mediaPackage, setAMP] = useState<string>(props.settings.active_media_package);
  const [kioskMode, setKioskMode] = useState<boolean>(props.settings.kiosk_mode_active);
  const [browserMode, setBrowserMode] = useState<boolean>(props.settings.browser_mode_active);
  const [mediaService, setMediaService] = useState<string>(props.settings.active_media_service || '');

  return (
    <>
      <Typography.Title level={3}>{t('mdm-app-settings')}</Typography.Title>
      <hr />

      <Styled.Row>
        <Col span={4}>
          <Tooltip title={t('kiosk-mode-enabled-description')}>
            <Typography.Text>{t('kiosk-mode-enabled')}</Typography.Text> <InfoCircleTwoTone />
          </Tooltip>
        </Col>
        <Col span={20}>
          <Checkbox
            label=""
            checked={kioskMode}
            onChange={(updated: boolean) => {
              setKioskMode(updated);
              props.settings.kiosk_mode_active = updated;

              props.updateSettings(props.settings);
            }}
          />
        </Col>
      </Styled.Row>

      <Styled.Row>
        <Col span={4}>
          <Tooltip title={t('mdm-app-browser-mode-enabled-description')}>
            <Typography.Text>{t('browser-mode-enabled')}</Typography.Text> <InfoCircleTwoTone />
          </Tooltip>
        </Col>
        <Col span={20}>
          <Checkbox
            label=""
            checked={browserMode}
            onChange={(updated: boolean) => {
              setBrowserMode(updated);
              props.settings.browser_mode_active = updated;

              props.updateSettings(props.settings);
            }}
          />
        </Col>
      </Styled.Row>

      <Styled.Row>
        <Col span={4}>
          <Tooltip title={t('mdm-app-download-url-description')}>
            <Typography.Text>{t('mdm-app-url')}</Typography.Text> <InfoCircleTwoTone />
          </Tooltip>
        </Col>
        <Col span={20}>
          <Input
            value={apkUrl}
            placeholder={t('apk-url')}
            onChange={(e) => {
              const updated = e.target.value;

              setApkUrl(updated);
              props.settings.apk_url = updated;
              props.updateSettings(props.settings);
            }}
          />
        </Col>
      </Styled.Row>

      <Styled.Row>
        <Col span={4}>
          <Tooltip title={t('mdm-app-package-id-description')}>
            <Typography.Text>{t('package-name')}</Typography.Text> <InfoCircleTwoTone />
          </Tooltip>
        </Col>
        <Col span={20}>
          <Input
            value={packageName}
            placeholder={t('package-name')}
            onChange={(e) => {
              const updated = e.target.value;
              console.log(updated);
              setPackageName(updated);
              props.settings.package_name = updated;
              props.updateSettings(props.settings);
            }}
          />
        </Col>
      </Styled.Row>

      <Styled.Row>
        <Col span={4}>
          <Tooltip title={t('mdm-app-version-code-description')}>
            <Typography.Text>{t('version-code')}</Typography.Text> <InfoCircleTwoTone />
          </Tooltip>
        </Col>
        <Col span={20}>
          <Input
            value={`${versionCode}`}
            type={'number'}
            maxLength={100}
            placeholder={t('version-code')}
            onChange={(e) => {
              const newVersionCode = e.target.value;
              console.log(newVersionCode);
              const newVersion = parseInt(newVersionCode, 10);

              if (isNaN(newVersion)) {
                message.warn(t('the-field-value-version-code-is-not-a-number'));
              } else {
                setVersionCode(newVersion);
                props.settings.version_code = newVersion;
                props.updateSettings(props.settings);
              }
            }}
          />
        </Col>
      </Styled.Row>

      <Styled.Row>
        <Col span={4}>
          <Tooltip title={t('autostart-app-by-package-id-description')}>
            <Typography.Text>{t('autostart-app-by-package-id')}</Typography.Text> <InfoCircleTwoTone />
          </Tooltip>
        </Col>
        <Col span={20}>
          <Input
            value={`${mediaPackage}`}
            placeholder={t('package-id')}
            onChange={(e) => {
              const updated = e.target.value;

              setAMP(updated);
              props.settings.active_media_package = updated;
              props.updateSettings(props.settings);
            }}
          />
        </Col>
      </Styled.Row>

      <Styled.Row>
        <Col span={4}>
          <Tooltip title={t('post-install-deeplink-url-description')}>
            <Typography.Text>{t('post-install-deeplink-url')}</Typography.Text> <InfoCircleTwoTone />
          </Tooltip>
        </Col>
        <Col span={20}>
          <Input
            value={`${mediaService}`}
            placeholder={t('deeplink-to-call-after-each-configuration-update')}
            onChange={(e) => {
              const updated = e.target.value;

              setMediaService(updated);
              props.settings.active_media_service = updated;
              props.updateSettings(props.settings);
            }}
          />
        </Col>
      </Styled.Row>
    </>
  );
};
