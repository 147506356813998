import { styled } from '../../style/theme';
import { Box, Button } from '@droidsolutions/ui-components';
import { Row } from 'antd';

export default {
  Row: styled(Row)`
    padding: 10px 0 10px 0;

    &:hover {
      background: #f8f8f8;
    }
  `,
};
