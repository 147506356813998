import { transparentize } from 'polished';
import { styled } from '../../../style/theme';

export default {
  Content: styled.div`
    display: flex;
    height: 60px;
  `,
  DashboardHeader: styled.div`
    align-items: center;
    background: ${(props) => props.theme.color.kuldigBlue};
    box-shadow: 0 3px 6px 0 ${transparentize(0.96, '#000000')};
    display: flex;
    height: ${(props) => props.theme.dashboard.header.height}px;
    justify-content: space-between;
    padding: 20px 0 20px 0;
    position: fixed;
    user-select: none;
    width: 100%;
    z-index: ${(props) => props.theme.dashboard.header.zIndex};
  `,

  Logo: styled.div`
    display: flex;
    svg {
      height: ${(props) => props.theme.dashboard.header.height}px;
      padding: 10px 0px 0px 21px;
      margin-left: 40px;
    }
    span {
      color: #fff;
      font-size: x-large;
      padding: 0 0 0 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  `,
};
