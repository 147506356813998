/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react-hooks/rules-of-hooks */

import React, { useState, FC } from 'react';
import { Row, Col, Divider, Typography, Select, InputNumber, Tooltip } from 'antd';
import { InfoCircleOutlined, InfoCircleTwoTone } from '@ant-design/icons';
import { Checkbox } from '@droidsolutions/ui-components';

import { SubPanelProps } from './SubPanelProps';
import LanguageCodes from '../../../lib/iso/iso-639-1.json';
import { Maybe } from '../../../types/Monads';
import { parseNumberField } from './configpanels.functions';
import { useTranslation } from 'react-i18next';
import Styled from '../Settings.styled';

export const AccessibilityPanel: FC<SubPanelProps> = (props: SubPanelProps) => {
  const { t } = useTranslation();
  /**
   * States and Updaters.
   */
  const [talkback_active, settalkback_active] = useState<boolean>(props.settings.accessibility.talkback_active);
  const [talkback_language, settalkback_language] = useState<string>(props.settings.accessibility.talkback_language);
  const [talkback_speed, settalkback_speed] = useState<number>(props.settings.accessibility.talkback_speed);

  /**
   * Rendering.
   */
  return (
    <>
      <Divider orientation="left">
        <Typography.Text strong>{t('accessibility')}</Typography.Text>
      </Divider>

      <Styled.Row>
        <Col span={4}>
          <Tooltip title={t('talkback-enabled-description')}>
            <Typography.Text>{t('talkback-enabled')}</Typography.Text> <InfoCircleTwoTone />
          </Tooltip>
        </Col>
        <Col span={20}>
          <Checkbox
            label=""
            checked={talkback_active}
            onChange={(value: boolean) => {
              settalkback_active(value);
              props.settings.accessibility.talkback_active = value;
              props.updateSettings(props.settings);
            }}
          />
        </Col>
      </Styled.Row>

      <Styled.Row>
        <Col span={4}>
          <Tooltip title={t('talkback-language-description')}>
            <Typography.Text>{t('talkback-language')}</Typography.Text> <InfoCircleTwoTone />
          </Tooltip>
        </Col>
        <Col span={20}>
          <Select
            defaultValue={talkback_language}
            style={{ width: 420 }}
            onChange={(value: string) => {
              settalkback_language(value);
              props.settings.accessibility.talkback_language = value;
              props.updateSettings(props.settings);
            }}
          >
            {LanguageCodes.map((languageCode, i) => (
              <Select.Option key={`languageCode-${i}`} value={languageCode.code}>
                {languageCode.name}
              </Select.Option>
            ))}
          </Select>
        </Col>
      </Styled.Row>

      <Styled.Row>
        <Col span={4}>
          <Tooltip title={t('talkback-speed-description')}>
            <Typography.Text>{t('talkback-speed')}</Typography.Text> <InfoCircleTwoTone />
          </Tooltip>
        </Col>
        <Col span={20}>
          <InputNumber
            min={0}
            max={100}
            value={talkback_speed}
            placeholder={t('talkback-speed')}
            onChange={(value: Maybe<number | string>) => {
              const actualValue: number = parseNumberField(value, 50, t('talkback-speed-parse-error'));

              settalkback_speed(actualValue);
              props.settings.accessibility.talkback_speed = actualValue;
              props.updateSettings(props.settings);
            }}
          />
        </Col>
      </Styled.Row>
    </>
  );
};
