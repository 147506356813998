import React from "react";
import { PageLayout } from "../shared/layout/PageLayout";
import { Typography } from "antd";

import { RegisterForm } from "./RegisterForm";
import Url from "../../lib/utils/Url";

export const LoginRegistrationPage = () => {
  return (
    <PageLayout>
      <Typography.Title level={2}>Sign Up</Typography.Title>
      <RegisterForm idpUrl={Url.idpBaseUrl} />
    </PageLayout>
  );
};
