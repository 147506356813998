import { animated } from "react-spring";
import { styled } from "../../style/theme";

export default {
  AnimatedLogoHeader: styled.div`
    display: flex;
    height: 84px;
    position: relative;
    width: 100%;
    box-shadow: 0 3px 6px 0 1;
  `,

  HeaderBar: styled(animated.div)`
    background: 100;
    height: 84px;
  `,

  Logo: styled.div`
    svg {
      height: 100px;
      padding: 24px 0 12px 21px;
    }
  `,
};
