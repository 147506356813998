import { LoadingSpinner } from "@droidsolutions/ui-components";
import React, { useEffect } from "react";

import { login, logout } from "../../lib/auth/auth";
import ErrorImage from "../../assets/detail_graphics_error520.svg";
import { ErrorPage } from "../shared/ErrorPage";

export interface LoginProps {
  mode: "login" | "logout";
}

export const LoginPrompt: React.FC<LoginProps> = (props: LoginProps) => {
  const [error, setError] = React.useState(false);
  const method = props.mode === "login" ? login : logout;

  useEffect(() => {
    method().catch(() => {
      setError(true);
    });
  }, [error, method]);

  if (error) {
    return (
      <ErrorPage
        image={<ErrorImage />}
        primaryText="errorBoundary.primaryText"
        secondaryText="errorBoundary.secondaryText"
      />
    );
  } else {
    return <LoadingSpinner />;
  }
};
