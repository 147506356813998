import { Time } from "@droidsolutions/ui-components/build/types/Time";

/**
 * Converts millsecond timestamps to the Time format form the @droidsolutions/ui-components library
 */
export const toTime = (timestamp: number): Time => {
  const secs = Math.floor(timestamp / 1000);
  const mins = Math.floor(secs / 60);
  const hours = Math.floor(mins / 60);

  return {
    hour: hours % 24,
    minute: mins % 60,
    second: secs % 60,
  };
};

/**
 * Converts the Time format form the @droidsolutions/ui-components library to millsecond based timestamps
 */
export const toTs = (time: Time): number => {
  const secs = time.second || 0;
  const updatedTimestamp =
    time.hour * 3600 * 1000 + time.minute * 60 * 1000 + secs * 1000;

  return updatedTimestamp;
};
