import { format } from 'date-fns';
import React, { FC, useEffect, useState } from 'react';
import { fetchDeviceMetaData } from '../deviceSettingsPage/DeviceSettings.apifunctions';
import { DeviceMetaData } from '../settings/DeviceSettings.types';
import { Row, Col, Typography } from 'antd';
import { CheckIcon } from '../shared/Icons/CheckIcon';
import { Maybe } from '../../types/Monads';
import { useTranslation } from 'react-i18next';

export interface DeviceMetaDataDisplayProps {
  span: number;
  deviceId: string;
}

export const DeviceMetaDataDisplay: FC<DeviceMetaDataDisplayProps> = (props: DeviceMetaDataDisplayProps) => {
  const { t } = useTranslation();

  const deviceId = props.deviceId;

  // variables for metadata json object
  const [active, setActive] = useState<boolean>();
  const [deviceGroup, setDeviceGroup] = useState<string>();
  const [hwId, setHwId] = useState<string>();
  const [KeyActive, setKeyActive] = useState<boolean>();
  const [osVersion, setOsVersion] = useState<string>();
  const [deviceType, setDeviceType] = useState<string>();
  const [isRegistered, setIsRegistered] = useState<boolean>();
  const [registerDate, setRegisterDate] = useState<string>();
  const [updateDate, setUpdateDate] = useState<string>();

  useEffect(() => {
    if (deviceId != undefined) {
      fetchDeviceMetaData(deviceId).then((fetchedMetaData: Maybe<DeviceMetaData>) => {
        if (fetchedMetaData) {
          setActive(fetchedMetaData.active);
          setHwId(fetchedMetaData.hwId);
          setDeviceGroup(fetchedMetaData.associatedGroup);
          setDeviceType(fetchedMetaData.type);
          setOsVersion(fetchedMetaData.osVersion);
          setKeyActive(fetchedMetaData.keyActive);
          setIsRegistered(fetchedMetaData.registered);
          setRegisterDate(format(new Date(fetchedMetaData.registeredAt), 'Pp'));
          setUpdateDate(format(new Date(fetchedMetaData.updatedAt), 'Pp'));
        }
      });
    }
  }, [deviceId]);

  return (
    <>
      <Row>
        <Col span={props.span}>
          <Typography.Text>
            {t('active')}: <CheckIcon state={active} />
          </Typography.Text>
        </Col>
        <Col span={24 - props.span} />
      </Row>

      <Row>
        <Col span={props.span}>
          <Typography.Text>
            {t('registered')}: <CheckIcon state={isRegistered} />
          </Typography.Text>
        </Col>
        <Col span={24 - props.span} />
      </Row>

      <Row>
        <Col span={props.span}>
          <Typography.Text>
            {t('device-group')}: {deviceGroup}
          </Typography.Text>
        </Col>
      </Row>

      <Row>
        <Col span={props.span}>
          <Typography.Text>
            {t('hardware-id')}: {hwId}
          </Typography.Text>
        </Col>
        <Col span={24 - props.span} />
      </Row>
      <Row>
        <Col span={props.span}>
          <Typography.Text>
            {t('os-version')}: {osVersion}
          </Typography.Text>
        </Col>
        <Col span={24 - props.span} />
      </Row>
      <Row>
        <Col span={props.span}>
          <Typography.Text>
            {t('key-active')}: <CheckIcon state={KeyActive} />
          </Typography.Text>
        </Col>
        <Col span={24 - props.span} />
      </Row>
      <Row>
        <Col span={props.span}>
          <Typography.Text>
            {t('device-type')}: {deviceType}
          </Typography.Text>
        </Col>
        <Col span={24 - props.span} />
      </Row>
      <Row>
        <Col span={props.span}>
          <Typography.Text>
            {t('registered-at')}: {registerDate}
          </Typography.Text>
        </Col>
        <Col span={24 - props.span} />
      </Row>
      <Row>
        <Col span={props.span}>
          <Typography.Text>
            {t('updated-at')}: {updateDate}
          </Typography.Text>
        </Col>
        <Col span={24 - props.span} />
      </Row>
    </>
  );
};
