import React from "react";
import { DashboardHeader } from "./DashboadHeader";
import Styled from "./PageLayout.styled";
export const PageLayout: React.FC = ({ children }) => {
  return (
    <Styled.Layout className="Layout">
      <DashboardHeader />
      <Styled.Content style={{ minWidth: 640 }}>
        <Styled.Box>{children}</Styled.Box>
      </Styled.Content>
      <Styled.Footer>
        ©{new Date().getFullYear()} DroidSolutions GmbH
      </Styled.Footer>
    </Styled.Layout>
  );
};
