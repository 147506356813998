import React from 'react';
import { ThemeProvider } from 'styled-components';
import { Normalize } from 'styled-normalize';
import { GlobalStyle } from '@droidsolutions/ui-components';

import { theme } from './style/theme';
import { Router } from './Router';
import { ContextProvider } from './lib/context/ContextProvider';
import { useTranslation } from 'react-i18next';

export const App: React.FC = () => {
  const { t } = useTranslation();
  return (
    <ContextProvider>
      <ThemeProvider theme={theme}>
        <Normalize />
        <GlobalStyle />
        <React.Suspense fallback={<span>{t('loading')}</span>}>
          <Router />
        </React.Suspense>
      </ThemeProvider>
    </ContextProvider>
  );
};
