import { styled } from '../../style/theme';

import { List } from 'antd';
import { Icon, Box, Button, Pagination, Checkbox } from '@droidsolutions/ui-components';

export default {
  Icon: styled(Icon)`
    float: left;
  `,

  Button: styled(Button)`
    width: 90px;
    margin-right: 24px;

    &:hover {
      background-color: ${(props) => props.theme.color.highlight50};
    }
  `,

  ListItem: styled(List.Item)`
    padding: 5px;
    &:hover {
      background-color: ${(props) => props.theme.color.highlight10};
    }
  `,

  EditButton: styled(Button)`
    width: 90px;
    margin-left: 24px;
    margin-bottom: 10px;
    &:hover {
      background-color: ${(props) => props.theme.color.highlight50};
    }
  `,

  Meta: styled(List.Item.Meta)`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 90px;
    margin-right: 24px;
  `,

  Box: styled(Box)`
    margin-top: 32px;
    padding: 24px;
    border: none;
  `,

  HeaderBox: styled(Box)`
    background-color: #fff;
    box-shadow: none;
    display: flex;
    flex-flow: row wrap;
    align-content: center;
    justify-content: start;
    margin-bottom: 24px;
  `,

  Spacer: styled.div`
    visibility: hidden;
    height: 53px;
    width: 100%;
  `,

  Pagination: styled(Pagination)`
    align-items: center;
    bottom: 42px;
    display: flex;
    justify-content: center;
    width: 100%;
  `,

  Checkbox: styled(Checkbox)`
    border-radius: 2px;
    min-height: 28px;
    margin-right: 55px;
    padding-left: 7px;
    padding-right: 7px;
    padding-top: 3px;
    overflow: hidden;

    label {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  `,
  SelectAllCheckbox: styled(Checkbox)`
    border-radius: 2px;
    min-height: 28px;
    padding-left: 7px;
    padding-right: 7px;
    padding-top: 3px;
    overflow: hidden;

    label {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  `,
  DeleteBox: styled(Box)`
    box-shadow: none;
    display: flex;
    flex-flow: row wrap;
    align-content: center;
    justify-content: flex-end;
  `,
};
