import { styled } from '../../style/theme';

import { List } from 'antd';
import { Button, Box } from '@droidsolutions/ui-components';

export default {
  Button: styled(Button)`
    width: 90px;
    margin-right: 24px;

    &:hover {
      background-color: ${(props) => props.theme.color.highlight50};
    }
  `,

  ListItem: styled(List.Item)`
    padding: 5px;
    &:hover {
      background-color: ${(props) => props.theme.color.highlight10};
    }
  `,

  Meta: styled(List.Item.Meta)`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 90px;
    margin-right: 24px;
  `,

  Box: styled(Box)`
    margin-top: 32px;
    padding: 24px;
    border: none;
  `,

  ActionBar: styled(Box)`
    margin-top: 24px;
    margin-left: 24px;
    box-shadow: none;
    display: flex;
    flex-flow: row wrap;
    align-content: center;
    justify-content: start;
    border: none;
  `,
};
