import React, { useEffect, useState, FC } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Row, Col, Tabs, Typography, message } from 'antd';

import { LoadingSpinner, Button, Modal, PromptModal } from '@droidsolutions/ui-components';
import { fetchDeviceGroupDetails, fetchGroupSettings, postGroupSettings } from './GroupSettings.apifunctions';

import { CheckIcon } from '../shared/Icons/CheckIcon';
import { PageLayout } from '../shared/layout/PageLayout';
import { MDMAppConfigPanel } from '../settings/MDMAppConfigPanel';
import { KioskAppConfigPanel } from '../settings/KioskAppConfigPanel';
import { BrowserSettingsPanel } from '../settings/BrowserSettingsPanel';
import { BaseSettingsPanel } from '../settings/BaseSettingsPanel';
import { MediaAppConfigPanel } from '../settings/MediaAppConfigPanel';
import { AppPermissionConfigPanel } from '../settings/AppPermissionConfigPanel';
import { DeviceConfigurationPanel } from '../settings/DeviceConfigurationPanel';

import { Maybe } from '../../types/Monads';
import {
  DeviceSettings,
  MDMAppConfig,
  KioskAppColorConfig,
  KioskAppConfig,
  BrowserConfig,
  DeviceConfigurationDTO,
  MediaAppConfig,
  AppPermissionConfig,
  KnoxConfig,
} from '../settings/DeviceSettings.types';
import Styled from './GroupSettingsPage.styled';
import Url from '../../lib/utils/Url';
import { useTranslation } from 'react-i18next';
import { KnoxConfigPanel } from '../settings/KnoxConfigPanel';

const { TabPane } = Tabs;

const uninitializedSaveErrorMessage = 'Warning, settings document is undefined!';

const postSettingsSuccessMessage = 'Successfully updated group settings!';

const postSettingsFailMessage = 'Failed to update group settings!';

// Defaults for Maybe<T> values
const kioskColorConfig: KioskAppColorConfig = {
  button_color: '#00F',
  button_text_color: '#FFF',
  background_color: '#FFF',
  text_heading_color: '#33C',
  text_normal_color: '#333',
};

const defaultKioskSettings: KioskAppConfig = {
  main_icon: '',
  main_text: '',
  show_kuldig_brand: true,
  colors: kioskColorConfig,
};

const defaultBrowserSettings: BrowserConfig = {
  browser_domain_whitelist: [],
  colors: {
    button_color: '#000000',
    background_color: '#FFF',
    navigationbar_background: '#FFF',
    navigationbar_label: '#FFF',
  },
  start_url: '',
  navigationbar_label: 'Navigation',
  show_label: true,
  navigationbar_active: false,
  navigation_button_whitelist: [],
};

export const GroupSettingsPage: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { groupSlug } = useParams<{ groupSlug: string }>();

  // varibales to handle prompt modal
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState<string>(t('an-internal-server-error-occurred'));

  // varibale to render save modal and discard modal
  const [saveModal, setSaveModal] = useState(false);

  // stateful object: main settings json
  const [settings, setSettings] = useState<DeviceSettings>();
  const [postModalActive, setPostSettingsModalActive] = useState<boolean>(false);

  // stateful params for tab-panel
  const [kioskTabActive, setKioskTabActive] = useState<boolean>(false);
  const [browserTabActive, setBrowserTabActive] = useState<boolean>(false);

  // TODO: hardware ID, device ID, type, model
  const [online, setOnline] = useState<boolean>(false);
  const [version, setVersion] = useState<number>(0);

  const [groupName, setGroupName] = useState<string>();

  useEffect(() => {
    if (settings === undefined && groupSlug !== undefined) {
      fetchGroupSettings(groupSlug).then((fetchedSettings) => {
        if (fetchedSettings) {
          setSettings(fetchedSettings);
          setOnline(fetchedSettings.online);
          setVersion(fetchedSettings.version);
          setKioskTabActive(fetchedSettings.mdm_app.kiosk_mode_active);
          setBrowserTabActive(fetchedSettings.mdm_app.browser_mode_active);
        }
      });
    }
  }, [settings, groupSlug]);

  useEffect(() => {
    if (groupName === undefined && groupSlug !== undefined) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      fetchDeviceGroupDetails(groupSlug).then((deviceGroupDetails) => {
        if (deviceGroupDetails) setGroupName(deviceGroupDetails.name);
      });
    }
  }, [groupName, groupSlug]);

  /**
   * Handles the case that the updated settings object is returned successfully and well-defined.
   */
  const handlePostSuccess = (updatedSettings: DeviceSettings) => {
    //message.success(postSettingsSuccessMessage);
    setOnline(updatedSettings.online);
    setVersion(updatedSettings.version);
    setSettings(updatedSettings);
    setSaveModal(true);
    handleModal(true, postSettingsSuccessMessage);
  };

  const handleModal = (showModal: boolean, modalText: string) => {
    setShowModal(showModal);
    setModalContent(modalText);
  };

  const handleDiscard = () => {
    setShowModal(true);
    setModalContent(t('do-you-want-to-discard-unsaved-changes'));
  };

  /**
   * POSTs the settings document to the backend.
   *
   * @param groupSlug the id of the device that should be POSTed to the backend
   * @param settings the settings JSON document
   *
   * TODO: get rid of timeout
   */
  const saveHandler = (groupSlug: Maybe<string>, settings: Maybe<DeviceSettings>): void => {
    if (groupSlug && settings) {
      setPostSettingsModalActive(true);
      setTimeout(() => {
        postGroupSettings(groupSlug, settings)
          .then((updatedSettings) => {
            updatedSettings ? handlePostSuccess(updatedSettings) : handleModal(true, postSettingsFailMessage); //message.error(postSettingsFailMessage);
          })
          .catch((err) => message.error(err))
          .finally(() => setPostSettingsModalActive(false));
      }, 5000);
    } else {
      message.error(uninitializedSaveErrorMessage);
    }
  };

  return (
    <PageLayout>
      <Typography.Title level={2}>
        {t('device-group-settings')}: {groupName}
      </Typography.Title>
      <Styled.Box>
        <Styled.Button icon="saveProject" styling="important" onClick={() => saveHandler(groupSlug, settings)}>
          {t('save')}
        </Styled.Button>
        <Styled.Button icon="closeBig" styling="important" onClick={handleDiscard}>
          {t('discard')}
        </Styled.Button>
      </Styled.Box>
      {settings ? (
        <>
          <Modal open={postModalActive}>
            <Row>
              <Col span={4} />
              <Col span={16} style={{ padding: '12px' }}>
                {/* <Typography.Title level={4}>
                  Posting Settings to Backend
                </Typography.Title>
                <Typography.Text ellipsis>Please wait...</Typography.Text> */}
              </Col>
              <Col span={4} />
            </Row>
            <LoadingSpinner />
          </Modal>

          {/** TODO: hardware ID, device ID, etc... */}
          <Row>
            <Col span={4}>
              <Typography.Text>
                {t('online')}: <CheckIcon state={online} />
              </Typography.Text>
            </Col>
            <Col span={19} />
            <Col span={1}></Col>
          </Row>

          <Row>
            <Col span={4}>
              <Typography.Text>
                {t('version')}: {version}
              </Typography.Text>
            </Col>
          </Row>

          <Tabs defaultActiveKey="1">
            <TabPane tab={t('base-settings')} key="1">
              <BaseSettingsPanel
                settings={settings}
                updateSettings={(updated: DeviceSettings) => setSettings(updated)}
              />
            </TabPane>

            <TabPane tab={t('mdm-settings')} key="2">
              <MDMAppConfigPanel
                settings={settings.mdm_app}
                updateSettings={(updated: MDMAppConfig) => {
                  settings.mdm_app = updated;
                  setSettings(settings);
                  setKioskTabActive(updated.kiosk_mode_active);
                  setBrowserTabActive(updated.browser_mode_active);
                }}
              />
            </TabPane>

            <TabPane tab={t('kiosk-settings')} key="3" disabled={!kioskTabActive}>
              <KioskAppConfigPanel
                settings={settings.kiosk_configuration || defaultKioskSettings}
                updateSettings={(updated: KioskAppConfig) => {
                  settings.kiosk_configuration = updated;
                  setSettings(settings);
                }}
              />
            </TabPane>

            <TabPane tab={t('browser-settings')} key="4" disabled={!browserTabActive}>
              <BrowserSettingsPanel
                settings={settings.browser_configuration || defaultBrowserSettings}
                updateSettings={(updated: BrowserConfig) => {
                  settings.browser_configuration = updated;
                  setSettings(settings);
                }}
              />
            </TabPane>

            <TabPane tab={t('media-settings')} key="5">
              <MediaAppConfigPanel
                settings={settings.media_apps}
                updateSettings={(updated: MediaAppConfig[]) => {
                  settings.media_apps = updated;
                  setSettings(settings);
                }}
              />
            </TabPane>

            <TabPane tab={t('permissions-settings')} key="6">
              <AppPermissionConfigPanel
                settings={settings.permissions}
                updateSettings={(updated: AppPermissionConfig[]) => {
                  settings.permissions = updated;
                  setSettings(settings);
                }}
              />
            </TabPane>

            <TabPane tab={t('hardware-configuration')} key="7">
              <DeviceConfigurationPanel
                settings={settings.configuration}
                updateSettings={(updated: DeviceConfigurationDTO) => {
                  settings.configuration = updated;
                  setSettings(settings);
                }}
              />
            </TabPane>

            <TabPane tab={t('samsung-knox-configuration')} key="8">
              <KnoxConfigPanel
                settings={
                  settings.configuration?.knox_config ?? {
                    knox_active: false,
                    prokiosk_active: false,
                    knox_license: '',
                    hardwareButtonAPP_SWITCH: { enabled: true },
                    hardwareButtonEMERGENCY: { enabled: true },
                    hardwareButtonHOME: { enabled: true },
                    hardwareButtonPOWER: { enabled: true },
                    hardwareButtonVOLUME: { enabled: true },
                    hardwareButtonPTT: { enabled: true },
                    hardwareButtonMENU: { enabled: true },
                  }
                }
                updateSettings={(updated: KnoxConfig) => {
                  if (!settings.configuration) settings.configuration = {} as DeviceConfigurationDTO;
                  settings.configuration.knox_config = updated;
                  setSettings(settings);
                }}
              />
            </TabPane>
          </Tabs>
        </>
      ) : (
        <LoadingSpinner />
      )}
      <PromptModal
        cancelButtonText={!saveModal ? t('cancel') : undefined}
        onCancel={() => setShowModal(false)}
        onClosed={() => {
          if (saveModal) {
            setSaveModal(false);
            history.go(0);
          }
        }}
        content={modalContent}
        onSubmit={() => {
          if (!saveModal) {
            setShowModal(false);
            history.push({ pathname: `/devicegroup/${groupSlug}` });
          } else {
            setShowModal(false);
          }
        }}
        open={showModal}
        submitButtonText={!saveModal ? t('yes') : t('close')}
      />
    </PageLayout>
  );
};
