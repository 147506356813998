import { styled } from "../../style/theme";
import { Box, Button } from "@droidsolutions/ui-components";

export default {
  Box: styled(Box)`
    background-color: #fff;
    box-shadow: none;
    display: flex;
    flex-flow: row wrap;
    align-content: center;
    justify-content: start;
    margin-bottom: 24px;
  `,
  Button: styled(Button)`
    margin: 10px;
  `,
};
