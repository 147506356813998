/* eslint-disable @typescript-eslint/no-non-null-assertion */

import React, { FC } from 'react';
import 'maplibre-gl/dist/maplibre-gl.css';

import { useState } from 'react';
import ReactMapGL, { Layer, LayerProps, Marker, Popup, Source } from 'react-map-gl';
// import { LatLngBounds, Map, Marker, Popup, TileLayer } from "react-leaflet";

import { GeoPoint, MonitoringInformation, StatisticsData, StatisticsResponse } from '../Statistics.types';
import { toTimeString } from '../time';
import { GeoTrackCollection } from './TrackingMap.types';
import { Unwrapped } from '../../../types/Monads';

/**
 * @returns a centroid Point for a bunch of geo points (not projected). Error Margin: 1E-9°
 * @param data the data collection
 */
const centroid = (data: GeoTrackCollection): GeoPoint => {
  const ε = 1e-9;
  const dataWithPonts = data.collection.filter((data) => data.position !== undefined);

  const lats = dataWithPonts.map((p) => p.position!.lat);
  const lons = dataWithPonts.map((p) => p.position!.lon);

  const ΣLat = lats.reduce((Σ, v) => (Σ && v ? Σ + v : ε), ε);
  const ΣLon = lons.reduce((Σ, v) => (Σ && v ? Σ + v : ε), ε);

  return {
    lat: ΣLat ? ΣLat / lats.length : 0,
    lon: ΣLon ? ΣLon / lons.length : 0,
  };
};

/**
 * Extracts a geographic points collection from
 * @param data the raw data collection
 */
const data2geoTrackCollection = (data: StatisticsData): GeoTrackCollection => {
  const baseData = Object.values(data.stats);
  const sorted = baseData.sort((a, b) => a.date - b.date);
  const mapped = sorted.map((entry: MonitoringInformation) => ({
    date: toTimeString(entry.date),
    position: entry.position
      ? {
          lat: entry.position.lat,
          lon: entry.position.lon,
        }
      : undefined,
  }));

  const filtered = mapped.filter((v) => v.position !== undefined);

  return { collection: filtered };
};

export interface TrackingMapProps {
  attributionMessage: string;
  xyzLayerUrl: string;
  zoomLvl: number;
  data: StatisticsData;
}

export const TrackingMap = (props: { data: StatisticsData }) => {
  const entries: Unwrapped<MonitoringInformation> = Object.entries(props.data.stats);
  let hasPosition = false;
  let cLat = 51.36113;
  let cLon = 12.3981;

  let lastTimestamp = 0;
  for (const [name, entry] of entries) {
    if (entry.position != null) {
      hasPosition = true;
      if (lastTimestamp < parseInt(name, 10)) {
        cLat = entry.position.lat;
        cLon = entry.position.lon;
        lastTimestamp = parseInt(name, 10);
      }
    }
  }

  //console.log(geoData, center);
  const [viewport, setViewport] = useState({
    width: '100%',
    height: 500,
    latitude: cLat,
    longitude: cLon,
    zoom: 12,
  });

  const geojson: GeoJSON.FeatureCollection = {
    type: 'FeatureCollection',
    features: [{ type: 'Feature', geometry: { type: 'Point', coordinates: [cLon, cLat] }, properties: {} }],
  };

  const layerStyle: LayerProps = {
    id: 'point',
    type: 'circle',
    paint: {
      'circle-radius': 10,
      'circle-color': '#ff0000',
    },
  };

  return (
    <ReactMapGL
      {...viewport}
      mapStyle={'https://tiles.droidmaps.de/styles/kuldig-clean/style.json'}
      onViewportChange={
        // eslint-disable-next-line max-len
        (nextViewport: any) => {
          setViewport(nextViewport);
        }
      }
    >
      {hasPosition ? (
        <Marker latitude={cLat} longitude={cLon} offsetLeft={-23} offsetTop={-48}>
          <img
            height="48"
            src="https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png"
          />
          <div style={{ color: 'white', background: 'grey', padding: '0 5px 0 5px' }}>
            {new Date(lastTimestamp).toLocaleString(undefined, { dateStyle: 'medium', timeStyle: 'medium' })}
          </div>
        </Marker>
      ) : (
        <Popup
          latitude={cLat}
          longitude={cLon}
          closeButton={false}
          closeOnClick={false}
          onClose={undefined}
          anchor="top"
        >
          <div style={{ color: 'red' }}>No Location data available!</div>
        </Popup>
      )}
    </ReactMapGL>
  );
};
/*
export const TrackingMap2: FC<TrackingMapProps> = (props: TrackingMapProps) => {
  const geoTrackCollection: GeoTrackCollection = data2geoTrackCollection(
    props.data
  );

  const center = centroid(geoTrackCollection);

  return (
    <div id="leaflet-container" style={{ height: 400, width: 900 }}>
      <Map center={[center.lat, center.lon]} zoom={props.zoomLvl}>
        <TileLayer
          minZoom={12}
          maxZoom={14}
          url={props.xyzLayerUrl}
          attribution={props.attributionMessage}
        />
        {geoTrackCollection.collection.map(
          (track) =>
            track.position && (
              <Marker position={[track.position.lat, track.position.lat]}>
                <Popup>Tracked at: {track.date}</Popup>
              </Marker>
            )
        )}
      </Map>
    </div>
  );
};
*/
