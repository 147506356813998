import { styled } from "../../style/theme";

export default {
  Content: styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-top: 63px;
  `,

  ErrorPage: styled.div`
    background: white;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    width: 100%;
  `,

  Footer: styled.div`
    height: 42px;
    user-select: none;
    padding-left: 84px;
    width: 100%;
  `,

  FooterLink: styled.a`
    color: #fff;
  `,

  Image: styled.div`
    height: 316px;
    width: 316px;
  `,

  PrimaryText: styled.div`
    font-size: 28px;
    font-weight: bold;
    text-align: center;
  `,

  SecondaryText: styled.div`
    font-size: 14px;
    line-height: 1.71;
    padding: 14px 0 63px 0;
    text-align: center;
    max-width: 420px;
  `,
};
