import React from 'react';
import { Typography } from 'antd';

import { PageLayout } from '../shared/layout/PageLayout';
import { LoginPrompt } from './LoginPrompt';
import { useTranslation } from 'react-i18next';

/**
 * This view redirects to the identity provider and removes the session token
 * in the browser and on the backend.
 */
export const LogoutPage = () => {
  const { t } = useTranslation();
  return (
    <PageLayout>
      <Typography.Title level={2}>{t('logout')}</Typography.Title>
      <LoginPrompt mode="logout" />
    </PageLayout>
  );
};
