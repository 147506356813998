module.exports = {
  "loading": "Loading...",
  "edit": "Edit",
  "select-all": "Select All",
  "cancel": "Cancel",
  "submit": "Submit",
  "close": "Close",
  "an-internal-server-error-occurred": "An internal server error occurred",
  "successfully-deleted-the-project-s": "Successfully deleted the project(s) :",
  "last-updated-at": "last updated at",
  "by": "by",
  "new-project": "New Project",
  "a-new-project-has-been-created-successfully": "A new Project has been created successfully",
  "a-project-has-been-updated-successfully": "A project has been updated successfully",
  "project-edited-successfully": "Project edited successfully",
  "number-of-groups": "Number of Groups",
  "updated-at": "Updated at",
  "number-of-devices": "Number of Devices",
  "updated-by": "Updated by",
  "description": "Description",
  "a-short-description-of-the-project": "A short description of the project.",
  "project-name": "Project Name",
  "name": "Name",
  "settings": "Settings",
  "add-a-new-project": "Add a new Project",
  "project-name-cannot-be-empty": "Project name cannot be empty",
  "project": "Project",
  "already-exists": "already exists",
  "created-successfully": "created successfully",
  "log-in": "Log In",
  "change-password": "Change Password",
  "logout": "Logout",
  "do-you-want-to-discard-unsaved-changes": "Do you want to discard unsaved changes ?",
  "device-group-settings": "Device Group Settings",
  "save": "Save",
  "discard": "Discard",
  "online": "Online",
  "version": "Version",
  "base-settings": "Base Settings",
  "mdm-settings": "MDM Settings",
  "kiosk-settings": "Kiosk Settings",
  "browser-settings": "Browser Settings",
  "media-settings": "Media Settings",
  "permissions-settings": "Permissions Settings",
  "hardware-configuration": "Hardware Configuration",
  "yes": "Yes",
  "device-settings": "Device Settings",
  "add-a-group": "Add a group",
  "active": "Active",
  "group-name": "Group Name",
  "scope": "Scope",
  "the-designation-of-the-devices-in-the-group": "The designation of the devices in the group.",
  "please-select-atleast-one-device-to-transfer": "Please select atleast one device to transfer !",
  "no-device-selected-to-transfer": "No device selected to transfer",
  "transferred-devicecount-devices-to-targetgroup": "Transferred {{devicecount}} device(s) to: {{targetgroup}}",
  "do-you-want-to-delete-count-devices": "Do you want to delete {{count}} device(s)?",
  "please-select-atleast-one-device-to-delete": "Please select atleast one device to delete!",
  "successfully-deleted-count-device-s": "Successfully deleted: {{count}} device(s)",
  "successfully-deleted-deviceid": "Successfully deleted {{deviceId}}.",
  "hardware-id": "Hardware-ID",
  "device-id": "Device-ID",
  "config-version": "Config-Version",
  "offline": "Offline",
  "last-contact": "Last contact",
  "action": "Action",
  "statistics": "Statistics",
  "are-you-sure-you-want-to-delete-this-device": "Are you sure you want to delete this device？",
  "remove-device": "Remove device",
  "transfer-device-s": "Transfer Device(s)",
  "group": "Group",
  "transfer": "Transfer",
  "remove": "Remove",
  "transferring-device": "Transferring Device",
  "not-implemented-yet": "Not implemented yet...",
  "do-you-want-to-delete-count-group-s": "Do you want to delete {{count}} group(s)?",
  "please-select-atleast-one-group-to-delete": "Please select atleast one group to delete !",
  "successfully-deleted-the-group-s": "Successfully deleted the group(s)",
  "create-new-group": "Create New Group",
  "create-group": "Create Group",
  "a-group-name": "A group name.",
  "a-short-description-of-the-group": "A short description of the group.",
  "where-should-the-devices-of-this-group-be-stored-used": "Where should the devices of this group be stored / used.",
  "device-group-name-already-exists": "Device group name already exists",
  "continue": "Continue",
  "device-groups": "Device Groups",
  "new": "New",
  "delete": "Delete",
  "device-registration": "Device Registration",
  "group-settings": "Group Settings",
  "devices": "Devices",
  "search-by-device-hw-id": "Search by device/hw id",
  "portrait": "Portrait",
  "landscape": "Landscape",
  "allow-brightness-changes": "Allow brightness changes",
  "brightness": "Brightness",
  "display-timeout": "Display Timeout",
  "display-rotation-lock": "Display rotation lock",
  "display-rotation": "Display rotation",
  "bluetooth": "Bluetooth",
  "wifi-enabled": "WIFI enabled",
  "main-wifi-network-ssid": "Main WIFI Network SSID",
  "wifi-ssid": "WiFi SSID",
  "wifi-password": "WiFi Password",
  "nfc-enabled": "NFC enabled",
  "device-properties": "Device Properties",
  "registered": "Registered",
  "type": "Type",
  "model": "Model",
  "registered-at": "Registered at",
  "device": "Device",
  "device-group": "Device Group",
  "os-version": "OS Version",
  "key-active": "Key Active",
  "device-type": "Device Type",
  "qr-code-generation-was-refused-by-the-server": "QR Code generation was refused by the server!",
  "register-a-new-device": "Register a new Device",
  "initial-network-configuration": "Initial Network Configuration",
  "wifi-type": "WiFi Type",
  "scan-qr-code-to-register": "In order to register a new mobile device on the system, please scan the following QR-Code with the <i>droid.MDM</i> Client App:",
  "create": "Create",
  "register": "Register",
  "dismiss": "Dismiss",
  "app-media-settings": "App Media Settings",
  "mdm-app-settings": "MDM App Settings",
  "kiosk-mode-enabled": "Kiosk Mode enabled",
  "browser-mode-enabled": "Browser Mode enabled",
  "mdm-app-url": "MDM App Url",
  "apk-url": "APK Url",
  "package-name": "Package Name",
  "version-code": "Version Code",
  "autostart-app-by-package-id": "Autostart App by package id",
  "package-id": "package id",
  "post-install-deeplink-url": "Post-install Deeplink URL",
  "deeplink-to-call-after-each-configuration-update": "deeplink to call after each configuration update",
  "the-field-value-version-code-is-not-a-number": "The field value 'version Code' is not a number",
  "mdm-app-download-url-description": "The download-URL for the mdm package (must resolve to an apk-file).",
  "mdm-app-package-id-description": "Android package name (also called applicationId) of the mdm app.",
  "mdm-app-version-code-description": "Android version code number -- this is used to compare with the installed apps version code for updating purposes.",
  "autostart-app-by-package-id-description": "Package ID (applicationId like de.kuldig.demo) of the app that should be autostarted while chargin. This can be used for automatic updates.",
  "kiosk-mode-enabled-description": "Indicates if the Kiosk Mode is enabled in the MDM app.",
  "mdm-app-browser-mode-enabled-description": "Indicates if the Browser Mode Feature is enabled in the MDM app.",
  "post-install-deeplink-url-description": "A Deeplink URL (referencing an app) that is queried and displayed after the installation procedure is complete. The URL must start with the protocol [http(s)://]",
  "show-kuldig-reference": "Show KULDIG reference",
  "logo-on-main-screen": "Logo on main screen",
  "text-on-main-screen": "Text on main screen",
  "colors": "Colors",
  "button-background-color": "Button background color",
  "button-text-color": "Button text color",
  "screen-background-color": "Screen background color",
  "screen-text-color": "Screen text color",
  "headline-text-color": "Headline text color",
  "headline-text-color-description": "Color of the Kiosk start page main text as a hex code.",
  "screen-text-color-description": "Color of the Kiosk start page heading as a hex code.",
  "screen-background-color-description": "Color of the Kiosk mode background as a hex code.",
  "button-text-color-description": "Color of the text on the Media-App start button as a hex code.",
  "button-background-color-description": "Color of the Kiosk app start button as a hex code.",
  "text-on-main-screen-description": "Text shown on the Kiosk start page.",
  "logo-on-main-screen-description": "URL of the main logo to show on main screen.",
  "show-kuldig-reference-description": "Indicates whether the KULDIG reference should be displayed.",
  "hardware-configuration-is-disabled-on-this-device": "Hardware configuration is disabled on this device",
  "navigation-bar-enabled": "Navigation Bar enabled",
  "navigation-bar-enabled-description": "Indicates whether the browsers navigationbar should be visible.",
  "show-label": "Show Label",
  "show-label-description": "Indicates whether the navigation bar label should be visible.",
  "start-url": "Start URL",
  "start-url-description": "URL for the browser start page. This is only a fallback URL.",
  "navigation-bar-label": "Navigation Bar Label",
  "navigation-bar-label-description": "The heading for the browser view shown in the navigation bar.",
  "allowed-domains": "Allowed domains",
  "allowed-domains-description": "Domains >not< on this list will be blocked. Subdomains are allowed by default. Please be as specific as possible! E.g. allow 'kuldig.de' will also allow 'api.kuldig.de'.",
  "navigation-buttons": "Navigation buttons",
  "navigation-buttons-description": "Valid button names are \"HOME\", \"BACK\", \"FORWARD\", and \"RELOAD\". Only those listed here will be visible.",
  "button-color": "Button Color",
  "browser-mode-kiosk-button-color-description": "Color of the KIOSK Browser Mode navigation button as a hex code.",
  "background-color": "Background Color",
  "browser-mode-background-color-description": "Color of the KISOK Browser mode background as a hex code.",
  "navigation-label-color": "Navigation Label Color",
  "navigation-label-color-description": "Color of the text of the navigation bar text as a hex code.",
  "navigation-background-color": "Navigation Background Color",
  "navigation-background-color-description": "Color of the navigation bar background as a hex code.",
  "device-enabled": "Configuration enabled",
  "device-enabled-description": "This setting determines if this configuration should be available to devices. WARNING: do not disable this!",
  "disable-native-apps": "Disable Native Apps",
  "disable-native-apps-description": "This setting determines whether the execution of native apps should be disabled on the device operating system.",
  "update-interval": "Update Interval",
  "update-interval-description": "The time in seconds between two checks for updates. (min. 1s, max. 8640s) A good value is 10s for tests and 15minutes for production.",
  "app-blocklist": "App Blocklist",
  "app-blocklist-description": "List of App-IDs (package-id) that are should be suspended on the device.",
  "allowed-apps-description": "List of App-IDs (package-id) that are should be allowed to start on the device.",
  "allowed-apps": "Allowed Apps",
  "uninstall-apps": "Uninstall Apps",
  "uninstall-apps-description": "List of App-IDs that should be uninstalled",
  "uninstall-apps-header": "App-IDs marked for deinstallation.",
  "allowed-apps-header": "Allowed App-IDs.",
  "suspended-apps-header": "Suspended App-IDs.",
  "wifi-and-interfaces": "Wifi and Interfaces",
  "wifi-settings-warning": "Be Careful: incorrect wifi settings can brick the device if handled improperly!",
  "bluetooth-enabled": "Bluetooth enabled",
  "airplane-mode-enabled": "Airplane Mode enabled",
  "main-wifi-settings": "Main WiFi settings",
  "additional-wifi-networks": "Additional WiFi networks",
  "actions": "Actions",
  "add_new_wifi_network_duplicate_error": "You cannot use this WiFi SSID as there is already a network with the same SSID configured.",
  "add-wifi-network": "Add WiFi Network",
  "wifi-brick-device-warning": "Warning, you can brick the device if you proceed.",
  "youve-been-warned-wifi-has-been-disabled": "You've been warned! Wifi has been disabled...",
  "wise-choice-wifi-has-been-enabled": "Wise choice! Wifi has been enabled...",
  "do-you-really-want-to-disable-the-wifi-network": "Do you really want to disable the Wifi Network?",
  "proceed": "Proceed",
  "system": "System",
  "system-settings-warning": "Be Careful: System settings can brick the device if handled improperly!",
  "adb-enabled": "ADB enabled",
  "show-status-bar": "Show Status bar",
  "lock-task-enabled": "Enable KIOSK Lock on MDM-Kiosk",
  "time-zone": "Time Zone",
  "do-you-really-want-to-update-the-system-timezone": "Do you really want to update the system timezone?",
  "do-you-really-want-to-disable-the-system-adb": "Do you really want to disable the system ADB?",
  "wise-choice-adb-is-set-to-active": "Wise choice, ADB is set to 'active'.",
  "wise-choice-device-adb-has-been-enabled": "Wise choice! Device ADB has been enabled...",
  "device-adb-has-been-disabled": "Device ADB has been disabled...",
  "warning-you-can-brick-the-device-if-you-proceed": "Warning, you can brick the device if you proceed.",
  "adb-enabled-description": "If ADB is enabled, you can access some of the devices settings and data over USB. This is usefull for fast configuration and device recovery.",
  "status-bar-enabled-description": "You can how or hide the status bar",
  "lock-task-enabled-description": "If KIOSK lock is enabled, you cannot access the launcher, status bar, quick settings etc. This should be set to \"enabled\" for production kiosk devices.",
  "timezone-description": "you can set the device timezone to change time settings. The current time is updated over NTP when WiFi is enabled",
  "device-system-timezone-has-been-updated": "Device system Timezone has been updated.",
  "wifi-enabled-description": "You can set hardware WiFi setting to \"enabled\" or \"disabled\". If you disable WiFi, no further updates over MDM can be made!",
  "bluetooth-enabled-description": "Enable or disable hardware bluetooth capabilities. Enable this if any app needs to use Bluetooth for ranging or communication",
  "nfc-enabled-description": "NFC can be used for interactive scenarios in Apps and can be enabled here.",
  "airplane-mode-enabled-description": "Airplane mode disables all RF communcation on the device. This can brick the device!",
  "main-wifi-description": "If WiFi is enabled, you need to configure one or multiple networks to connect to. You need to set main WiFi to keep the device operational!",
  "additional-wifi-description": "You can also define additional WiFi networks to connect to. This can be usefull for debugging or if you have different SSIDs in different rooms.",
  "gps-location-service-enabled": "GPS Location Service enabled",
  "gps-location-service-enabled-description": "You need to enable location services to access GPS or Bluetooth LE in your apps.",
  "location-accuracy": "Location Accuracy",
  "location-accuracy-description": "Android location services can use different sensors to compute location. Depending on this setting your device will have a more accurate location or less energy impact.",
  "locality": "Locality",
  "language": "Language",
  "locality-description": "You can set the main locality of the device, which will be used to format numbers, dates, etc.",
  "language-description": "The language setting will be used by your apps and web apps to automatically select the default translations.",
  "locality-and-language": "Locality and Language",
  "display": "Display",
  "brightness-override": "Brightness override",
  "landscape-mode": "Landscape mode",
  "orientation-lock": "Orientation lock",
  "display-brightness": "Display Brightness",
  "display-timeout-description": "The time in milliseconds the screen should stay ON after the last user interaction.",
  "display-brightness-description": "set the default display brightness for the device. It can be changed by users/apps unless you set brightness override to \"ON\"",
  "orientation-lock-description": "If orientation lock is enabled, the device will be locked to landscape or portrait mode, depending on if landscape mode is enabled.",
  "landscape-mode-description": "Set the devices default state to \"landscape\". You can lock this setting by  setting \"orientation lock\" to enabled",
  "brightness-override-description": "If you do not want to allow brightness changes by other apps or the user, you can set brightness override to enabled. This will override every brightness change.",
  "audio-volume-override": "Audio Volume override",
  "audio": "Audio",
  "audio-volume": "Audio Volume",
  "audio-volume-override-description": "You can forbid to change the volume using hardware buttons or using app settings. This will fix the volume to the value you set under Audio Volume",
  "audio-volume-description": "Set default audio volume",
  "accessibility": "Accessibility",
  "talkback-enabled": "Talkback enabled",
  "talkback-language": "Talkback language",
  "talkback-speed": "Talkback speed",
  "talkback-enabled-description": "Android Talkback can be enabled or disabled on devices either here or using the hardware button shortcut on each device.",
  "talkback-language-description": "You can predefine talkback language to use for Google TTS",
  "talkback-speed-description": "You can set the default talkback speach speed",
  "talkback-speed-parse-error": "Talkback speed value has the incorrect format! Value has been set to 50.",
  "edit-media-package": "Edit Media Package",
  "add-media-package": "Add Media Package",
  "add": "Add",
  "app-type": "App Type",
  "browser": "Browser",
  "native-app": "Native App",
  "app-label": "App Label",
  "display-in-mdm-kiosk": "Display in MDM KIOSK",
  "logo-url": "Logo Url",
  "update-app": "Update App",
  "app-version-code": "App Version-Code",
  "error-on-version-input-number": "Error on version input number",
  "browser-start-url": "Browser Start Url",
  "browser-package-id": "Browser Identifier",
  "mac-address": "MAC-Adress",
  "export-device-list": "Export device list",
  "knox-enabled-description": "KNOX enabled if supported by device (Samsung)",
  "knox-enabled": "KNOX enabled",
  "knox-settings": "KNOX Settings",
  "knox-btn-power-enabled-description": "POWER Button can be used to turn screen or device off",
  "knox-btn-power-enabled": "POWER Button enabled",
  "knox-btn-volume-enabled-description": "VOLUME Buttons can be used to set Volume",
  "knox-btn-volume-enabled": "VOLUME Button enabled",
  "knox-btn-ptt-enabled-description": "PTT Button on Samsung XCover5 devices (left side)",
  "knox-btn-ptt-enabled": "PTT Button enabled",
  "knox-btn-home-enabled-description": "HOME Button enabled",
  "knox-btn-home-enabled": "HOME Button enabled",
  "knox-btn-menu-enabled-description": "MENU Button enabled",
  "knox-btn-menu-enabled": "MENU Button enabled",
  "knox-btn-app-switch-enabled-description": "APP SWITCH Button enabled",
  "knox-btn-app-switch-enabled": "APP SWITCH Button enabled",
  "knox-btn-emergency-enabled-description": "EMERGENCY Button enabled (XCover Pro)",
  "knox-btn-emergency-enabled": "EMERGENCY Button enabled (XCover Pro)",
  "prokiosk-enabled-description": "ProKiosk enabled",
  "prokiosk-enabled": "ProKiosk enabled",
  "samsung-knox-configuration": "KNOX Settings",
  "reboot-periodically-enabled-description": "Reboot device each day on a given time",
  "reboot-periodically-enabled": "Reboot periodically enabled",
  "ota-update-enabled": "enable OTA Update",
  "ota-update-enabled-description": "try to update devices each day in a specific time window",
  "ota-update-start-window-description": "start time of the update window",
  "ota-start-window": "OTA Update start window",
  "ota-update-end-window-description": "end time of the update window",
  "ota-end-window": "OTA Update end window",
  "audio-equalizer-enabled-description": "enable audio equalizer",
  "audio-equalizer-enabled": "Audio equalizer",

  "audio-equalizer-bands-description": "set audio equalizer bands",
  "audio-equalizer-bands": "Audio equalizer bands"
}
;