import React, { FC } from "react";
import { Typography } from "antd";

import { AppPermissionConfig } from "./DeviceSettings.types";
import { PermissionsList } from "./permissionspanel/PermissionsList";

export interface AppPermissionConfigProps {
  settings: AppPermissionConfig[];
  updateSettings: (updated: AppPermissionConfig[]) => void;
}

export const AppPermissionConfigPanel: FC<AppPermissionConfigProps> = (
  props: AppPermissionConfigProps
) => {
  return (
    <>
      <Typography.Title level={3}>App Permissions Settings</Typography.Title>
      <hr />
      <PermissionsList
        permissions={props.settings}
        updatePermissions={(updated: AppPermissionConfig[]) =>
          props.updateSettings(updated)
        }
      />
    </>
  );
};
