import { Box } from '@droidsolutions/ui-components';
import { Layout } from 'antd';
import { styled } from '../../../style/theme';

const { Header, Content, Footer } = Layout;

export default {
  Layout: styled(Layout)`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  `,
  Header: styled(Header)`
    background-color: #09b;
    height: 110px;
  `,

  Content: styled(Content)`
    background-color: #eee;
    padding: 50px;
    margin-top: ${(props) => props.theme.creation.header.margin}px;
    flex: 2;
  `,

  Box: styled(Box)`
    padding: 24px 36px;
    border: none;
  `,

  Footer: styled(Footer)`
    border-top: 3px solid grey;
    background-color: #ddd;
    text-align: center;
  `,
};
