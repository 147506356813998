import React, { FC } from "react";
import {
  MonitoringInstalledMediaApps,
  MonitoringInformation,
} from "./Statistics.types";
import { Collapse } from "antd";

/** creates a date string from a number */
export interface StaticDataTimelineItemProps {
  index: number;
  statItem: MonitoringInformation;
}

/** concatenate the items in the MonitoringInstalledMediaApps list as strings */
const appListToString = (apps: MonitoringInstalledMediaApps[]) =>
  apps.length === 0
    ? ""
    : apps
        .map(
          (app: MonitoringInstalledMediaApps) =>
            `${app.package_name} (${app.version_code})`
        )
        .join("; ");

/** The item on the static data timeline */
export const StaticDataTimelineItem: FC<StaticDataTimelineItemProps> = (
  props: StaticDataTimelineItemProps
) => {
  const installedAppList: string = props.statItem.installed_media_apps
    ? appListToString(props.statItem.installed_media_apps)
    : "";

  return (
    <>
      <p>Build: {props.statItem.build}</p>
      <p>Config Version: {props.statItem.config_version}</p>
      <p>Network Address (MAC): {props.statItem.mac_address}</p>
      <p>OS Version: {props.statItem.os_version}</p>
      <p>Installed Apps: {installedAppList}</p>
    </>
  );
};
