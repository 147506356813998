import { Dropdown } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import config from '../../../config';
import Styled from './NavigationBar.styled';
import { checkTokenValidity } from '../../../lib/auth/auth';

export interface NavigationBarItem {
  path: string;
  label: string;
}

export interface NavigationBarProps {
  items: NavigationBarItem[];
}

export const NavigationBar = (props: NavigationBarProps) => {
  const navItems = props.items;

  const navigationMenu = (
    <Styled.Menu>
      {navItems.map((nav) => (
        <Styled.Menu.Item key={nav.label}>
          <Link to={nav.path}>{nav.label}</Link>
        </Styled.Menu.Item>
      ))}
    </Styled.Menu>
  );

  const loginMenu = (
    <Styled.Menu>
      {!checkTokenValidity() ? (
        <Styled.Menu.Item>
          <Link to="/login">
            <b>Login</b>
          </Link>
        </Styled.Menu.Item>
      ) : (
        <Styled.Menu.Item>
          <Link to="/login/logout">
            <b>Logout</b>
          </Link>
        </Styled.Menu.Item>
      )}

      {/*<Styled.Menu.Item>
        <a href={config.USER_MANAGEMENT_URL}>User Management</a>
      </Styled.Menu.Item>*/}
    </Styled.Menu>
  );

  return (
    <Styled.Box>
      <Styled.Button>
        <Link to="/">
          <b>Projects</b>
        </Link>
      </Styled.Button>

      {!checkTokenValidity() ? (
        <Styled.Button>
          <Link to="/login">
            <b>Login</b>
          </Link>
        </Styled.Button>
      ) : (
        <Styled.Button>
          <Link to="/login/logout">
            <b>Logout</b>
          </Link>
        </Styled.Button>
      )}

      {/*<Dropdown overlay={loginMenu} placement="bottomRight">
        <Styled.Button>
          <Styled.UserOutlined />
        </Styled.Button>
      </Dropdown>*/}
    </Styled.Box>
  );
};
