import React, { FC } from "react";

import Styled from "./ActionBar.styled";

export interface ActionBarProps {
  addPhone?: boolean;
  addPhoneEventHandler?: () => void;

  create?: boolean;
  createEventHandler?: () => void;

  edit?: boolean;
  editEventHandler?: () => void;

  save?: boolean;
  saveEventHandler?: () => void;

  delete?: boolean;
  deleteEventHandler?: () => void;
}

export const ActionBar: FC<ActionBarProps> = (props: ActionBarProps) => {
  const AddPhoneButton = () =>
    props.addPhone ? (
      <Styled.AddPhoneButton
        icon="phoneIconBig"
        styling="important"
        onClick={props.addPhoneEventHandler}
      >
        Add
      </Styled.AddPhoneButton>
    ) : null;

  const CreateButton = () =>
    props.create ? (
      <Styled.Button
        icon="addSmall"
        styling="important"
        onClick={props.createEventHandler}
      >
        Create
      </Styled.Button>
    ) : null;

  const EditButton = () =>
    props.edit ? (
      <Styled.Button
        icon="edit"
        styling="important"
        onClick={props.editEventHandler}
      >
        Edit
      </Styled.Button>
    ) : null;

  const SaveButton = () =>
    props.save ? (
      <Styled.Button
        icon="saveProject"
        styling="important"
        onClick={props.saveEventHandler}
      >
        Save
      </Styled.Button>
    ) : null;

  const DeleteButton = () =>
    props.delete ? (
      <Styled.DeleteButton
        icon="delete"
        styling="important"
        onClick={props.deleteEventHandler}
      >
        Delete
      </Styled.DeleteButton>
    ) : null;

  return (
    <Styled.ActionBar>
      <AddPhoneButton />
      <CreateButton />
      <EditButton />
      <SaveButton />
      <DeleteButton />
    </Styled.ActionBar>
  );
};
