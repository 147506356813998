import React from 'react';
import { Typography } from 'antd';

import { PageLayout } from '../shared/layout/PageLayout';
import { LoginPrompt } from './LoginPrompt';
import { useTranslation } from 'react-i18next';

/**
 * This view lets users create a session token from circular redirection to the
 * identity provider.
 */
export const LoginPage = () => {
  const { t } = useTranslation();
  return (
    <PageLayout>
      <Typography.Title level={2}>{t('log-in')}</Typography.Title>
      <LoginPrompt mode="login" />
    </PageLayout>
  );
};
