import React, { FC } from "react";

import { green, red } from "@ant-design/colors";
import { Device } from "../../device/Device.types";

export interface DeviceOnlineProps {
  device: Device;
}

export const DeviceOnlineIcon: FC<DeviceOnlineProps> = (
  props: DeviceOnlineProps
) => {
  const DeviceActivityState = (props: DeviceOnlineProps) =>
    props.device.online ? (
      <span style={{ color: green.primary }}>&#10687;</span>
    ) : (
      <span style={{ color: red.primary }}>&#10687;</span>
    );

  return <DeviceActivityState device={props.device} />;
};
