import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Typography } from 'antd';

import { EditorField, Checkbox } from '@droidsolutions/ui-components';

import { PageLayout } from '../shared/layout/PageLayout';
import Styled from './CreateGroupPage.styled';
import { addGroup } from './DeviceGroup.apifunctions';
import { useTranslation } from 'react-i18next';

export const CreateGroupPage = () => {
  const { t } = useTranslation();
  const { slug } = useParams<{ slug: any }>();

  const [groupName, setGroupName] = useState<string>('');
  const [groupScope, setGroupScope] = useState<string>('');
  const [groupDescription, setGroupDescription] = useState<string>('');
  const [groupActive, setGroupActive] = useState<boolean>(false);

  const handleSave = async () => {
    await addGroup({
      name: groupName,
      desc: groupDescription,
      scope: groupScope,
      active: groupActive,
      isDefault: true,
      projectId: slug,
      slug: `group-${Date.now()}`,
    });

    setTimeout(() => history.back(), 5000);
  };

  const handleDiscard = () => history.back();

  return (
    <PageLayout>
      <Typography.Title level={3}>{t('add-a-group')}</Typography.Title>
      <Styled.Outer>
        <Styled.Section>
          <Typography.Title level={4}>{t('settings')}</Typography.Title>
          <Styled.Inner>
            <Checkbox checked={groupActive} label={t('active')} onChange={(value: boolean) => setGroupActive(value)} />
          </Styled.Inner>
          <Styled.Inner>
            <EditorField
              initialContent={groupName}
              label={t('name')}
              placeholder={t('group-name')}
              onBlur={(text: string) => setGroupName(text)}
            />
          </Styled.Inner>
          <Styled.Inner>
            <EditorField
              initialContent={groupDescription}
              label={t('description')}
              placeholder={t('a-short-description-of-the-project')}
              fieldHeight={210}
              onBlur={(text: string) => setGroupDescription(text)}
            />
          </Styled.Inner>
          <Styled.Inner>
            <EditorField
              initialContent={groupScope}
              label={t('scope')}
              placeholder={t('the-designation-of-the-devices-in-the-group')}
              fieldHeight={210}
              onBlur={(text: string) => setGroupScope(text)}
            />
          </Styled.Inner>
        </Styled.Section>
      </Styled.Outer>
      <Styled.ActionBar>
        <Styled.Button icon="saveProject" styling="important" onClick={() => handleSave()}>
          {t('save')}
        </Styled.Button>
        <Styled.Button icon="closeBig" styling="important" onClick={() => handleDiscard()}>
          {t('discard')}
        </Styled.Button>
      </Styled.ActionBar>
    </PageLayout>
  );
};
