export const nop = () => undefined;

// import { Model, Server } from "miragejs";

// new Server({
//   models: {
//     device: Model,
//     devicegroup: Model,
//     project: Model
//   },

//   routes() {
//     this.urlPrefix = "https://mdm.dev.droidnet.de:443";

//     this.get("/device/by-id/:deviceId", (schema: any, request: any) => {
//       const deviceId = request.params.deviceId;
//       return schema.devices.findBy({ deviceId });
//     });

//     this.get("/devicegroup/by-name", (schema: any) => {
//       return schema.devicegroups.all().models.map((m: any) => m.attrs);
//     });

//     this.get("/devicegroup/by-name/:slug", (schema: any, request: any) => {
//       const slug = request.params.slug;

//       return schema.devicegroups.findBy({ slug });
//     });

//     this.get("/project", (schema: any) => {
//       return schema.projects.all().models.map((m: any) => m.attrs);
//     });

//     this.get("/project/:slug", (schema: any, request: any) => {
//       const slug = request.params.slug;

//       return schema.projects.findBy({ slug })?.attrs;
//     });

//     this.passthrough();
//   },

//   seeds(server: any) {
//     server.create("project", {
//       name: "Project 1",
//       desc: "This is project 1",
//       slug: "project1",
//       updatedAt: new Date().toString(),
//       updatedBy: "user1",
//       groups: [
//         {
//           slug: "group-1",
//           name: "Default Group",
//           scope: "DA / 1.OG:2A",
//           numDevices: 4
//         },
//         {
//           slug: "group-2",
//           name: "Ersatzgeräte",
//           scope: "Backup Devices",
//           numDevices: 3
//         }
//       ]
//     });
//     server.create("project", {
//       name: "Project 2",
//       desc: "This is project 2",
//       slug: "project2",
//       updatedAt: new Date().toString(),
//       updatedBy: "user2",
//       groups: [
//         {
//           slug: "group-3",
//           name: "Dauerausstellung",
//           scope: "DA / 2.OG:3A",
//           numDevices: 2
//         }
//       ]
//     });

//     server.create("devicegroup", {
//       slug: "group-1",
//       label: "Default Group",
//       scope: "DA / 1.OG:2A",
//       desc: "Geräte für alles und jeden. (1)",
//       devices: [
//         {
//           type: "Meder Smartguide",
//           model: "SG2",
//           hwId: "o9278vn089rwq093utnr0va1",
//           deviceId: "8cfc9c9a-408f-4836-8eb2-128bb1509952",
//           online: true
//         },
//         {
//           type: "Meder Smartguide",
//           model: "SG2",
//           hwId: "o922352bvwaarvw3rw3vrw31",
//           deviceId: "6c0b339b-4c93-4a4f-b768-d91631d21d3e",
//           online: false
//         },
//         {
//           type: "Android 9",
//           model: "9.1",
//           hwId: "o95tw2bat43t8sba4twe4eyv",
//           deviceId: "ab3d933c-80a8-4a14-92cd-f47e9b1eeff7",
//           online: false
//         },
//         {
//           type: "Android 9",
//           model: "9.1",
//           hwId: "q3v7890w83q0vqrbqtvw4v3a",
//           deviceId: "b68b4ea3-8656-45da-944b-132ec31a11a8",
//           online: false
//         }
//       ]
//     });

//     server.create("devicegroup", {
//       slug: "group-2",
//       label: "Ersatzgeräte",
//       scope: "Backup Devices",
//       desc: "Geräte für alles und jeden. (2)",
//       devices: [
//         {
//           type: "Android S10",
//           model: "10.1",
//           hwId: "z6548vn089rwq093utnr0va1",
//           deviceId: "3djh9c9a-408f-4836-8eb2-128bb1509952",
//           online: false
//         },
//         {
//           type: "Android 9",
//           model: "9.1",
//           hwId: "a048352bvwaarvw3rw3vrw31",
//           deviceId: "3v6b339b-4c93-4a4f-b768-d91631d21d3e",
//           online: true
//         },
//         {
//           type: "Android 9",
//           model: "9.1",
//           hwId: "u5q2890w83q0vqrbqtvw4v3a",
//           deviceId: "l37l4ea3-8656-45da-944b-132ec31a11a8",
//           online: false
//         }
//       ]
//     });

//     server.create("devicegroup", {
//       slug: "group-3",
//       label: "Dauerausstellung",
//       scope: "DA / 2.OG:3A",
//       desc: "Geräte für alles und jeden. (3)",
//       devices: [
//         {
//           type: "Meder Smartguide",
//           model: "SG2",
//           hwId: "o9278vn089rwq093utnr0va1",
//           deviceId: "8cfc9c9a-408f-4836-8eb2-128bb1509952",
//           online: true
//         },
//         {
//           type: "Meder Smartguide",
//           model: "SG2",
//           hwId: "i822352bvwaarvw3rw3vrw34",
//           deviceId: "7c0b339b-4c93-4a4f-b768-d91631d21d3c",
//           online: false
//         }
//       ]
//     });

//     server.create("device", {
//       type: "Meder Smartguide",
//       model: "SG2",
//       hwId: "o9278vn089rwq093utnr0va1",
//       deviceId: "8cfc9c9a-408f-4836-8eb2-128bb1509952",
//       online: true,
//       active: true,
//       configuration: {
//         // Helligkeit steuerbar: JA/NEIN
//         brightness_control: true,

//         // Helligkeit voreinstellung: 0 - 100%
//         brightness: 99.0,

//         // Display Timeout: in sekunden
//         disp_timeout: 3,

//         // Display rotation lock: JA/NEIN
//         disp_rotation_lock: true,

//         // Display rotation: Portrait/Landscape
//         disp_rotation: "portrait",

//         // Bluetooth: AN/AUS
//         bluetooth_active: false,

//         // WIFI: AN/AUS
//         wifi_active: false,

//         // WIFI-SSID: string
//         wifi_ssid: "SSC-219P3-22",

//         // WIFI-Password: string
//         wifi_pw: "quo5AhNgo7ohCeic0",

//         // NFC: AN/AUS
//         nfc: false
//       }
//     });

//     server.create("device", {
//       type: "Meder Smartguide",
//       model: "SG2",
//       hwId: "i822352bvwaarvw3rw3vrw34",
//       deviceId: "7c0b339b-4c93-4a4f-b768-d91631d21d3c",
//       online: true,
//       active: true,
//       configuration: {
//         brightness_control: false,
//         brightness: 50.0,
//         disp_timeout: 3,
//         disp_rotation_lock: false,
//         disp_rotation: "portrait",
//         bluetooth_active: true,
//         wifi_active: true,
//         wifi_ssid: "SSC-219P3-22",
//         wifi_pw: "quo5AhNgo7ohCeic0",
//         nfc: false
//       }
//     });
//   }
// });
