import { ProjectReference } from "../../components/project/Project.types";

export const getDisplayedProjects = (
  projects: ProjectReference[],
  searchString: string
) =>
  projects.filter((project) =>
    project.name
      .toLocaleLowerCase()
      .includes(searchString.trim().toLocaleLowerCase())
  );
