import { LoadingSpinner, NoData } from "@droidsolutions/ui-components";
import { message, Tabs, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { fetchProjectList } from "../project/Project.apifunctions";
import { ProjectReference } from "../project/Project.types";
import { ProjectList } from "../project/ProjectList";
import Styled from "../shared/ActionBar.styled";
import { PageLayout } from "../shared/layout/PageLayout";
import { CreateProjectPage } from "../project/ProjectCreatePage";
import { getDisplayedProjects } from "../../lib/utils/LandingPageUtils";

const projectSuccessMessage = "A new Project has been created successfully";
const projectUpdateMessage = "A project has been updated successfully";

const { TabPane } = Tabs;

/**
 * When a project has been updated on the server this function renders an appropriate message.
 */
const showProjectUpdateMessage = () => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const updateState = params.get("projectUpdate");

  switch (updateState) {
    case "create-success": {
      return message.success(projectSuccessMessage);
    }

    case "update-success": {
      return message.success(projectUpdateMessage);
    }
  }
};

export const LandingPage = () => {
  // Project overview

  const history = useHistory();
  const [projects, setProjects] = useState<Array<ProjectReference>>([]);
  const [dataFetched, setDataFetched] = useState<boolean>(false);
  const [creationModal, setCreationModal] = useState(false);

  const [searchString, setSearchString] = useState("");

  showProjectUpdateMessage();

  useEffect(() => {
    if (!dataFetched) {
      fetchProjectList().then((projectList) => {
        const updatedProjectList = Array.isArray(projectList)
          ? projectList
          : [];

        setProjects(updatedProjectList);
        setDataFetched(true);
      });
    }
  }, [dataFetched, projects]);

  const createProjectUrl = "/project/new";
  const createProjectEventHandler = () => history.push(createProjectUrl);
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const logoutState = params.get("state");

  /** Check if a user has logged out, and remove the token in storage */
  useEffect(() => {
    if (logoutState === "logged_out") {
      localStorage.removeItem("token");
    }
  }, [logoutState]);

  return (
    <PageLayout>
      <Tabs defaultActiveKey="1" centered>
        <TabPane tab="My Projects" key="1">
          {dataFetched ? (
            <>
              <Styled.Box>
                <Typography.Title style={{ margin: 12 }} level={2}>
                  Projects ( {projects.length} )
                </Typography.Title>

                <Styled.AddPhoneButton
                  icon="addSmall"
                  styling="important"
                  // onClick={() => createProjectEventHandler()}
                  onClick={() => setCreationModal(true)}
                >
                  New Project
                </Styled.AddPhoneButton>

                <Styled.SearchBox>
                  <Styled.Input
                    onChange={(event) => setSearchString(event.target.value)}
                    placeholder={"Search Projects..."}
                    value={searchString}
                  />
                </Styled.SearchBox>
              </Styled.Box>

              <ProjectList
                projects={
                  projects !== null
                    ? getDisplayedProjects(projects, searchString)
                    : []
                }
              />

              <CreateProjectPage
                showParentModal={creationModal}
                closeParentModal={() => {
                  setCreationModal(false);
                }}
              />
            </>
          ) : (
            <LoadingSpinner />
          )}
        </TabPane>
        <TabPane tab="Statisitcs" key="2">
          <Typography.Paragraph>
            Links to different statistics collection pages
          </Typography.Paragraph>
          <NoData type="bar" />
        </TabPane>
      </Tabs>
    </PageLayout>
  );
};
