import React, { FC, useState } from 'react';
import { List, Modal, Row, Col, Select, Typography, Table, message } from 'antd';
import { ColumnsType } from 'antd/es/table/index';

import { WifiListProps } from './WifiList.types';
import { WifiNetwork, WifiNetworkType } from '../DeviceSettings.types';
import { Button, Checkbox, Input } from '@droidsolutions/ui-components';

import { wifiNetworkTypes } from '../../../lib/utils/WifiNetworkTypes';
import { DeleteButton } from '../../shared/Buttons/DeleteButton';
import { EditButton } from '../../shared/Buttons/EditButton';
import { useTranslation } from 'react-i18next';

const addWifiTitle = 'Add new Wifi Network';
const editWifiTitle = 'Edit Wifi Network';
const listHeader = 'Enabled Wifi Networks';

const defaultWifi: WifiNetwork = {
  wifi_type: 'WPA',
  wifi_pw: '',
  wifi_ssid: '',
};

export const WifiList: FC<WifiListProps> = (props: WifiListProps) => {
  const { t } = useTranslation();

  /**
   * States
   */
  const [wifi_modal, set_wifi_modal] = useState<boolean>(false);
  const [wifi_modal_edit, set_wifi_modal_edit] = useState<boolean>(false);
  const [wifiModalEditIndex, setWifiModalEditIndex] = useState<number>(-1);

  const [new_wifi_ssid, set_new_wifi_ssid] = useState<string>('');
  const [new_wifi_type, set_new_wifi_type] = useState<WifiNetworkType>('WPA');
  const [new_wifi_pw, set_new_wifi_pw] = useState<string>('');

  const [currentWifiNetworks, setCurrentWifiNetworks] = useState<WifiNetwork[]>(props.wifis);

  /**
   * Event Handlers
   */
  const addWifiHandler = () => {
    set_wifi_modal(true);
    set_wifi_modal_edit(false);
    setWifiModalEditIndex(-1);

    set_new_wifi_ssid('');
    set_new_wifi_pw('');
    set_new_wifi_type('WPA');
  };

  const editWifiHandler = (i: number) => {
    const newWifi: WifiNetwork =
      currentWifiNetworks && currentWifiNetworks.length >= i ? currentWifiNetworks[i] : defaultWifi;

    set_new_wifi_ssid(newWifi.wifi_ssid);
    set_new_wifi_pw(newWifi.wifi_pw);
    set_new_wifi_type(newWifi.wifi_type || 'WPA');

    set_wifi_modal(true);
    set_wifi_modal_edit(true);
    setWifiModalEditIndex(i);
  };

  const deleteWifiHandler = (i: number) => {
    const newList: Array<WifiNetwork> = [...currentWifiNetworks] || [];
    if (i > -1 && !isNaN(i)) {
      newList.splice(i, 1);

      setTimeout(() => props.onAppend([...newList]), 10);
      setCurrentWifiNetworks(newList);
    }
  };

  const columns: ColumnsType<WifiNetwork> = [
    {
      title: t('wifi-ssid'),
      dataIndex: 'wifi_ssid',
      sorter: (a: WifiNetwork, b: WifiNetwork) => {
        if (a.wifi_ssid < b.wifi_ssid) return -1;
        if (a.wifi_ssid > b.wifi_ssid) return 1;
        return 0;
      },
    },
    {
      title: t('wifi-type'),
      dataIndex: 'wifi_type',
      sorter: (a: WifiNetwork, b: WifiNetwork) => {
        if (a.wifi_type < b.wifi_type) return -1;
        if (a.wifi_type > b.wifi_type) return 1;
        return 0;
      },
    },
    {
      title: t('actions'),
      dataIndex: 'wifi_ssid',
      render: (text, wifi) => {
        const index = currentWifiNetworks.findIndex((w: WifiNetwork) => w.wifi_ssid === wifi.wifi_ssid);
        if (index < 0) return null;
        return (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <EditButton index={index} onClick={() => editWifiHandler(index)} />
            <DeleteButton index={index} onClick={() => deleteWifiHandler(index)} />
          </div>
        );
      },
    },
  ];

  /**
   * Rendering:
   */
  return (
    <>
      <Modal
        title={wifi_modal_edit ? editWifiTitle : addWifiTitle}
        visible={wifi_modal}
        onCancel={() => {
          set_wifi_modal(false);
        }}
        onOk={() => {
          const index = currentWifiNetworks.findIndex((w: WifiNetwork) => w.wifi_ssid == new_wifi_ssid);
          const newList = [...currentWifiNetworks];

          if (!wifi_modal_edit) {
            if (index > -1) {
              message.error(t('add_new_wifi_network_duplicate_error'));
              set_wifi_modal(false);
              return;
            }
            // new wifi is being added
            newList.push({
              wifi_ssid: new_wifi_ssid,
              wifi_pw: new_wifi_pw,
              wifi_type: new_wifi_type,
            });
            props.onAppend([...newList]);
            setCurrentWifiNetworks(newList);

            set_wifi_modal(false);
            return;
          }

          if (wifi_modal_edit) {
            if (index === -1 || index === wifiModalEditIndex) {
              // we simply alter wifi
              newList[wifiModalEditIndex] = {
                wifi_ssid: new_wifi_ssid,
                wifi_pw: new_wifi_pw,
                wifi_type: new_wifi_type,
              };
              props.onAppend([...newList]);
              setCurrentWifiNetworks(newList);
              set_wifi_modal(false);
              return;
            }
            // @todo show conflict error
            message.error(t('add_new_wifi_network_duplicate_error'));
            set_wifi_modal(false);
            return;
          }

          set_wifi_modal(false);
        }}
      >
        <Row>
          <Col span={6}>
            <b>{t('wifi-ssid')}</b>
          </Col>
          <Col span={12}>
            <Input
              type="text"
              placeholder={t('wifi-ssid')}
              value={new_wifi_ssid}
              width={256}
              onChange={(evt) => set_new_wifi_ssid(evt.target.value)}
            />
          </Col>
        </Row>
        <Row>
          <Col span={6}>{t('wifi-password')}</Col>
          <Col span={12}>
            <Input
              type="text"
              placeholder={t('wifi-password')}
              value={new_wifi_pw}
              width={256}
              onChange={(evt) => set_new_wifi_pw(evt.target.value)}
            />
          </Col>
        </Row>
        <Row>
          <Col span={6}>{t('wifi-type')}</Col>
          <Col span={12}>
            <Select
              defaultValue={new_wifi_type}
              style={{ width: 256 }}
              onChange={(value: WifiNetworkType) => set_new_wifi_type(value)}
            >
              {wifiNetworkTypes.map((networkType: WifiNetworkType) => (
                <Select.Option key={`wifi-network-type-${networkType}`} value={networkType}>
                  {networkType}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
      </Modal>

      <Button onClick={() => addWifiHandler()} icon="addSmall">
        {t('add-wifi-network')}
      </Button>

      <Table columns={columns} rowKey="wifi_ssid" dataSource={currentWifiNetworks}></Table>
    </>
  );
};
