import React, { FC } from "react";
import { Button } from "@droidsolutions/ui-components";

import { AnimatedLogoHeader } from "./AnimatedLogoHeader";
import Styled from "./ErrorPage.styled";

export interface ErrorPageProps {
  buttonText?: string;
  footer?: boolean;
  image: JSX.Element;
  onClick?: () => void;
  primaryText?: string;
  secondaryText?: string;
}

export const ErrorPage: FC<ErrorPageProps> = ({
  buttonText,
  footer = true,
  image,
  onClick,
  primaryText,
  secondaryText,
}) => {
  return (
    <Styled.ErrorPage data-testid="error-page">
      <AnimatedLogoHeader fadeOut />
      <Styled.Content>
        <Styled.Image>{image}</Styled.Image>
        {primaryText && <Styled.PrimaryText>{primaryText}</Styled.PrimaryText>}
        {secondaryText && (
          <Styled.SecondaryText>{secondaryText}</Styled.SecondaryText>
        )}
        {onClick && buttonText && (
          <Button onClick={onClick} styling="blue">
            {buttonText}
          </Button>
        )}
      </Styled.Content>
      {footer && (
        <Styled.Footer>
          errorPage.footerText
          <Styled.FooterLink href="mailto:support@apyon.de">
            errorPage.footerLink
          </Styled.FooterLink>
        </Styled.Footer>
      )}
    </Styled.ErrorPage>
  );
};
