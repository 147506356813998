/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import React, { useState, FC } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Select, Typography, message, Modal, Row, Col } from 'antd';
import { Button, Input } from '@droidsolutions/ui-components';

import Styled from './DeviceRegistrationPage.styled';

import Url from '../../lib/utils/Url';
import { QRCode } from '../qrcode/QRCode';
import { PageLayout } from '../shared/layout/PageLayout';
import { DeviceRegistered } from '../shared/DeviceRegistered/DeviceRegistered';
import { putQRCode, newRegistrationHandler } from './Device.apifunctions';
import { FileDownloadButton } from '../shared/Buttons/FileDownloadButton';
import { WifiNetworkType } from '../settings/DeviceSettings.types';
import { wifiNetworkTypes } from '../../lib/utils/WifiNetworkTypes';
import { useTranslation } from 'react-i18next';

export const DeviceRegistrationPage: FC = () => {
  const { t } = useTranslation();

  const { groupslug } = useParams<{ groupslug: string }>();

  const history = useHistory();

  const [registered, setRegistered] = useState<boolean>(false);
  const [qrEnabled, setEnableQr] = useState<boolean>(false);
  const [qrCode, setQrCode] = useState<string>('');
  const [registrationId, setRegistrationId] = useState<string>('');

  const [wifi_ssid, setwifi_ssid] = useState<string>('');
  const [wifi_pw, setwifi_pw] = useState<string>('');
  const [wifi_type, setwifi_type] = useState<WifiNetworkType>('WPA');

  const registerHandler = () => newRegistrationHandler(qrCode, setRegistered, registrationId);

  const dismissHandler = () => history.goBack();

  const [postModalActive, setPostSettingsModalActive] = useState<boolean>(false);

  const modalOk = async () => {
    try {
      const qrFromBackend = await putQRCode(groupslug || 'group', setRegistrationId, wifi_ssid, wifi_pw, wifi_type);
      setQrCode(qrFromBackend);
      setEnableQr(true);
    } catch (err) {
      message.error(err.message || t('qr-code-generation-was-refused-by-the-server'));
    }
    setPostSettingsModalActive(false);
  };

  const modalCancel = () => {
    setwifi_ssid('');
    setwifi_pw('');
    setwifi_type('WPA');
    setPostSettingsModalActive(false);
  };

  return (
    <PageLayout>
      <Typography.Title level={4}>{t('register-a-new-device')}</Typography.Title>

      <Modal title={t('initial-network-configuration')} visible={postModalActive} onOk={modalOk} onCancel={modalCancel}>
        <Row>
          <Col span={8}>{t('wifi-ssid')}</Col>
          <Col span={16}>
            <Input
              type="text"
              placeholder={t('wifi-ssid')}
              value={wifi_ssid}
              width={256}
              onChange={(evt) => setwifi_ssid(evt.target.value)}
            />
          </Col>
        </Row>
        <Row>
          <Col span={8}>{t('wifi-password')}</Col>
          <Col span={16}>
            <Input
              type="password"
              placeholder={t('wifi-password')}
              value={wifi_pw}
              width={256}
              onChange={(evt) => setwifi_pw(evt.target.value)}
            />
          </Col>
        </Row>

        <Row>
          <Col span={8}>
            <Typography.Text>{t('wifi-type')}</Typography.Text>
          </Col>
          <Col span={16}>
            <Select
              defaultValue={wifi_type}
              style={{ width: 256 }}
              onChange={(value: WifiNetworkType) => setwifi_type(value)}
            >
              {wifiNetworkTypes.map((networkType) => (
                <Select.Option key={`wifi-network-${networkType}`} value={networkType}>
                  {networkType}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
      </Modal>

      <Styled.Box>
        <p>{t('scan-qr-code-to-register')}</p>

        <QRCode enabled={qrEnabled} code={qrCode} />
      </Styled.Box>

      <DeviceRegistered registered={registered} />

      <Styled.Box>
        <Button icon="description" onClick={() => setPostSettingsModalActive(true)}>
          {t('create')}
        </Button>
        <Button icon="copyFile" disabled={!qrEnabled} onClick={registerHandler}>
          {t('register')}
        </Button>
        <FileDownloadButton
          disabled={!qrEnabled}
          reference={groupslug}
          buttonText={'Download Configuration'}
          downloadUrl={Url.api(`registration/qrcode/${groupslug}`)}
        />
        <Button icon="closeBig" onClick={dismissHandler}>
          {t('dismiss')}
        </Button>
      </Styled.Box>
    </PageLayout>
  );
};
