import React, { useState, FC, useEffect } from 'react';
import { Row, Col, Modal, Typography, InputNumber, Table, Tooltip, Switch } from 'antd';
import { ColumnsType } from 'antd/es/table/index';

import { Button, Checkbox, Input } from '@droidsolutions/ui-components';

import { MediaAppConfig } from '../DeviceSettings.types';

import { DeleteButton } from '../../shared/Buttons/DeleteButton';
import { EditButton } from '../../shared/Buttons/EditButton';
import { CheckIcon } from '../../shared/Icons/CheckIcon';
import { Maybe } from '../../../types/Monads';
import { parseNumberField } from '../configpanels/configpanels.functions';
import { useTranslation } from 'react-i18next';
import { MediaAppConfigPanel } from '../MediaAppConfigPanel';

import Styled from './AddMediaAppModal.styled';

interface AddMediaModalPropsInterface {
  visible: boolean;
  edit: boolean;
  config: MediaAppConfig;
  onFinish: (config: MediaAppConfig) => void;
  onCancel: () => void;
}

export const AddMediaAppModal = (props: AddMediaModalPropsInterface) => {
  const { t } = useTranslation();

  const [config, setConfig] = useState<MediaAppConfig>({ ...props.config });

  useEffect(() => {
    setConfig(props.config);
  }, [props.config]);

  return (
    <Modal
      visible={props.visible}
      title={props.edit ? t('edit-media-package') : t('add-media-package')}
      onOk={() => {
        props.onFinish(config);
      }}
      onCancel={() => {
        props.onCancel();
      }}
      okText={props.edit ? t('edit') : t('add')}
      cancelText={t('cancel')}
    >
      <Styled.Row>
        <Col span={8}>
          <Typography.Text>{t('app-type')}</Typography.Text>
        </Col>
        <Col span={16}>
          <Styled.Switch
            checkedChildren={t('browser')}
            unCheckedChildren={t('native-app')}
            checked={config.browser_mode_active || false}
            onChange={(updated: boolean) => setConfig({ ...config, browser_mode_active: updated })}
          />
        </Col>
      </Styled.Row>
      <Styled.Row>
        <Col span={8}>
          <Typography.Text>{t('app-label')}</Typography.Text>
        </Col>
        <Col span={16}>
          <Input
            type="text"
            placeholder={t('app-label')}
            value={config.app_label || ''}
            onChange={(evt) => setConfig({ ...config, app_label: evt.target.value })}
          />
        </Col>
      </Styled.Row>
      <Styled.Row>
        <Col span={8}>
          <Typography.Text>{t('display-in-mdm-kiosk')}</Typography.Text>
        </Col>
        <Col span={16}>
          <Checkbox
            label=""
            checked={config.display_in_kiosk || false}
            onChange={(updated: boolean) => setConfig({ ...config, display_in_kiosk: updated })}
          />
        </Col>
      </Styled.Row>
      <Styled.Row>
        <Col span={8}>
          <Typography.Text>{t('logo-url')}</Typography.Text>
        </Col>
        <Col span={16}>
          <Input
            type="text"
            placeholder={t('logo-url')}
            value={config.logo_url}
            onChange={(evt) => setConfig({ ...config, logo_url: evt.target.value })}
          />
        </Col>
      </Styled.Row>
      {config.browser_mode_active == false ? (
        <>
          <Styled.Row>
            <Col span={8}>
              <Typography.Text>{t('update-app')}</Typography.Text>
            </Col>
            <Col span={16}>
              <Checkbox
                label=""
                checked={config.update}
                onChange={(updated: boolean) => setConfig({ ...config, update: updated })}
              />
            </Col>
          </Styled.Row>
          <Styled.Row>
            <Col span={8}>
              <Typography.Text>{t('package-id')}</Typography.Text>
            </Col>
            <Col span={16}>
              <Input
                type="text"
                placeholder={t('package-id')}
                value={config.package_name}
                onChange={(evt) => setConfig({ ...config, package_name: evt.target.value })}
              />
            </Col>
          </Styled.Row>
          <Styled.Row>
            <Col span={8}>
              <Typography.Text>{t('app-version-code')}</Typography.Text>
            </Col>
            <Col span={16}>
              <InputNumber
                min={0}
                max={Number.MAX_VALUE}
                value={config.version_code}
                placeholder="Version"
                onChange={(value: Maybe<number | string>) => {
                  const actualValue: number = parseNumberField(value, 1, t('error-on-version-input-number'));

                  setConfig({ ...config, version_code: actualValue });
                }}
              />
            </Col>
          </Styled.Row>
          <Styled.Row>
            <Col span={8}>
              <Typography.Text>{t('apk-url')}</Typography.Text>
            </Col>
            <Col span={16}>
              <Input
                type="text"
                placeholder={t('apk-url')}
                value={config.apk_url}
                onChange={(evt) => setConfig({ ...config, apk_url: evt.target.value })}
              />
            </Col>
          </Styled.Row>
        </>
      ) : (
        <>
          <Styled.Row>
            <Col span={8}>
              <Typography.Text>{t('browser-package-id')}</Typography.Text>
            </Col>
            <Col span={16}>
              <Input
                type="text"
                placeholder={t('browser-package-id')}
                value={config.package_name}
                onChange={(evt) => setConfig({ ...config, package_name: evt.target.value })}
              />
            </Col>
          </Styled.Row>
          <Styled.Row>
            <Col span={8}>
              <Typography.Text>{t('browser-start-url')}</Typography.Text>
            </Col>
            <Col span={16}>
              <Input
                type="text"
                placeholder={t('browser-start-url')}
                value={config.browser_url || ''}
                onChange={(evt) => setConfig({ ...config, browser_url: evt.target.value })}
              />
            </Col>
          </Styled.Row>
        </>
      )}
    </Modal>
  );
};
