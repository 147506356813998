import React, { FC, useEffect, useState } from 'react';
import { List, Modal, Typography, Row, Col } from 'antd';
import { useHistory } from 'react-router-dom';
import { Icon, EditorField, Checkbox, PromptModal } from '@droidsolutions/ui-components';

import { DeviceGroupReference } from './DeviceGroup.types';
import { ProjectDetail } from '../project/Project.types';

import Styled from './DeviceGroupListEditable.styled';
import Url from '../../lib/utils/Url';
import { addNewDeviceGroup, deleteMultipleGroups, removeGroup } from './DeviceGroup.apifunctions';
import { fetchProjectDetail } from '../project/Project.apifunctions';
import { useTranslation } from 'react-i18next';

export interface DeviceGroupListEditableProps {
  project: ProjectDetail;
}

export const DeviceGroupListEditable: FC<DeviceGroupListEditableProps> = (props: DeviceGroupListEditableProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const groups = props.project.groups;

  const [showDuplicateDeviceNameModal, setShowDuplicateDeviceNameModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState<string>(t('an-internal-server-error-occurred'));

  const [create_group_modal, set_create_group_modal] = useState<boolean>(false);
  const [new_group_active, set_new_group_active] = useState<boolean>(false);
  const [new_group_name, set_new_group_name] = useState<string>('');
  const [new_group_description, set_new_group_description] = useState<string>('');
  const [new_group_scope, set_new_group_scope] = useState<string>('');

  const [groupsMultipleAction, setGroupsMultipleAction] = useState<string[]>([]);
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
  const [selectAllCheckBox, setSelectAllCheckbox] = useState<boolean>(false);

  const groupEditHandler = (g: DeviceGroupReference) => () => {
    const deviceGroupEditUrl = `/devicegroup/${g.slug}/edit`;
    history.push(deviceGroupEditUrl);
  };

  const deleteButtonHandler = () => {
    if (groupsMultipleAction.length > 0) {
      setModalContent(t('do-you-want-to-delete-count-group-s', { count: groupsMultipleAction.length }));
      setShowModal(true);
      setDeleteConfirmModal(true);
    } else {
      setModalContent(t('please-select-atleast-one-group-to-delete'));
      setShowModal(true);
    }
  };

  const handleSelectAllCheckBoxChange = () => {
    const addGroups: string[] = [];

    if (!selectAllCheckBox) {
      setSelectAllCheckbox(true);
      groups.forEach((g) => addGroups.push(g.slug));
      setGroupsMultipleAction(addGroups);
    } else {
      setSelectAllCheckbox(false);
      setGroupsMultipleAction([]);
    }
  };

  const handleCheckBoxChange = (g: DeviceGroupReference) => {
    if (groupsMultipleAction.includes(g.slug)) {
      setGroupsMultipleAction(groupsMultipleAction.filter((group) => group !== g.slug));
    } else {
      setGroupsMultipleAction([...groupsMultipleAction, g.slug]);
    }
  };

  const handleModal = (modalOpen: boolean, modalText: string) => {
    setModalContent(modalText);
    setShowModal(modalOpen);
    setDeleteConfirmModal(false);
  };
  const groupDeleteHandler = () => {
    const failMessage = t('an-internal-server-error-occurred');

    deleteMultipleGroups(groupsMultipleAction).then((deleteResponse) => {
      if (deleteResponse.deleted) {
        handleModal(true, `${t('successfully-deleted-the-group-s')}: ${groupsMultipleAction.length}`);
      } else if (deleteResponse.status === 405) {
        handleModal(true, deleteResponse.message);
      } else {
        handleModal(true, failMessage || deleteResponse.message);
      }
    });
  };

  const checkIfDeviceNameAlreadyExists = () => {
    return groups.some((g) => new_group_name === g.name);
  };

  const clearModalFields = () => {
    set_new_group_active(false);
    set_new_group_name('');
    set_new_group_scope('');
    set_new_group_description('');
  };

  // Pagination
  const maxTiles = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPages, setMaxPages] = useState(1);

  useEffect(() => {
    setMaxPages(Math.ceil(groups.length / maxTiles));
  }, [groups]);

  const displayedGroups = groups.slice(maxTiles * (currentPage - 1), currentPage * maxTiles);

  return (
    <>
      <Modal
        centered
        visible={create_group_modal}
        title={t('create-new-group')}
        onOk={async () => {
          // eslint-disable-next-line no-console
          console.log('create new group for project', props.project);
          if (checkIfDeviceNameAlreadyExists()) {
            setShowDuplicateDeviceNameModal(true);
          } else {
            const success = await addNewDeviceGroup({
              name: new_group_name,
              desc: new_group_description,
              scope: new_group_scope,
              active: new_group_active,
              slug: `group-${props.project.slug}-${Date.now()}`,
              projectId: props.project.slug,
              isDefault: false,
            });

            if (success) {
              history.go(0);
            } else {
              set_create_group_modal(false);
            }
          }
        }}
        onCancel={() => {
          clearModalFields();
          set_create_group_modal(false);
        }}
        okText={t('create-group')}
        cancelText={t('cancel')}
      >
        <Row>
          <Col span={6}>
            <Typography.Text>{t('active')}</Typography.Text>
          </Col>
          <Col span={18}>
            <Checkbox
              label=""
              checked={new_group_active}
              onChange={(updated: boolean) => set_new_group_active(updated)}
            />
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <Typography.Text>{t('name')}</Typography.Text>
          </Col>
          <Col span={18}>
            <EditorField
              placeholder={t('a-group-name')}
              initialContent={new_group_name}
              onBlur={(updated: string) => set_new_group_name(updated)}
            />
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <Typography.Text>{t('description')}</Typography.Text>
          </Col>
          <Col span={18}>
            <EditorField
              placeholder={t('a-short-description-of-the-group')}
              initialContent={new_group_description}
              fieldHeight={120}
              onBlur={(updated: string) => set_new_group_description(updated)}
            />
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <Typography.Text>{t('scope')}</Typography.Text>
          </Col>
          <Col span={18}>
            <EditorField
              placeholder={t('where-should-the-devices-of-this-group-be-stored-used')}
              initialContent={new_group_scope}
              fieldHeight={120}
              onBlur={(updated: string) => set_new_group_scope(updated)}
            />
          </Col>
        </Row>
        <PromptModal
          cancelButtonText={t('cancel')}
          onCancel={() => {
            setShowDuplicateDeviceNameModal(false);
          }}
          content={`${t('device-group-name-already-exists')} : ${new_group_name}`}
          onSubmit={async () => {
            const success = await addNewDeviceGroup({
              name: new_group_name,
              desc: new_group_description,
              scope: new_group_scope,
              active: new_group_active,
              slug: `group-${props.project.slug}-${Date.now()}`,
              projectId: props.project.slug,
              isDefault: false,
            });

            if (success) {
              history.go(0);
            } else {
              setShowDuplicateDeviceNameModal(false);
            }
          }}
          open={showDuplicateDeviceNameModal}
          submitButtonText={t('continue')}
        />
      </Modal>

      <Styled.Box>
        <Styled.HeaderBox>
          <Typography.Title level={4}>{t('device-groups')}</Typography.Title>
          <Styled.Button
            icon="addSmall"
            styling="important"
            onClick={() => {
              clearModalFields();
              set_create_group_modal(true);
            }}
          >
            {t('new')}
          </Styled.Button>
          <Styled.DeleteBox>
            <Styled.Button icon="delete" onClick={() => deleteButtonHandler()}>
              {t('delete')}
            </Styled.Button>
            <Styled.SelectAllCheckbox
              label={t('select-all')}
              checked={selectAllCheckBox}
              onChange={() => handleSelectAllCheckBoxChange()}
            />
          </Styled.DeleteBox>
        </Styled.HeaderBox>
        <List>
          {displayedGroups.map((g) => (
            <Styled.ListItem key={g.slug}>
              <Styled.Meta avatar={<Icon icon="duplicate" color="black" />} title={Url.slug2link(g, 'devicegroup')} />
              <Styled.EditButton icon="edit" onClick={groupEditHandler(g)}>
                {t('edit')}
              </Styled.EditButton>
              <Styled.Checkbox
                label={''}
                checked={groupsMultipleAction.includes(g.slug)}
                onChange={() => {
                  handleCheckBoxChange(g);
                }}
              />
            </Styled.ListItem>
          ))}
          {
            /* if this is the last page, fill up the rest of the slots 
      with empty elements so the row is aligned on the left */
            maxTiles - displayedGroups.length > 0 &&
              groups.length > 0 &&
              Array.from({ length: maxTiles - displayedGroups.length }, () => Math.random()).map((_tile, index) => (
                <Styled.Spacer key={index} />
              ))
          }
          {groups.length > 0 && (
            <Styled.Pagination currentPage={currentPage} total={maxPages} onChange={(page) => setCurrentPage(page)} />
          )}
        </List>
      </Styled.Box>
      <PromptModal
        onClosed={() => !deleteConfirmModal && history.go(0)}
        content={modalContent}
        onSubmit={deleteConfirmModal ? () => groupDeleteHandler() : () => setShowModal(false)}
        open={showModal}
        submitButtonText={deleteConfirmModal ? t('submit') : t('close')}
      />
    </>
  );
};
