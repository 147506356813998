import React, { FC } from "react";
import { red, green } from "@ant-design/colors";

export interface DeviceRegisteredProps {
  state?: boolean;
}

export const CheckIcon: FC<DeviceRegisteredProps> = (
  props: DeviceRegisteredProps
) => {
  if (props.state) {
    return <span style={{ color: green.primary }}>✓</span>;
  } else {
    return <span style={{ color: red.primary }}>✗</span>;
  }
};
