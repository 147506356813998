import React, { FC } from "react";
import { DeviceRegisteredIcon } from "./DeviceRegisteredIcon";
import { DeviceRegisteredText } from "./DeviceRegisteredText";

import Styled from "./DeviceRegistered.styled";

export interface DeviceRegisteredProps {
  registered: boolean;
}

export const DeviceRegistered: FC<DeviceRegisteredProps> = (
  props: DeviceRegisteredProps
) => {
  return (
    <Styled.Box>
      <p>
        <DeviceRegisteredIcon state={props.registered} /> the device is{" "}
        <DeviceRegisteredText state={props.registered} />
      </p>
    </Styled.Box>
  );
};
