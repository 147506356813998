import { styled } from '../../style/theme';
import { Box, Button } from '@droidsolutions/ui-components';

export default {
  Box: styled(Box)`
    box-shadow: none;
    display: box;
    width: 90%;
  `,

  ActionBar: styled(Box)`
    margin-left: 12px;
    box-shadow: none;
    display: flex;
    flex-flow: row wrap;
    align-content: center;
    justify-content: start;
    border: none;
  `,

  Button: styled(Button)`
    width: 90px;
    margin-right: 24px;
  `,
};
