/* eslint-disable no-console */
import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Typography } from 'antd';
import { LoadingSpinner } from '@droidsolutions/ui-components';

import { Device } from './Device.types';

import Url from '../../lib/utils/Url';
import { PageLayout } from '../shared/layout/PageLayout';
import { DeviceDescriptionBox } from './DeviceDescriptionBox';
import { DeviceConfigBox } from '../deviceconfiguration/DeviceConfigBox';
import { getAuthHeaders } from '../../lib/auth/headers';
import { useTranslation } from 'react-i18next';

export interface DeviceDetailProps {
  device: Device;
}

/**
 * Fetches a device by its id from the server
 */
const getDeviceById = async (deviceId: string) => {
  const projectDetailUrl = Url.api(`device/by-id/${deviceId}`);
  const data = await fetch(projectDetailUrl, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    headers: getAuthHeaders(),
  });

  return data.json();
};

export const DeviceDetailPage: FC<DeviceDetailProps> = (props: DeviceDetailProps) => {
  const { t } = useTranslation();

  const { deviceId } = useParams<{ deviceId: string }>();
  const [device, setDevice] = useState<Device>();
  const [dataFetched, setDataFetched] = useState<boolean>(false);

  useEffect(() => {
    if (props.device === undefined && deviceId !== undefined && !dataFetched) {
      getDeviceById(deviceId).then((response) => {
        setDevice(response.device);
        setDataFetched(true);
      });
    }
  }, [deviceId, props.device, dataFetched]);

  const title = `${t('device')}: ${device?.deviceId || ''}`;

  const handleSave = () => {
    /** TODO: implement device config save */
  };

  const handleDiscard = () => {
    /** TODO: implement discard */
  };

  return (
    <PageLayout>
      <Typography.Title level={4}>{title}</Typography.Title>
      {!device && <LoadingSpinner />}
      {device && (
        <>
          <DeviceDescriptionBox device={device} />
          <DeviceConfigBox device={device} />
        </>
      )}
    </PageLayout>
  );
};
