import { UserOutlined } from '@ant-design/icons';
import { Box, Dropdown } from '@droidsolutions/ui-components';
import { Button, Menu } from 'antd';
import { styled } from '../../../style/theme';

export default {
  Menu: styled(Menu)`
    background-color: transparent;
  `,

  Box: styled(Box)`
    background-color: transparent;
    box-shadow: none;
    display: flex;
    width: 400px;
    flex-flow: row wrap;
    align-content: center;
    justify-content: start;
    border: none;
  `,

  LoginDropdown: styled(Dropdown)`
    background-color: #fff;
    align-content: center;
  `,

  Button: styled(Button)`
    background-color: #fff;
    margin: 10px 10px 10px 10px;
    transition: none;
  `,
  UserOutlined: styled(UserOutlined)`
    background-color: #fff;
  `,
};
