import { styled } from '../../style/theme';
import { Box, Button } from '@droidsolutions/ui-components';
import { ActionBar } from './ActionBar';

export default {
  Button: styled(Button)`
    width: 115px;
    margin-right: 24px;
  `,

  ActionBar: styled(ActionBar)`
    border: none;
  `,

  AddPhoneButton: styled(Button)`
    border-radius: 4px;
    width: 130px;
    margin: 18px;
  `,

  DeleteButton: styled(Button)`
    width: 115px;
    margin-right: 24px;
    margin-left: auto;
  `,

  SearchBox: styled.div`
    display: flex;
    background-color: #fff;
    box-shadow: none;
    display: flex;
    margin-bottom: 24px;
    align-content: center;
    justify-content: end;
  `,
  Box: styled(Box)`
    background-color: #fff;
    box-shadow: none;
    display: flex;
    flex-flow: row wrap;
    align-content: center;
    justify-content: start;
    margin-bottom: 24px;
    border: none;
  `,

  Input: styled.input`
    border-radius: 4px;
    border: solid 1px ${(props) => props.theme.color.primary};
    color: ${(props) => props.theme.color.primary};
    font-size: 18px;
    height: 40px;
    width: 300px;
    padding: 0 5px 0 5px;
    margin: 14px;

    :focus {
      outline: none;
    }

    ::placeholder {
      color: ${(props) => props.theme.color.primary25};
      font-style: italic;
      text-align: center;
    }
  `,
};
