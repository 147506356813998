import { EditorField, PromptModal, Modal } from '@droidsolutions/ui-components';
import { message, Typography } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { PageLayout } from '../shared/layout/PageLayout';
import { putProject } from './Project.apifunctions';
import Styled from './ProjectCreatePage.styled';

const defaultErrorMessage = 'An internal server error occured';

export interface NewProjectProps {
  name?: string;
  desc?: string;
  organisation?: string;
  showParentModal: boolean;
  closeParentModal: () => void;
}

export const CreateProjectPage: React.FC<NewProjectProps> = ({ showParentModal, closeParentModal }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [name, setName] = React.useState('');
  const handleNameEdit = (newName: string) => setName(newName);

  const [desc, setDescription] = React.useState('');
  const handleDescEdit = (newDescr: string) => setDescription(newDescr);

  const [modalVisible, setModalVisible] = useState(false);
  const text = t('an-internal-server-error-occurred');
  const [modalContent, setModalContent] = useState(text);
  const handleModal = (modalOpen: boolean, modalText: string) => {
    setModalContent(modalText);
    setModalVisible(modalOpen);
  };

  const checkIfNameIsEmpty = (): boolean => {
    if (name.trim().length > 0) {
      return false;
    }
    return true;
  };

  const handleDiscard = () => {
    closeParentModal();
  };

  const handleSave = async () => {
    if (!checkIfNameIsEmpty()) {
      const saveResponse = await putProject(name.trim(), desc);

      if (saveResponse.status >= 200 && saveResponse.status < 300) {
        handleModal(true, `${t('project')} ${name} ${t('created-successfully')}`);
      } else if (saveResponse.status === 409) {
        const errMessage = (await saveResponse.json()).message;
        handleModal(true, `${t('project')} ${name} ${t('already-exists')}`);
      } else {
        const errMessage = (await saveResponse.json()).message;
        handleModal(true, errMessage || t('an-internal-server-error-occurred'));
      }
    } else {
      handleModal(true, t('project-name-cannot-be-empty'));
    }
  };

  return (
    <Modal
      open={showParentModal}
      onClosed={() => {
        history.go(0);
      }}
    >
      <Styled.Box>
        <Typography.Title level={3}>{t('add-a-new-project')}</Typography.Title>
        <Styled.Outer>
          <Styled.Section>
            <Typography.Title level={4}>{t('settings')}</Typography.Title>
            <Styled.Inner>
              <EditorField
                initialContent={name}
                label={t('name')}
                placeholder={t('project-name')}
                onBlur={handleNameEdit}
              />
            </Styled.Inner>
            <Styled.Inner>
              <EditorField
                initialContent={desc}
                label={t('description')}
                placeholder={t('a-short-description-of-the-project')}
                fieldHeight={210}
                onBlur={handleDescEdit}
              />
            </Styled.Inner>
          </Styled.Section>
        </Styled.Outer>
        <Styled.ActionBar>
          <Styled.Button icon="saveProject" styling="important" onClick={handleSave}>
            Save
          </Styled.Button>
          <Styled.Button icon="closeBig" styling="important" onClick={handleDiscard}>
            Discard
          </Styled.Button>
        </Styled.ActionBar>
        <PromptModal
          onClosed={() => {
            if (!checkIfNameIsEmpty()) {
              closeParentModal();
            }
          }}
          content={modalContent}
          onSubmit={() => setModalVisible(false)}
          open={modalVisible}
          submitButtonText={t('close')}
        />
      </Styled.Box>
    </Modal>
  );
};
