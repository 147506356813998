/* eslint-disable @typescript-eslint/quotes */
import React, { useState, FC } from 'react';
import { Col, Row, Typography, Tooltip } from 'antd';
import { InfoCircleOutlined, InfoCircleTwoTone } from '@ant-design/icons';

import { BrowserConfig } from './DeviceSettings.types';
import { Checkbox, Input } from '@droidsolutions/ui-components';
import { ExtensibleStringList } from '../shared/ExtensibleStringList';
import { MDMColorPicker } from '../shared/MDMColorPicker/MDMColorPicker';
import { SmallDescription } from '../shared/SmallDescription';
import { useTranslation } from 'react-i18next';
import Styled from './Settings.styled';

export interface BrowserConfigProps {
  settings: BrowserConfig;
  updateSettings: (updated: BrowserConfig) => void;
}

export const BrowserSettingsPanel: FC<BrowserConfigProps> = (props: BrowserConfigProps) => {
  const { t } = useTranslation();

  const [startURL, setStartURL] = useState<string>(props.settings.start_url);
  const [navbarLabel, setNavbarLabel] = useState<string>(props.settings.navigationbar_label);
  const [showLabel, setShowLabel] = useState<boolean>(props.settings.show_label);
  const [navActive, setNavActive] = useState<boolean>(props.settings.navigationbar_active);
  const [browserDomainWhitelist, setWhitelist] = useState<string[]>(props.settings.browser_domain_whitelist);
  const [navButtonWhitelist, setNavButtonWhitelist] = useState<string[]>(props.settings.navigation_button_whitelist);

  /**
   * Colors
   */
  const [button_color, setbutton_color] = useState<string>(props.settings.colors.button_color || 'FFFFFF');
  const [titlebar_label, settitlebar_label] = useState<string>(props.settings.colors.navigationbar_label || 'FFFFFF');
  const [titlebar_background, settitlebar_background] = useState<string>(
    props.settings.colors.navigationbar_background || 'FFFFFF'
  );
  const [background_color, setbackground_color] = useState<string>(props.settings.colors.background_color || 'FFFFFF');

  return (
    <>
      <Typography.Title level={3}>{t('browser-settings')}</Typography.Title>
      <hr />
      <Styled.Row>
        <Col span={4}>
          <Tooltip title={t('navigation-bar-enabled-description')}>
            <Typography.Text>{t('navigation-bar-enabled')}</Typography.Text> <InfoCircleTwoTone />
          </Tooltip>
        </Col>
        <Col span={20}>
          <Checkbox
            label=""
            checked={navActive}
            onChange={(updated: boolean) => {
              setNavActive(updated);
              props.settings.navigationbar_active = updated;

              props.updateSettings(props.settings);
            }}
          />
        </Col>
      </Styled.Row>

      <Styled.Row>
        <Col span={4}>
          <Tooltip title={t('show-label-description')}>
            <Typography.Text>{t('show-label')}</Typography.Text> <InfoCircleTwoTone />
          </Tooltip>
        </Col>
        <Col span={20}>
          <Checkbox
            label=""
            checked={showLabel}
            onChange={(updated: boolean) => {
              setShowLabel(updated);
              props.settings.show_label = updated;
              props.updateSettings(props.settings);
            }}
          />
        </Col>
      </Styled.Row>

      <Styled.Row>
        <Col span={4}>
          <Tooltip title={t('start-url-description')}>
            <Typography.Text>{t('start-url')}</Typography.Text> <InfoCircleTwoTone />
          </Tooltip>
        </Col>
        <Col span={20}>
          <Input
            value={startURL}
            placeholder={t('start-url')}
            onChange={(e) => {
              const updated = e.target.value;
              setStartURL(updated);
              props.settings.start_url = updated;
              props.updateSettings(props.settings);
            }}
          />
        </Col>
      </Styled.Row>

      <Styled.Row>
        <Col span={4}>
          <Tooltip title={t('navigation-bar-label-description')}>
            <Typography.Text>{t('navigation-bar-label')}</Typography.Text> <InfoCircleTwoTone />
          </Tooltip>
        </Col>
        <Col span={20}>
          <Input
            value={navbarLabel}
            placeholder={t('navigation-bar-label')}
            onChange={(e) => {
              const updated = e.target.value;
              setNavbarLabel(updated);
              props.settings.navigationbar_label = updated;
              props.updateSettings(props.settings);
            }}
          />
        </Col>
      </Styled.Row>

      <Styled.Row>
        <Col span={4}>
          <Tooltip title={t('allowed-domains-description')}>
            <Typography.Text>{t('allowed-domains')}</Typography.Text> <InfoCircleTwoTone />
          </Tooltip>
        </Col>
        <Col span={20}>
          <ExtensibleStringList
            header={t('allowed-domains')}
            list={browserDomainWhitelist}
            onAppend={(updated: string[]) => setWhitelist(updated)}
          />
        </Col>
      </Styled.Row>

      <Styled.Row>
        <Col span={4}>
          <Tooltip title={t('navigation-buttons-description')}>
            <Typography.Text>{t('navigation-buttons')}</Typography.Text> <InfoCircleTwoTone />
          </Tooltip>
        </Col>
        <Col span={20}>
          <ExtensibleStringList
            header={t('navigation-buttons')}
            list={navButtonWhitelist}
            onAppend={(updated: string[]) => setNavButtonWhitelist(updated)}
          />
        </Col>
      </Styled.Row>

      <Typography.Title level={4}>{t('colors')}</Typography.Title>
      <hr />
      <Styled.Row>
        <Col span={4}>
          <Tooltip title={t('browser-mode-kiosk-button-color-description')}>
            <Typography.Text>{t('button-color')}</Typography.Text> <InfoCircleTwoTone />
          </Tooltip>
        </Col>
        <Col span={20}>
          <MDMColorPicker
            color={button_color}
            updateSettings={(color: string) => {
              setbutton_color(color);

              props.settings.colors.button_color = color;
              props.updateSettings(props.settings);
            }}
          />
        </Col>
      </Styled.Row>
      <Styled.Row>
        <Col span={4}>
          <Tooltip title={t('browser-mode-background-color-description')}>
            <Typography.Text>{t('background-color')}</Typography.Text> <InfoCircleTwoTone />
          </Tooltip>
        </Col>
        <Col span={20}>
          <MDMColorPicker
            color={background_color}
            updateSettings={(color: string) => {
              setbackground_color(color);

              props.settings.colors.background_color = color;
              props.updateSettings(props.settings);
            }}
          />
        </Col>
      </Styled.Row>
      <Styled.Row>
        <Col span={4}>
          <Tooltip title={t('navigation-label-color-description')}>
            <Typography.Text>{t('navigation-label-color')}</Typography.Text> <InfoCircleTwoTone />
          </Tooltip>
        </Col>
        <Col span={20}>
          <MDMColorPicker
            color={titlebar_label}
            updateSettings={(color: string) => {
              settitlebar_label(color);

              props.settings.colors.navigationbar_label = color;
              props.updateSettings(props.settings);
            }}
          />
        </Col>
      </Styled.Row>
      <Styled.Row>
        <Col span={4}>
          <Tooltip title={t('navigation-background-color-description')}>
            <Typography.Text>{t('navigation-background-color')}</Typography.Text> <InfoCircleTwoTone />
          </Tooltip>
        </Col>
        <Col span={20}>
          <MDMColorPicker
            color={titlebar_background}
            updateSettings={(color: string) => {
              settitlebar_background(color);

              props.settings.colors.navigationbar_background = color;
              props.updateSettings(props.settings);
            }}
          />
        </Col>
      </Styled.Row>
    </>
  );
};
