import { styled } from "../../style/theme";
import { Box, Button } from "@droidsolutions/ui-components";

export default {
  Box: styled(Box)`
    box-shadow: none;
    display: flex;
    flex-flow: row wrap;
    align-content: center;
    justify-content: start;
  `,
};
