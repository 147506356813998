import React, { useState, FC } from "react";
import { List, Typography, message } from "antd";

import { Input } from "@droidsolutions/ui-components";
import { DeleteButton } from "./Buttons/DeleteButton";

export interface ExtensibleStringListProps {
  list: string[];
  header: string;
  onAppend: (updated: string[]) => void;
}

/**
 * A string-list that can be extended with an editor field.
 */
export const ExtensibleStringList: FC<ExtensibleStringListProps> = (
  props: ExtensibleStringListProps
) => {
  const [newListItem, setNewListItem] = useState<string>("");

  /**
   * Handler for the change event of the text field.
   */
  const inputUpdateEvent = (event: React.ChangeEvent<HTMLInputElement>) =>
    setNewListItem(event.target.value);

  /**
   * Handler for the delete event of a list item deletion.
   */
  const deleteFromListEvent = (index: number) => {
    const newList = props.list;
    if (index > -1 && !isNaN(index)) {
      newList.splice(index, 1);

      // quirky hack to force re-rendering - TODO: ask the frontend guys on how to do this better
      setNewListItem(newListItem === "" ? " " : "");
      setTimeout(() => {
        props.onAppend(newList);
      }, 10);
    }
  };

  /**
   * Handler for the "press enter key" event. All items are trimmed, before they get pushed into the state array.
   */
  const inputEnterEvent = () => {
    const updatedList = props.list;
    const newItem = newListItem.trim();

    if (!updatedList.includes(newItem)) {
      updatedList.push(newItem);

      setNewListItem("");
      props.onAppend(updatedList);
    } else {
      message.warn(`Item: ${newItem} is already listed!`);
    }
  };

  return (
    <>
      <Input
        value={newListItem}
        placeholder="Insert new Term Here..."
        onPressEnter={inputEnterEvent}
        onChange={inputUpdateEvent}
      />
      <List
        header={
          <div>
            <i>{props.header}</i>
          </div>
        }
        bordered
        dataSource={props.list}
        renderItem={(item: string, i: number) => (
          <List.Item
            extra={<DeleteButton index={i} onClick={deleteFromListEvent} />}
          >
            <Typography.Text underline style={{ display: "inline-block" }}>
              {item}
            </Typography.Text>
          </List.Item>
        )}
      />
    </>
  );
};
