import { styled } from '../../style/theme';
import { Box, Button, Checkbox, Icon, Pagination } from '@droidsolutions/ui-components';

export default {
  Icon: styled(Icon)`
    float: left;
  `,

  Button: styled(Button)`
    min-width: 90px;
    margin-right: 24px;
  `,
  Spacer: styled.div`
    visibility: hidden;
    height: 93px;
    width: 100%;
  `,

  Pagination: styled(Pagination)`
    align-items: center;
    bottom: 42px;
    display: flex;
    justify-content: center;
    width: 100%;
  `,

  Box: styled(Box)`
    background-color: #fff;
    box-shadow: none;
    display: flex;
    flex-flow: row wrap;
    align-content: center;
    justify-content: start;
    margin-bottom: 0px;
    justify-content: flex-end;
  `,

  Checkbox: styled(Checkbox)`
    border-radius: 2px;
    min-height: 28px;
    margin-right: 100px;
    padding-left: 7px;
    padding-right: 7px;
    padding-top: 3px;
    overflow: hidden;

    label {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  `,

  SelectAllCheckbox: styled(Checkbox)`
    border-radius: 2px;
    min-height: 28px;
    padding-left: 7px;
    padding-right: 7px;
    padding-top: 3px;
    overflow: hidden;

    label {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  `,
};
