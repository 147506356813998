// import axios, { AxiosRequestConfig } from 'axios'
import * as React from 'react';
import { render } from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { App } from './App';
import './mockServer';

// import config from './config'
// import './i18n'
// import { getTokenFromLocalStorage, getTokenValidity } from './lib/auth'

render(
  <I18nextProvider i18n={i18n}>
    <App />
  </I18nextProvider>,
  document.getElementById('root') as HTMLElement
);
