/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react-hooks/rules-of-hooks */

import React, { useState, FC } from 'react';
import { Row, Col, Divider, Typography, Select, message, Modal, Tooltip } from 'antd';
import { InfoCircleOutlined, InfoCircleTwoTone } from '@ant-design/icons';

import { SubPanelProps } from './SubPanelProps';
import { Checkbox, DatePicker, TimePicker } from '@droidsolutions/ui-components';

import Timezones from '../../../lib/AndroidTimezoneIds.json';
import { useTranslation } from 'react-i18next';
import Styled from '../Settings.styled';
import { Time } from '@droidsolutions/ui-components/build/types/Time';

export const SystemPanel: FC<SubPanelProps> = (props: SubPanelProps) => {
  const { t } = useTranslation();
  /**
   * States and Updaters.
   */
  /** ADB */
  const [adb_active, setadb_active] = useState<boolean>(props.settings.adb_active);
  const [adb_modal_visible, set_adb_modal_visible] = useState<boolean>(false);

  /** Status Bar */
  const [allow_status_bar, setallow_status_bar] = useState<boolean>(props.settings.allow_status_bar);

  /** Lock Task */
  const [lock_task, setlock_task] = useState<boolean>(props.settings.lock_task);

  /** Time Zone */
  const [time_zone, settime_zone] = useState<string>(props.settings.time_zone);
  const [original_time_zone, setoriginal_time_zone] = useState<string>(props.settings.time_zone);
  const [date_timezone_modal_visible, set_timezone_modal_visible] = useState<boolean>(false);

  const [rebootPeriodically, setRebootPeriodically] = useState(
    props.settings.reboot_configuration?.reboot_periodically ?? false
  );

  const [rebootTime, setRebootTime] = useState<String>(props.settings.reboot_configuration?.reboot_time ?? '22:05');

  const [otaUpdate, setOtaUpdate] = useState<boolean>(props.settings.ota_configuration?.enabled ?? false);
  const [otaStartTime, setOtaStartTime] = useState<number>(props.settings.ota_configuration?.ota_start_window ?? 1320);
  const [otaEndTime, setOtaEndTime] = useState<number>(props.settings.ota_configuration?.ota_end_window ?? 300);

  /** Datetime 
  const [date_time, setdate_time] = useState<number>(props.settings.date_time);
  const [original_date_time, setoriginal_date_time] = useState<number>(
    props.settings.date_time
  );
  const [
    date_time_modal_visible,
    set_date_time_modal_visible,
  ] = useState<boolean>(false);
*/
  /**
   * Rendering.
   */
  return (
    <>
      <Divider orientation="left">
        <Typography.Text strong type="danger">
          {t('system')}
        </Typography.Text>
      </Divider>
      <Typography.Text ellipsis type="danger">
        {t('system-settings-warning')}
      </Typography.Text>

      <Styled.Row>
        <Col span={4}>
          <Tooltip title={t('adb-enabled-description')}>
            <Typography.Text>{t('adb-enabled')}</Typography.Text> <InfoCircleTwoTone />
          </Tooltip>
        </Col>
        <Col span={20}>
          <Checkbox
            label=""
            checked={adb_active}
            onChange={(value: boolean) => {
              setadb_active(value);
              set_adb_modal_visible(true);
            }}
          />
          <Modal
            visible={adb_modal_visible}
            title={t('warning-you-can-brick-the-device-if-you-proceed')}
            onOk={() => {
              if (!adb_active) {
                message.success(t('device-adb-has-been-disabled'));

                props.settings.adb_active = adb_active;
                props.updateSettings(props.settings);
                set_adb_modal_visible(false);
              } else {
                message.success(t('wise-choice-device-adb-has-been-enabled'));

                props.settings.adb_active = adb_active;
                props.updateSettings(props.settings);
                set_adb_modal_visible(false);
              }
            }}
            onCancel={() => {
              message.warn(t('wise-choice-adb-is-set-to-active'));
              setadb_active(true);
              props.settings.adb_active = true;
              props.updateSettings(props.settings);
              set_adb_modal_visible(false);
            }}
            okText={t('proceed')}
            cancelText={t('cancel')}
          >
            <Typography.Text>{t('do-you-really-want-to-disable-the-system-adb')}</Typography.Text>
          </Modal>
        </Col>
      </Styled.Row>

      <Styled.Row>
        <Col span={4}>
          <Tooltip title={t('status-bar-enabled-description')}>
            <Typography.Text>{t('show-status-bar')}</Typography.Text> <InfoCircleTwoTone />
          </Tooltip>
        </Col>
        <Col span={20}>
          <Checkbox
            label=""
            checked={allow_status_bar}
            onChange={(value: boolean) => {
              setallow_status_bar(value);
              props.settings.allow_status_bar = value;
              props.updateSettings(props.settings);
            }}
          />
        </Col>
      </Styled.Row>

      <Styled.Row>
        <Col span={4}>
          <Tooltip title={t('lock-task-enabled-description')}>
            <Typography.Text>{t('lock-task-enabled')}</Typography.Text> <InfoCircleTwoTone />
          </Tooltip>
        </Col>
        <Col span={20}>
          <Checkbox
            label=""
            checked={lock_task}
            onChange={(value: boolean) => {
              setlock_task(value);
              props.settings.lock_task = value;
              props.updateSettings(props.settings);
            }}
          />
        </Col>
      </Styled.Row>

      <Styled.Row>
        <Col span={4}>
          <Tooltip title={t('ota-update-enabled-description')}>
            <Typography.Text>{t('ota-update-enabled')}</Typography.Text> <InfoCircleTwoTone />
          </Tooltip>
        </Col>
        <Col span={20}>
          <Checkbox
            label=""
            checked={otaUpdate}
            onChange={(value: boolean) => {
              setOtaUpdate(value);
              if (!props.settings.ota_configuration) {
                props.settings.ota_configuration = {
                  enabled: value,
                  ota_start_window: otaStartTime,
                  ota_end_window: otaEndTime,
                };
              }
              props.settings.ota_configuration.enabled = value;
              props.updateSettings(props.settings);
            }}
          />
        </Col>
      </Styled.Row>

      <Styled.Row>
        <Col span={4}>
          <Tooltip title={t('ota-update-start-window-description')}>
            <Typography.Text>{t('ota-start-window')}</Typography.Text> <InfoCircleTwoTone />
          </Tooltip>
        </Col>
        <Col span={20}>
          <TimePicker
            value={{ hour: Math.floor(otaStartTime / 60), minute: otaStartTime % 60 }}
            onChange={(value: Time) => {
              setOtaStartTime(value.hour * 60 + value.minute);
              if (!props.settings.ota_configuration) {
                props.settings.ota_configuration = {
                  enabled: otaUpdate,
                  ota_start_window: value.hour * 60 + value.minute,
                  ota_end_window: otaEndTime,
                };
              }
              props.settings.ota_configuration.ota_start_window = value.hour * 60 + value.minute;
              props.updateSettings(props.settings);
            }}
          ></TimePicker>
        </Col>
      </Styled.Row>

      <Styled.Row>
        <Col span={4}>
          <Tooltip title={t('ota-update-end-window-description')}>
            <Typography.Text>{t('ota-end-window')}</Typography.Text> <InfoCircleTwoTone />
          </Tooltip>
        </Col>
        <Col span={20}>
          <TimePicker
            value={{ hour: Math.floor(otaEndTime / 60), minute: otaEndTime % 60 }}
            onChange={(value: Time) => {
              setOtaEndTime(value.hour * 60 + value.minute);
              if (!props.settings.ota_configuration) {
                props.settings.ota_configuration = {
                  enabled: otaUpdate,
                  ota_start_window: otaStartTime,
                  ota_end_window: value.hour * 60 + value.minute,
                };
              }
              props.settings.ota_configuration.ota_end_window = value.hour * 60 + value.minute;
              props.updateSettings(props.settings);
            }}
          ></TimePicker>
        </Col>
      </Styled.Row>

      <Styled.Row>
        <Col span={4}>
          <Tooltip title={t('reboot-periodically-enabled-description')}>
            <Typography.Text>{t('reboot-periodically-enabled')}</Typography.Text> <InfoCircleTwoTone />
          </Tooltip>
        </Col>
        <Col span={20}>
          <Checkbox
            label=""
            checked={rebootPeriodically}
            onChange={(value: boolean) => {
              setRebootPeriodically(value);
              if (!props.settings.reboot_configuration) {
                props.settings.reboot_configuration = {
                  reboot_periodically: value,
                  reboot_time: rebootTime,
                };
              }
              props.settings.reboot_configuration.reboot_periodically = value;
              props.updateSettings(props.settings);
            }}
          />
        </Col>
      </Styled.Row>

      <Styled.Row>
        <Col span={4}>
          <Typography.Text>Reboot Time</Typography.Text>
        </Col>
        <Col span={20}>
          <TimePicker
            value={{ hour: parseInt(rebootTime.split(':')[0]), minute: parseInt(rebootTime.split(':')[1]) }}
            onChange={(value: Time) => {
              setRebootTime(`${value.hour}:${value.minute}`);
              if (!props.settings.reboot_configuration) {
                props.settings.reboot_configuration = {
                  reboot_periodically: rebootPeriodically,
                  reboot_time: rebootTime,
                };
              }
              props.settings.reboot_configuration.reboot_time = rebootTime;
              props.updateSettings(props.settings);
            }}
          ></TimePicker>
        </Col>
      </Styled.Row>

      <Styled.Row>
        <Col span={4}>
          <Tooltip title={t('timezone-description')}>
            <Typography.Text>{t('time-zone')}</Typography.Text> <InfoCircleTwoTone />
          </Tooltip>
        </Col>
        <Col span={20}>
          <Select
            value={time_zone}
            style={{ width: 420 }}
            onChange={(value: string) => {
              setoriginal_time_zone(time_zone);
              settime_zone(value);
              set_timezone_modal_visible(true);
            }}
          >
            {Timezones.map((tz, i) => (
              <Select.Option key={`countryCode-${i}`} value={tz}>
                {tz}
              </Select.Option>
            ))}
          </Select>

          <Modal
            visible={date_timezone_modal_visible}
            title={t('warning-you-can-brick-the-device-if-you-proceed')}
            onOk={() => {
              message.success(t('device-system-timezone-has-been-updated'));

              props.settings.time_zone = time_zone;
              props.updateSettings(props.settings);
              set_timezone_modal_visible(false);
            }}
            onCancel={() => {
              settime_zone(original_time_zone);
              props.settings.time_zone = original_time_zone;
              props.updateSettings(props.settings);
              set_timezone_modal_visible(false);
            }}
            okText={t('proceed')}
            cancelText={t('cancel')}
          >
            <Typography.Text>{t('do-you-really-want-to-update-the-system-timezone')}.</Typography.Text>
          </Modal>
        </Col>
      </Styled.Row>

      {/*
      <Styled.Row>
        <Col span={4}>
          <Typography.Text>System Time</Typography.Text>
        </Col>
        <Col span={20}>
          <DatePicker
            allowClear={false}
            showTimePicker={true}
            value={new Date(date_time)}
            onChange={(updatedDateTime: Maybe<Date>) => {
              setoriginal_date_time(date_time);
              if (updatedDateTime) {
                const numeric_date: number = updatedDateTime?.valueOf();
                setdate_time(numeric_date);
                set_date_time_modal_visible(true);
              }
            }}
          />
          <Modal
            visible={date_time_modal_visible}
            title={deviceBrickWarning}
            onOk={() => {
              message.success(systemDatetimeSuccess);

              props.settings.date_time = date_time;
              props.updateSettings(props.settings);
              set_date_time_modal_visible(false);
            }}
            onCancel={() => {
              message.warn(systemDatetimeCancel);
              setdate_time(original_date_time);
              props.settings.date_time = original_date_time;
              props.updateSettings(props.settings);
              set_date_time_modal_visible(false);
            }}
            okText="Proceed"
            cancelText="Cancel"
          >
            <Typography.Text>{systemTimeWarning}</Typography.Text>
          </Modal>
        </Col>
      </Styled.Row>*/}
    </>
  );
};
