import { getAuthHeaders } from "../../lib/auth/headers";
import { DeviceSettings } from "../settings/DeviceSettings.types";

import Url from "../../lib/utils/Url";
import { Maybe } from "../../types/Monads";
import { checkResponseStatus } from "../../lib/api-errorhandler";
import { DeviceGroupResponse } from "../devicegroup/DeviceGroup.types";

const resourceName = "device settings";

export const fetchDeviceGroupDetails = async (
  groupSlug: string
): Promise<Maybe<DeviceGroupResponse>> => {
  const getDeviceGroupUrl = Url.api(`devicegroup/by-name/${groupSlug}`);
  const response = await fetch(getDeviceGroupUrl, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    headers: getAuthHeaders(),
  });

  const responseOK = checkResponseStatus(
    "device group details",
    groupSlug,
    response
  );
  if (responseOK) {
    return response.json();
  }
};

/**
 * @returns the settings document for a single device, identified by its URL.
 */
export const fetchGroupSettings = async (
  groupSlug: string
): Promise<Maybe<DeviceSettings>> => {
  const deviceSettingsUrl = Url.api(`devicegroup/settings/${groupSlug}`);
  const response = await fetch(deviceSettingsUrl, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    headers: getAuthHeaders(),
  });

  const responseOK = checkResponseStatus(resourceName, groupSlug, response);

  if (responseOK) {
    return response.json();
  }
};

/**
 * @returns true or false for posting the settings document for a device, identified by its URL
 */
export const postGroupSettings = async (
  groupSlug: string,
  deviceSetting: DeviceSettings
): Promise<DeviceSettings | null> => {
  const deviceSettingsUrl = Url.api(`devicegroup/settings/${groupSlug}`);
  const response = await fetch(deviceSettingsUrl, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    headers: getAuthHeaders(),
    body: JSON.stringify(deviceSetting),
  });

  const responseOK = checkResponseStatus(resourceName, groupSlug, response);

  if (responseOK) {
    return response.json();
  } else {
    return null;
  }
};
