/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react-hooks/rules-of-hooks */

import React, { useState, FC } from 'react';
import { Select, Row, Col, Divider, Typography, Modal, message, Tooltip } from 'antd';
import { Checkbox, Input } from '@droidsolutions/ui-components';
import { InfoCircleOutlined, InfoCircleTwoTone } from '@ant-design/icons';

import { SubPanelProps } from './SubPanelProps';
import { WifiNetwork, WifiNetworkType } from '../DeviceSettings.types';
import { wifiNetworkTypes } from '../../../lib/utils/WifiNetworkTypes';
import { WifiList } from '../wifilist/WifiList';
import { useTranslation } from 'react-i18next';

import Styled from '../Settings.styled';

/**
 * This Panel is responsible for updating the Wifi and Interface Settings.
 */
export const WifiPanel: FC<SubPanelProps> = (props: SubPanelProps) => {
  const { t } = useTranslation();

  /**
   * States and Updaters:
   */
  const [wifi_active, setwifi_active] = useState<boolean>(props.settings.wifi_active);
  const [wifi_active_modal_visible, set_wifi_active_adb_modal_visible] = useState<boolean>(false);

  const [wifi_ssid, setwifi_ssid] = useState<string>(props.settings.wifi_ssid);
  const [wifi_pw, setwifi_pw] = useState<string>(props.settings.wifi_pw);
  const [wifi_type, setwifi_type] = useState<WifiNetworkType>(props.settings.wifi_type || 'WPA');

  const [bluetooth_active, setbluetooth_active] = useState<boolean>(props.settings.bluetooth_active);
  const [nfc_active, setnfc_active] = useState<boolean>(props.settings.nfc_active);
  const [airplane_mode_active, setairplane_mode_active] = useState<boolean>(props.settings.airplane_mode_active);

  /**
   * Rendering:
   */
  return (
    <>
      <Divider orientation="left">
        <Typography.Text strong type="danger">
          {t('wifi-and-interfaces')}
        </Typography.Text>
      </Divider>
      <Typography.Text ellipsis type="danger">
        {t('wifi-settings-warning')}
      </Typography.Text>
      <Styled.Row>
        <Col span={4}>
          <Tooltip title={t('wifi-enabled-description')}>
            <Typography.Text>{t('wifi-enabled')}</Typography.Text> <InfoCircleTwoTone />
          </Tooltip>
        </Col>
        <Col span={20}>
          <Checkbox
            label=""
            checked={wifi_active}
            onChange={(value: boolean) => {
              setwifi_active(value);
              set_wifi_active_adb_modal_visible(true);
            }}
          />
        </Col>
        <Modal
          visible={wifi_active_modal_visible}
          title={t('wifi-brick-device-warning')}
          onOk={() => {
            if (!wifi_active) {
              message.success(t('youve-been-warned-wifi-has-been-disabled'));

              props.settings.wifi_active = wifi_active;
              props.updateSettings(props.settings);
              set_wifi_active_adb_modal_visible(false);
            } else {
              message.success(t('wise-choice-wifi-has-been-enabled'));

              props.settings.wifi_active = wifi_active;
              props.updateSettings(props.settings);
              set_wifi_active_adb_modal_visible(false);
            }
          }}
          onCancel={() => {
            message.warn(t('wise-choice-wifi-has-been-enabled'));
            setwifi_active(true);
            props.settings.wifi_active = true;
            props.updateSettings(props.settings);
            set_wifi_active_adb_modal_visible(false);
          }}
          okText={t('proceed')}
          cancelText={t('cancel')}
        >
          <Typography.Text>{t('do-you-really-want-to-disable-the-wifi-network')}</Typography.Text>
        </Modal>
      </Styled.Row>
      <Styled.Row>
        <Col span={4}>
          <Tooltip title={t('bluetooth-enabled-description')}>
            <Typography.Text>{t('bluetooth-enabled')}</Typography.Text> <InfoCircleTwoTone />
          </Tooltip>
        </Col>
        <Col span={20}>
          <Checkbox
            label=""
            checked={bluetooth_active}
            onChange={(value: boolean) => {
              setbluetooth_active(value);
              props.settings.bluetooth_active = value;
              props.updateSettings(props.settings);
            }}
          />
        </Col>
      </Styled.Row>
      <Styled.Row>
        <Col span={4}>
          <Tooltip title={t('nfc-enabled-description')}>
            <Typography.Text>{t('nfc-enabled')}</Typography.Text> <InfoCircleTwoTone />
          </Tooltip>
        </Col>
        <Col span={20}>
          <Checkbox
            label=""
            checked={nfc_active}
            onChange={(value: boolean) => {
              setnfc_active(value);
              props.settings.nfc_active = value;
              props.updateSettings(props.settings);
            }}
          />
        </Col>
      </Styled.Row>
      <Styled.Row>
        <Col span={4}>
          <Tooltip title={t('airplane-mode-enabled-description')}>
            <Typography.Text>{t('airplane-mode-enabled')}</Typography.Text> <InfoCircleTwoTone />
          </Tooltip>
        </Col>
        <Col span={20}>
          <Checkbox
            label=""
            checked={airplane_mode_active}
            onChange={(value: boolean) => {
              setairplane_mode_active(value);
              props.settings.airplane_mode_active = value;
              props.updateSettings(props.settings);
            }}
          />
        </Col>
      </Styled.Row>
      <Styled.Row>
        <Col span={4}>
          <Tooltip title={t('main-wifi-description')}>
            <Typography.Text>{t('main-wifi-settings')}</Typography.Text> <InfoCircleTwoTone />
          </Tooltip>
        </Col>
        <Col span={20}>
          <Styled.Row>
            <Col span={2}>
              <Typography.Text>{t('wifi-ssid')}</Typography.Text>
            </Col>
            <Col span={20}>
              <Input
                type="text"
                placeholder={t('wifi-ssid')}
                value={wifi_ssid}
                width={512}
                onChange={(evt) => {
                  setwifi_ssid(evt.target.value);
                  props.settings.wifi_ssid = evt.target.value;
                  props.updateSettings(props.settings);
                }}
              />
            </Col>
          </Styled.Row>
          <Styled.Row>
            <Col span={2}>
              <Typography.Text>{t('wifi-password')}</Typography.Text>
            </Col>
            <Col span={20}>
              <Input
                type="text"
                placeholder={t('wifi-password')}
                value={wifi_pw}
                width={512}
                onChange={(evt) => {
                  setwifi_pw(evt.target.value);
                  props.settings.wifi_pw = evt.target.value;
                  props.updateSettings(props.settings);
                }}
              />
            </Col>
          </Styled.Row>

          <Styled.Row>
            <Col span={2}>
              <Typography.Text>{t('wifi-type')}</Typography.Text>
            </Col>
            <Col span={20}>
              <Select
                defaultValue={wifi_type}
                style={{ width: 420 }}
                onChange={(value: WifiNetworkType) => {
                  setwifi_type(value);
                  props.settings.wifi_type = value;
                  props.updateSettings(props.settings);
                }}
              >
                {wifiNetworkTypes.map((networkType) => (
                  <Select.Option key={`wifi-network-type-${networkType}`} value={networkType}>
                    {networkType}
                  </Select.Option>
                ))}
              </Select>
            </Col>
          </Styled.Row>
        </Col>
      </Styled.Row>

      <Styled.Row>
        <Col span={4}>
          <Tooltip title={t('additional-wifi-description')}>
            <Typography.Text>{t('additional-wifi-networks')}</Typography.Text> <InfoCircleTwoTone />
          </Tooltip>
        </Col>
        <Col span={20}>
          <WifiList
            wifis={props.settings.wifis || []}
            onAppend={(updated: WifiNetwork[]) => {
              const settings = props.settings;
              settings.wifis = [...updated];
              props.updateSettings(settings);
            }}
          />
        </Col>
      </Styled.Row>
    </>
  );
};
