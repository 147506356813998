/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react-hooks/rules-of-hooks */

import React, { FC } from 'react';
import { Typography } from 'antd';

import { DeviceConfigurationDTO } from './DeviceSettings.types';
import { Maybe } from '../../types/Monads';

import { WifiPanel } from './configpanels/WifiPanel';
import { DisplayPanel } from './configpanels/DisplayPanel';
import { AudioPanel } from './configpanels/AudioPanel';
import { LocationPanel } from './configpanels/LocationPanel';
import { LocalizationPanel } from './configpanels/LocalizationPanel';
import { AccessibilityPanel } from './configpanels/AccessibilityPanel';
import { SystemPanel } from './configpanels/SystemPanel';
import { useTranslation } from 'react-i18next';

export interface DeviceConfigurationProps {
  settings: Maybe<DeviceConfigurationDTO>;
  updateSettings: (updated: DeviceConfigurationDTO) => void;
}

export const DeviceConfigurationPanel: FC<DeviceConfigurationProps> = (props: DeviceConfigurationProps) => {
  const { t } = useTranslation();

  if (!props.settings) {
    return (
      <>
        <Typography.Title level={3}>{t('hardware-configuration-is-disabled-on-this-device')}</Typography.Title>
      </>
    );
  } else {
    const updateHandler = (updated: DeviceConfigurationDTO) => props.updateSettings(updated);

    return (
      <>
        <Typography.Title level={3}>{t('hardware-configuration')}</Typography.Title>
        <hr />

        <WifiPanel settings={props.settings} updateSettings={updateHandler} />

        <DisplayPanel settings={props.settings} updateSettings={updateHandler} />

        <AudioPanel settings={props.settings} updateSettings={updateHandler} />

        <LocationPanel settings={props.settings} updateSettings={updateHandler} />

        <LocalizationPanel settings={props.settings} updateSettings={updateHandler} />

        <AccessibilityPanel settings={props.settings} updateSettings={updateHandler} />

        <SystemPanel settings={props.settings} updateSettings={updateHandler} />
      </>
    );
  }
};
