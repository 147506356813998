import React, { useState, FC } from 'react';
import { Col, Row, Typography, Tooltip } from 'antd';
import { InfoCircleOutlined, InfoCircleTwoTone } from '@ant-design/icons';

import { DeviceSettings } from './DeviceSettings.types';
import { Checkbox } from '@droidsolutions/ui-components';
import { ExtensibleStringList } from '../shared/ExtensibleStringList';
import { SmallDescription } from '../shared/SmallDescription';
import { TimeField } from '../shared/TimeField/TimeField';
import { useTranslation } from 'react-i18next';
import Styled from './Settings.styled';

export interface BaseConfigProps {
  settings: DeviceSettings;
  updateSettings: (updated: DeviceSettings) => void;
}

export const BaseSettingsPanel: FC<BaseConfigProps> = (props: BaseConfigProps) => {
  const { t } = useTranslation();

  // stateful field values and updaters -- settings
  const [active, setActive] = useState<boolean>(props.settings.active);
  const [disableNative, setDisableNative] = useState<boolean>(props.settings.disable_native_apps);
  const [appBlacklist, setAppBlacklist] = useState<string[]>(props.settings.app_blacklist || []);
  const [appWhitelist, setAppWhitelist] = useState<string[]>(props.settings.app_whitelist || []);
  const [uninstallApps, setUninstallApps] = useState<string[]>(props.settings.uninstall_apps || []);
  const [update_interval, setupdate_interval] = useState<number>(props.settings.update_interval);

  return (
    <>
      <Typography.Title level={3}>{t('base-settings')}</Typography.Title>
      <hr />

      <Styled.Row>
        <Col span={4}>
          <Tooltip title={t('device-enabled-description')}>
            <Typography.Text>{t('device-enabled')}</Typography.Text> <InfoCircleTwoTone />
          </Tooltip>
        </Col>
        <Col span={20}>
          <Checkbox
            label=""
            checked={active}
            onChange={(newActive: boolean) => {
              setActive(newActive);
              props.settings.active = newActive;
              props.updateSettings(props.settings);
            }}
          />
        </Col>
      </Styled.Row>

      <Styled.Row>
        <Col span={4}>
          <Tooltip title={t('disable-native-apps-description')}>
            <Typography.Text>{t('disable-native-apps')}</Typography.Text> <InfoCircleTwoTone />
          </Tooltip>
        </Col>
        <Col span={20}>
          <Checkbox
            label=""
            checked={disableNative}
            onChange={(updated: boolean) => {
              setDisableNative(updated);
              props.settings.disable_native_apps = updated;
              props.updateSettings(props.settings);
            }}
          />
        </Col>
      </Styled.Row>

      <Styled.Row>
        <Col span={4}>
          <Tooltip title={t('update-interval-description')}>
            <Typography.Text>{t('update-interval')}</Typography.Text> <InfoCircleTwoTone />
          </Tooltip>
        </Col>
        <Col span={20}>
          <TimeField
            range={[10 * 1000, 24 * 60 * 60 * 1000]}
            value={update_interval}
            onChange={(value: number) => {
              setupdate_interval(value);
              props.settings.update_interval = value;
              props.updateSettings(props.settings);
            }}
          />
        </Col>
      </Styled.Row>

      <Styled.Row>
        <Col span={4}>
          <Tooltip title={t('app-blocklist-description')}>
            <Typography.Text>{t('app-blocklist')}</Typography.Text> <InfoCircleTwoTone />
          </Tooltip>
        </Col>
        <Col span={20}>
          <ExtensibleStringList
            header={t('suspended-apps-header')}
            list={appBlacklist}
            onAppend={(updated: string[]) => setAppBlacklist(updated)}
          />
        </Col>
      </Styled.Row>

      <Styled.Row>
        <Col span={4}>
          <Tooltip title={t('allowed-apps-description')}>
            <Typography.Text>{t('allowed-apps')}</Typography.Text> <InfoCircleTwoTone />
          </Tooltip>
        </Col>
        <Col span={20}>
          <ExtensibleStringList
            header={t('allowed-apps-header')}
            list={appWhitelist}
            onAppend={(updated: string[]) => setAppWhitelist(updated)}
          />
        </Col>
      </Styled.Row>

      <Styled.Row>
        <Col span={4}>
          <Tooltip title={t('uninstall-apps-description')}>
            <Typography.Text>{t('uninstall-apps')}</Typography.Text> <InfoCircleTwoTone />
          </Tooltip>
        </Col>
        <Col span={20}>
          <ExtensibleStringList
            header={t('uninstall-apps-header')}
            list={uninstallApps}
            onAppend={(updated: string[]) => setUninstallApps(updated)}
          />
        </Col>
      </Styled.Row>
    </>
  );
};
