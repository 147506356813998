import { Maybe } from './types/Monads';

export interface Config {
  MDM_API: string;
  BASE_URL: string;
  CDN_URL: string;
  CLIENT_ID: string;
  IP_API: string;
  SENTRY_DSN: string;
  USER_MANAGEMENT_URL: string;

  [key: string]: string;
}

const setIfDefined = (x: Maybe<string>, xDefault: string) => (x !== undefined && x !== null ? x : xDefault);

const initConfig = (): Config => {
  if (window.location.host === 'ui.droidmdm.de') {
    return {
      MDM_API: setIfDefined(process.env.MDM_API_PROD, 'https://api.droidmdm.de'),
      BASE_URL: setIfDefined(process.env.BASE_URL_PROD, 'https://ui.droidmdm.de'),
      CDN_URL: setIfDefined(process.env.CDN_URL_PROD, ''),
      CLIENT_ID: setIfDefined(process.env.CLIENT_ID_PROD, 'mdm-ui'),
      IP_API: setIfDefined(process.env.IP_API_PROD, 'https://ip.apyon.de'),
      SENTRY_DSN: setIfDefined(
        process.env.SENTRY_DSN_PROD,
        'https://f3a01145e7214a9990e9a520563ed5cc@sentry.droidnet.de/18'
      ),
      USER_MANAGEMENT_URL: setIfDefined(process.env.USER_MANAGEMENT_URL_PROD, 'https://ui.droidmdm.de/usermanagement'),
    };
  } else if (window.location.host === 'mdm-ui.platform.droidcloud.de') {
    return {
      MDM_API: setIfDefined(process.env.MDM_API_PROD, 'https://mdm-api.platform.droidcloud.de'),
      BASE_URL: setIfDefined(process.env.BASE_URL_PROD, 'https://mdm-ui.platform.droidcloud.de'),
      CDN_URL: setIfDefined(process.env.CDN_URL_PROD, ''),
      CLIENT_ID: setIfDefined(process.env.CLIENT_ID_PROD, 'mdm-ui'),
      IP_API: setIfDefined(process.env.IP_API_PROD, 'https://ip.platform.droidcloud.de'),
      SENTRY_DSN: setIfDefined(
        process.env.SENTRY_DSN_PROD,
        'https://f3a01145e7214a9990e9a520563ed5cc@sentry.droidnet.de/18'
      ),
      USER_MANAGEMENT_URL: setIfDefined(process.env.USER_MANAGEMENT_URL_PROD, 'https://ui.droidmdm.de/usermanagement'),
    };
  } else if (window.location.host === 'mdm-ui.test.platform.droidcloud.de') {
    return {
      MDM_API: setIfDefined(process.env.MDM_API_PROD, 'https://mdm-api.test.platform.droidcloud.de'),
      BASE_URL: setIfDefined(process.env.BASE_URL_PROD, 'https://mdm-ui.test.platform.droidcloud.de'),
      CDN_URL: setIfDefined(process.env.CDN_URL_PROD, ''),
      CLIENT_ID: setIfDefined(process.env.CLIENT_ID_PROD, 'mdm-ui'),
      IP_API: setIfDefined(process.env.IP_API_PROD, 'https://ip.test.platform.droidcloud.de'),
      SENTRY_DSN: setIfDefined(
        process.env.SENTRY_DSN_PROD,
        'https://f3a01145e7214a9990e9a520563ed5cc@sentry.droidnet.de/18'
      ),
      USER_MANAGEMENT_URL: setIfDefined(process.env.USER_MANAGEMENT_URL_PROD, 'https://ui.droidmdm.de/usermanagement'),
    };
  } else if (window.location.host === 'ui-mdm.humboldtforum.org') {
    return {
      MDM_API: setIfDefined(process.env.MDM_API_HFM_PROD, 'https://api-mdm.humboldtforum.org'),
      BASE_URL: setIfDefined(process.env.BASE_URL_HFM_PROD, 'https://ui-mdm.humboldtforum.org'),
      CDN_URL: setIfDefined(process.env.CDN_URL_HFM_PROD, ''),
      CLIENT_ID: setIfDefined(process.env.CLIENT_ID_HFM_PROD, 'mdm-ui'),
      IP_API: setIfDefined(process.env.IP_API_HFM_PROD, 'https://idp-mdm.humboldtforum.org'),
      SENTRY_DSN: setIfDefined(
        process.env.SENTRY_DSN_PROD,
        'https://f3a01145e7214a9990e9a520563ed5cc@sentry.droidnet.de/18'
      ),
      USER_MANAGEMENT_URL: setIfDefined(
        process.env.USER_MANAGEMENT_URL,
        'https://api.mdm.humboldtforum.org/usermanagement'
      ),
    };
  } else if (window.location.host === 'localclient:3000') {
    return {
      MDM_API: setIfDefined(
        process.env.MDM_API,
        /*'https://mdm.test-appcreator.droidnet.de'*/ 'https://mdm-api.dev.droidnet.de'
      ),
      BASE_URL: setIfDefined(process.env.BASE_URL, 'https://localclient:3000'),
      CDN_URL: setIfDefined(process.env.CDN_URL, ''),
      CLIENT_ID: setIfDefined(process.env.CLIENT_ID, 'localclient'),
      IP_API: setIfDefined(process.env.IP_API, 'https://ip.test-appcreator.droidnet.de'),
      SENTRY_DSN: setIfDefined(
        process.env.SENTRY_DSN,
        'https://f3a01145e7214a9990e9a520563ed5cc@sentry.droidnet.de/18'
      ),
      USER_MANAGEMENT_URL: setIfDefined(
        process.env.USER_MANAGEMENT_URL,
        'https://mdm-ui.test-appcreator.droidnet.de/usermanagement'
      ),
    };
  } else {
    return {
      MDM_API: setIfDefined(process.env.MDM_API, 'https://mdm.test-appcreator.droidnet.de'),
      BASE_URL: setIfDefined(process.env.BASE_URL, 'https://mdm-ui.test-appcreator.droidnet.de'),
      CDN_URL: setIfDefined(process.env.CDN_URL, ''),
      CLIENT_ID: setIfDefined(process.env.CLIENT_ID, 'test-mdm-ui'),
      IP_API: setIfDefined(process.env.IP_API, 'https://ip.test-appcreator.droidnet.de'),
      SENTRY_DSN: setIfDefined(
        process.env.SENTRY_DSN,
        'https://f3a01145e7214a9990e9a520563ed5cc@sentry.droidnet.de/18'
      ),
      USER_MANAGEMENT_URL: setIfDefined(
        process.env.USER_MANAGEMENT_URL,
        'https://mdm-ui.test-appcreator.droidnet.de/usermanagement'
      ),
    };
  }
};

export default { ...initConfig() };
