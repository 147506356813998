import React, { FC } from 'react';
import { Descriptions, Typography } from 'antd';

import { Device } from './Device.types';

import Styled from './DeviceDescriptionBox.styled';
import DateUtils from '../../lib/utils/DateUtils';
import { DeviceActiveIcon } from '../shared/Icons/DeviceActivieIcon';
import { DeviceRegisteredIcon } from '../shared/DeviceRegistered/DeviceRegisteredIcon';
import { useTranslation } from 'react-i18next';

export interface DeviceDescriptionBoxProps {
  device: Device;
}

export const DeviceDescriptionBox: FC<DeviceDescriptionBoxProps> = (props: DeviceDescriptionBoxProps) => {
  const { t } = useTranslation();

  const device = props.device;
  const updateTime = device.updatedAt ? DateUtils.ts2datestring(device.updatedAt) : '--';

  const registrationTime = device.registeredAt ? DateUtils.ts2datestring(device.registeredAt) : '--';

  return (
    <Styled.Box>
      <Typography.Title level={4}>{t('device-properties')}</Typography.Title>
      <Descriptions column={2}>
        <Descriptions.Item label={t('active')}>
          <DeviceActiveIcon device={device} />
        </Descriptions.Item>
        <Descriptions.Item label={t('registered')}>
          <DeviceRegisteredIcon device={device} />
        </Descriptions.Item>

        <Descriptions.Item label={t('type')}>{device.type}</Descriptions.Item>
        <Descriptions.Item label={t('model')}>{device.model}</Descriptions.Item>

        <Descriptions.Item label={t('device-id')}>{device.deviceId}</Descriptions.Item>
        <Descriptions.Item label={t('hardware-id')}>{device.hwId}</Descriptions.Item>

        <Descriptions.Item label={t('updated-at')}>{updateTime}</Descriptions.Item>
        <Descriptions.Item label={t('registered-at')}>{registrationTime}</Descriptions.Item>
      </Descriptions>
    </Styled.Box>
  );
};
