import React from "react";
import { Typography } from "antd";

import { PageLayout } from "../shared/layout/PageLayout";

export const ErrorPage = () => {
  return (
    <PageLayout>
      <Typography.Title level={2}>Error</Typography.Title>
      <p>wow, that shouldn't have happened...</p>
    </PageLayout>
  );
};
