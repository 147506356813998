/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-console */

import React, { useEffect, useState, FC } from "react";
import { Redirect } from "react-router-dom";
import { message, Typography, Row, Col, Card } from "antd";

import { jwtDecode } from "../../lib/auth/jwtDecode";

import { PageLayout } from "../shared/layout/PageLayout";
import {
  getTokenFromLocalStorage,
  getTokenFromUrl,
  storeToken,
} from "../../lib/auth/auth";
import { TokenInfo } from "../shared/Debug/TokenInfo";
import { NoData } from "@droidsolutions/ui-components";

const tokenParseErrorMessage = "Your access Token is malformed.";
const tokenExpiryTimeout = 3000;
const tokenExpiryWarningMessage =
  "Your access Token is expired, you are being redirected to the login.";

/**
 * Enables the storage of session tokens, if they are present in the url via redirect
 * from the identity provider.
 *
 * @param setRedirect a function that sets a redirection target
 * @param setToken a function that updates the token
 */
const enableSessionTokenOrRedirect = (
  setRedirect: Function,
  setToken: Function
) => {
  // if a token is already set and available in the local storage, update the component state
  const localToken = getTokenFromLocalStorage();

  if (localToken) {
    setToken(localToken);
  } else {
    // if not, try to parse it from the url
    const tokenFromUrl = getTokenFromUrl();

    if (tokenFromUrl && tokenFromUrl.length > 0) {
      // if it can be parsed, store it and remove the token from the url
      storeToken(tokenFromUrl);

      // decode token for debugging
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const decoded = jwtDecode(tokenFromUrl);

      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (decoded.exp < Date.now()) {
        message.warn(tokenExpiryWarningMessage);

        // redirect to "/login" after "tokenExpiryTimeout"
        setTimeout(() => {
          setRedirect("/login");
        }, tokenExpiryTimeout);
      } else {
        setToken(tokenFromUrl);
      }
    } else {
      // if no token can be parsed, show an error message
      message.error(tokenParseErrorMessage);
    }
  }
};

/**
 * Enables the storage of session tokens, if they are present in the url via redirect
 * from the identity provider.
 *
 * @param setRedirect a function that sets a redirection target
 * @param setToken a function that updates the token
 */
export const Dashboard: FC = () => {
  const [redirect, setRedirect] = useState("");
  const [token, setToken] = useState("");

  /**
   * side effect: if a session token is present, store it in the local storage, if the token is expired
   * redirect to /login
   */
  useEffect(() => {
    enableSessionTokenOrRedirect(setRedirect, setToken);
  }, [redirect, setRedirect, token, setToken]);

  return redirect !== "" ? (
    <Redirect to={redirect} />
  ) : (
    <PageLayout>
      <Typography.Title level={2}>Dashboard</Typography.Title>
      <Row>
        <Col span={12}>
          <Typography.Paragraph>Welcome to droid.MDM.</Typography.Paragraph>
          {token ? (
            <Typography.Paragraph>
              Login successfull. You're beeing redirected to your Projects.{" "}
              <Redirect to="/" />
            </Typography.Paragraph>
          ) : (
            <Typography.Paragraph>
              You are currently not logged in. You will be redirected in a few
              seconds
            </Typography.Paragraph>
          )}
          <Typography.Paragraph></Typography.Paragraph>
        </Col>
      </Row>
    </PageLayout>
  );
};
