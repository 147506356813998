/* eslint-disable @typescript-eslint/no-unsafe-return */
import { getAuthHeaders } from '../../lib/auth/headers';
import { MultipleProjects, ProjectDeleteResponse, ProjectDetail } from './Project.types';

import Url from '../../lib/utils/Url';
import { Maybe } from '../../types/Monads';

/**
 * Makes an create put-query for a project.
 *
 * @param name the project name
 * @param desc text, describing the project
 */
export const putProject = async (name: string, desc: string): Promise<Response> => {
  const newProjectUrl = Url.api('project');
  return fetch(newProjectUrl, {
    method: 'PUT',
    headers: getAuthHeaders(),
    cache: 'no-cache',
    mode: 'cors',
    body: JSON.stringify({ name, desc }),
  });
};

/**
 * Makes an update post-query for a project.
 *
 * @param slug the project slug
 * @param project the project that should be posted
 */
export const postProject = async (project: ProjectDetail): Promise<Response> => {
  const projectDetailUrl = Url.api(`project/${project.slug}`);
  return fetch(projectDetailUrl, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: getAuthHeaders(),
    body: JSON.stringify(project),
  });
};

/**
 * @returns a project's details
 * @param slug the project slug
 */
export const fetchProjectDetail = async (slug: string): Promise<Maybe<ProjectDetail>> => {
  const projectDetailUrl = Url.api(`project/${slug}`);
  const data = await fetch(projectDetailUrl, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    headers: getAuthHeaders(),
  });

  return data.json();
};

/**
 * @returns a list of all projects with access to the logged in user.
 */
export const fetchProjectList = async (): Promise<Array<ProjectDetail>> => {
  const projectListUrl = Url.api('project/');
  const data = await fetch(projectListUrl, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    headers: getAuthHeaders(),
  });

  return data.json();
};

/**
 *  Deletes a list of projects
 */
export const deleteMultipleProjects2 = async (projects: string[]): Promise<ProjectDeleteResponse> => {
  const projectDeletetUrl = Url.api('project/delete-multiple');

  const requestBody: MultipleProjects = {
    projectSlugs: projects,
  };

  const data = await fetch(projectDeletetUrl, {
    method: 'DELETE',
    mode: 'cors',
    cache: 'no-cache',
    headers: getAuthHeaders(),
    body: JSON.stringify(requestBody),
  });

  return data.json();
};

/**
 *  Deletes a list of projects
 */
export const deleteMultipleProjects = async (projects: string[]): Promise<Response> => {
  let data: Response;

  for (const project of projects) {
    const projectDeletetUrl = Url.api(`project/${project}`);

    data = await fetch(projectDeletetUrl, {
      method: 'DELETE',
      mode: 'cors',
      cache: 'no-cache',
      headers: getAuthHeaders(),
      //body: JSON.stringify(requestBody),
    });
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return data;
};
