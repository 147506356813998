import { message } from "antd";

export const checkResponseStatus = (
  resource: string,
  id: string,
  res: Response
): boolean => {
  if (res.status === 500) {
    message.error(`General system error querying ${resource} for: ${id}`);
    return false;
  }

  if (res.status === 404) {
    message.error(`Cound not find ${resource} for: ${id}`);
    return false;
  }

  if (res.status === 401) {
    message.error(`Invalid Token provided while accessing ${name}: ${id}`);
    return false;
  }

  if (res.status === 403) {
    message.error(`You are not authorized to access ${name}: ${id}`);
    return false;
  }

  return true;
};
