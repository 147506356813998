import React, { useState, FC } from 'react';
import { Col, Row, Typography, Tooltip } from 'antd';
import { InfoCircleOutlined, InfoCircleTwoTone } from '@ant-design/icons';

import { KioskAppConfig } from './DeviceSettings.types';
import { Checkbox, Input } from '@droidsolutions/ui-components';
import { MDMColorPicker } from '../shared/MDMColorPicker/MDMColorPicker';
import { SmallDescription } from '../shared/SmallDescription';
import { useTranslation } from 'react-i18next';

import Styled from './Settings.styled';

export interface KioskAppConfigProps {
  settings: KioskAppConfig;
  updateSettings: (updated: KioskAppConfig) => void;
}

export const KioskAppConfigPanel: FC<KioskAppConfigProps> = (props: KioskAppConfigProps) => {
  const { t } = useTranslation();

  /**
   * Stateful field values and updaters
   */
  const [mainIcon, setMainIcon] = useState<string>(props.settings.main_icon);
  const [mainText, setMainText] = useState<string>(props.settings.main_text);
  const [showBrand, setShowBrand] = useState<boolean>(props.settings.show_kuldig_brand);

  /**
   * Colors
   */
  const [button_color, setbutton_color] = useState<string>(props.settings.colors.button_color || 'FFFFFF');
  const [button_text_color, setbutton_text_color] = useState<string>(
    props.settings.colors.button_text_color || 'FFFFFF'
  );
  const [text_heading_color, settext_heading_color] = useState<string>(
    props.settings.colors.text_heading_color || 'FFFFFF'
  );
  const [text_normal_color, settext_normal_color] = useState<string>(
    props.settings.colors.text_normal_color || 'FFFFFF'
  );
  const [background_color, setbackground_color] = useState<string>(props.settings.colors.background_color || 'FFFFFF');

  return (
    <>
      <Typography.Title level={3}>{t('kiosk-settings')}</Typography.Title>
      <hr />

      <Styled.Row>
        <Col span={4}>
          <Tooltip title={t('show-kuldig-reference-description')}>
            <Typography.Text>{t('show-kuldig-reference')}</Typography.Text> <InfoCircleTwoTone />
          </Tooltip>
        </Col>
        <Col span={20}>
          <Checkbox
            label={t('show-kuldig-reference')}
            checked={showBrand}
            onChange={(updatedBrand: boolean) => {
              setShowBrand(updatedBrand);
              props.settings.show_kuldig_brand = updatedBrand;

              props.updateSettings(props.settings);
            }}
          />
        </Col>
      </Styled.Row>

      <Styled.Row>
        <Col span={4}>
          <Tooltip title={t('logo-on-main-screen-description')}>
            <Typography.Text>{t('logo-on-main-screen')}</Typography.Text> <InfoCircleTwoTone />
          </Tooltip>
        </Col>
        <Col span={20}>
          <Input
            value={mainIcon}
            placeholder={t('logo-on-main-screen')}
            onChange={(e) => {
              const updated = e.target.value;

              setMainIcon(updated);
              props.settings.main_icon = updated;

              props.updateSettings(props.settings);
            }}
          />
        </Col>
      </Styled.Row>

      <Styled.Row>
        <Col span={4}>
          <Tooltip title={t('text-on-main-screen-description')}>
            <Typography.Text>{t('text-on-main-screen')}</Typography.Text> <InfoCircleTwoTone />
          </Tooltip>
        </Col>
        <Col span={20}>
          <Input
            value={mainText}
            placeholder={t('text-on-main-screen')}
            onChange={(e) => {
              const updated = e.target.value;

              setMainText(updated);
              props.settings.main_text = updated;

              props.updateSettings(props.settings);
            }}
          />
        </Col>
      </Styled.Row>

      <Typography.Title level={4}>{t('colors')}</Typography.Title>
      <hr />

      <Styled.Row>
        <Col span={4}>
          <Tooltip title={t('button-background-color-description')}>
            <Typography.Text>{t('button-background-color')}</Typography.Text> <InfoCircleTwoTone />
          </Tooltip>
        </Col>
        <Col span={18}>
          <MDMColorPicker
            color={button_color}
            updateSettings={(color: string) => {
              setbutton_color(color);

              props.settings.colors.button_color = color;
              props.updateSettings(props.settings);
            }}
          />
        </Col>
      </Styled.Row>

      <Styled.Row>
        <Col span={4}>
          <Tooltip title={t('button-text-color-description')}>
            <Typography.Text>{t('button-text-color')}</Typography.Text> <InfoCircleTwoTone />
          </Tooltip>
        </Col>
        <Col span={18}>
          <MDMColorPicker
            color={button_text_color}
            updateSettings={(color: string) => {
              setbutton_text_color(color);

              props.settings.colors.button_text_color = color;
              props.updateSettings(props.settings);
            }}
          />
        </Col>
      </Styled.Row>

      <Styled.Row>
        <Col span={4}>
          <Tooltip title={t('screen-background-color-description')}>
            <Typography.Text>{t('screen-background-color')}</Typography.Text> <InfoCircleTwoTone />
          </Tooltip>
        </Col>
        <Col span={18}>
          <MDMColorPicker
            color={background_color}
            updateSettings={(color: string) => {
              setbackground_color(color);

              props.settings.colors.background_color = color;
              props.updateSettings(props.settings);
            }}
          />
        </Col>
      </Styled.Row>

      <Styled.Row>
        <Col span={4}>
          <Tooltip title={t('screen-text-color-description')}>
            <Typography.Text>{t('screen-text-color')}</Typography.Text> <InfoCircleTwoTone />
          </Tooltip>
        </Col>
        <Col span={18}>
          <MDMColorPicker
            color={text_normal_color}
            updateSettings={(color: string) => {
              settext_normal_color(color);

              props.settings.colors.text_normal_color = color;
              props.updateSettings(props.settings);
            }}
          />
        </Col>
      </Styled.Row>

      <Styled.Row>
        <Col span={4}>
          <Tooltip title={t('headline-text-color-description')}>
            <Typography.Text>{t('headline-text-color')}</Typography.Text> <InfoCircleTwoTone />
          </Tooltip>
        </Col>
        <Col span={18}>
          <MDMColorPicker
            color={text_heading_color}
            updateSettings={(color: string) => {
              settext_heading_color(color);

              props.settings.colors.text_heading_color = color;
              props.updateSettings(props.settings);
            }}
          />
        </Col>
      </Styled.Row>
    </>
  );
};
