import { styled } from '../../../style/theme';
import { Box, Button } from '@droidsolutions/ui-components';
import { Row, Switch } from 'antd';

export default {
  Switch: styled(Switch)`
    background: green;

    &.ant-switch-checked {
      background: #1890ff;
    }
  `,
  Row: styled(Row)`
    padding: 10px 0 10px 0;

    &:hover {
      background: #f8f8f8;
    }
  `,
};
