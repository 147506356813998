import React, { FC } from "react";
import { useTransition } from "react-spring";
import Logo from "../../assets/Apyon-Logo.svg";
import Styled from "./AnimatedLogoheader.styled";

interface AnimatedLogoHeaderProps {
  fadeOut?: boolean;
}

export const AnimatedLogoHeader: FC<AnimatedLogoHeaderProps> = ({
  fadeOut,
}) => {
  const headerBarTransitions = useTransition(!fadeOut, null, {
    enter: { width: "100%" },
    from: { width: "100%" },
    leave: { width: "0%" },
  });

  return (
    <Styled.AnimatedLogoHeader>
      <Styled.Logo>
        <Logo />
      </Styled.Logo>
      {headerBarTransitions.map(
        ({ item, key, props }) =>
          item && <Styled.HeaderBar key={key} style={props} />
      )}
    </Styled.AnimatedLogoHeader>
  );
};
