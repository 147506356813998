/* eslint-disable no-console */

import axios from "axios";
import { message } from "antd";
import { isAfter } from "date-fns";

import config from "../../config";
import { Maybe } from "../../types/Monads";

// error messages
const idpErrorMessage =
  "There was an error while contacting the identiy provider. Please try again later.";
const tokenInvalidMessage = "Your access token seems to be invalid!";
const accessTokenRevokedMessage = "Your access token has been .";

export interface PartialJTWToken {
  customer_id?: string;
  exp?: number;
  organisations?: string;
  sub?: string;

  [key: string]: string | number | undefined;
}

/** @returns an alphanimeric, string encoded nonce for the login handshake procedure */
export const generateNonce = () => {
  const alphabet =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  return new Array(8)
    .fill(null)
    .map(() => alphabet.charAt(Math.floor(Math.random() * alphabet.length)))
    .join("");
};

/** @returns the token value from the local storage or null */
export const getTokenFromLocalStorage = () =>
  window.localStorage.getItem("token");

/** updates the local storage with the new token state */
export const storeToken = (token: string) =>
  window.localStorage.setItem("token", token);

/** @returns the IdP Url for the login action */
export const getLoginUrl = async (): Promise<string> => {
  try {
    const idpUrl = config.IP_API;
    const idpResponse = await axios.get(idpUrl);

    if (idpResponse.status < 300) {
      return idpResponse.data.authorization_endpoint;
    } else {
      throw new Error(`IdP Error: ${idpResponse.status}`);
    }
  } catch (err) {
    message.error(err.message);
    console.error(err);

    return "";
  }
};

/** @returns the IdP Url for the logout action */
export const getLogoutUrl = async (): Promise<string> => {
  try {
    const idpUrl = config.IP_API;
    const idpResponse = await axios.get(idpUrl);

    if (idpResponse.status < 300) {
      return idpResponse.data.end_session_endpoint;
    } else {
      throw new Error(`IdP Error: ${idpResponse.status}`);
    }
  } catch (err) {
    message.error(err.message);
    console.error(err);

    return "";
  }
};

/** @returns true if the token in the local storage is valid */
export const checkTokenValidity = (): boolean => {
  const token = getTokenFromLocalStorage();
  if (!token) {
    return false;
  }

  const parsedToken = JSON.parse(window.atob(token.split(".")[1]));
  const expiryTime = parsedToken.exp * 1000;

  return isAfter(new Date(expiryTime), new Date());
};

/** @returns a string representing token that was transmitted as a url parameter */
export const getTokenFromUrl = (): Maybe<string> => {
  const locationFragment = window.location.hash;

  if (!locationFragment.startsWith("#id_token=")) {
    return "";
  } else {
    const fragments = locationFragment.split("#id_token=");
    const tokenFragment = fragments[1];

    if (!tokenFragment) {
      return "";
    } else {
      return tokenFragment.split("&")[0];
    }
  }
};

/** @returns the url parameters for the login action */
export const getLoginParams = () => {
  const loginParams = new URLSearchParams();

  loginParams.append("response_type", "id_token");
  loginParams.append("scope", "openid email mdm profile");
  loginParams.append("client_id", config.CLIENT_ID);
  loginParams.append("redirect_uri", `${config.BASE_URL}/dashboard`);
  loginParams.append("nonce", generateNonce());

  return loginParams.toString();
};

/** performs the login action on the IdP server */
export const login = async (): Promise<void> => {
  console.log("# login");

  try {
    const idpLoginUrl = await getLoginUrl();
    const params = getLoginParams();

    console.log(`login::${idpLoginUrl}`);
    console.log(`params::${params}`);

    if (idpLoginUrl.length > 0) {
      const loginUrl = `${idpLoginUrl}?${params}`;
      console.log(loginUrl);
      window.location.replace(loginUrl);
    } else {
      throw new Error(idpErrorMessage);
    }
  } catch (err) {
    message.error(err.message);
    console.error(err);
  }
};

/** @returns the url parameters for the logout action */
export const getLogoutParams = (): string => {
  const logoutParams = new URLSearchParams();

  logoutParams.append("id_token_hint", getTokenFromLocalStorage() as string);
  logoutParams.append("post_logout_redirect_uri", config.BASE_URL);
  logoutParams.append("state", "logged_out");

  return logoutParams.toString();
};

/** performs the logout action on the IdP server */
export const logout = async (): Promise<void> => {
  try {
    const idpLogoutUrl = await getLogoutUrl();
    const params = getLogoutParams();

    if (window.localStorage.getItem("token") !== null) {
      message.info(accessTokenRevokedMessage);
      window.localStorage.removeItem("token");
    }

    if (idpLogoutUrl.length < 1) {
      throw new Error(idpErrorMessage);
    }

    window.location.replace(`${idpLogoutUrl}?${params}`);
  } catch (err) {
    message.error(err.message);
    console.error(err);
  }
};

/** @returns all organizations from the token in the local storage */
export const getOrganizationsFromToken = (): string[] => {
  const token = getTokenFromLocalStorage();
  if (!checkTokenValidity()) {
    message.warn(tokenInvalidMessage);
    window.localStorage.removeItem("token");

    console.log("> login");
    login();
  }

  if (!token) {
    return [];
  } else {
    const tokenParts: string[] = token.split(".");
    const customerToken: string = window.atob(tokenParts[1]);
    const decodedToken: PartialJTWToken = JSON.parse(customerToken);
    const userOrgs: string = decodedToken.organisations || "";

    return userOrgs.split(";");
  }
};

/** @returns a parsed and normalized JWT Token */
export const parseToken = (token: string) => {
  const { customer_id, exp, organisations, sub }: PartialJTWToken = JSON.parse(
    atob(token.split(".")[1])
  );
  return {
    customerId: customer_id,
    organizations: organisations !== undefined ? organisations.split(";") : [],
    tokenExpiration: exp !== undefined ? new Date(exp * 1000) : new Date(),
    userId: sub,
  };
};

/** @returns a string representing the users valid organization */
export const getCurrentOrganization = (): string => {
  const organizations = getOrganizationsFromToken();
  return organizations ? organizations[0] : "";
};

/** @returns the user id from a token */
export const getUserId = () => {
  const token = getTokenFromLocalStorage();
  return token ? parseToken(token).userId : undefined;
};
