import { LoadingSpinner } from '@droidsolutions/ui-components';
import { Descriptions, Typography } from 'antd';
import React, { useEffect, useState, FC } from 'react';
import { useParams } from 'react-router-dom';

import { ProjectDetail } from './Project.types';

import Styled from './ProjectDetailPage.styled';
import { PageLayout } from '../shared/layout/PageLayout';

import { DeviceGroupListEditable } from '../devicegroup/DeviceGroupListEditable';
import { fetchProjectDetail } from './Project.apifunctions';
import DateUtils from '../../lib/utils/DateUtils';
import { useTranslation } from 'react-i18next';

export interface ProjectsDetailProps {
  project: ProjectDetail;
}

export const ProjectsDetailPage: FC<ProjectsDetailProps> = (props: ProjectsDetailProps) => {
  const { t } = useTranslation();
  const { slug } = useParams<{ slug: string }>();

  const [project, setProject] = useState<ProjectDetail>();
  const [dataFetched, setDataFetched] = useState<boolean>(false);

  useEffect(() => {
    if (props.project === undefined && slug !== undefined && !dataFetched) {
      fetchProjectDetail(slug).then((fetchedProject) => {
        if (fetchedProject) {
          setProject(fetchedProject);
          setDataFetched(true);
        }
      });
    }
  }, [dataFetched, props.project, slug]);

  const displayedProject = props.project ?? project;
  // const getDevicesCount = () => {
  //   if (displayedProject?.groups) {
  //     return displayedProject.groups.map((g) => g.numDevices).reduce((p, c) => p + c, 0);
  //   }
  //   return 0;
  // };

  return (
    <PageLayout>
      {displayedProject ? (
        <>
          <Typography.Title level={3}>{displayedProject.name}</Typography.Title>
          <Descriptions column={2}>
            <Descriptions.Item label={t('number-of-groups')} key="1">
              {displayedProject.groups?.length}
            </Descriptions.Item>
            <Descriptions.Item label={t('updated-at')} key="2">
              <i>{DateUtils.ts2datestring(displayedProject.updatedAt)}</i>
            </Descriptions.Item>

            {/*<Descriptions.Item label={t('number-of-devices')} key="3">
              {getDevicesCount()}
      </Descriptions.Item>*/}
            <Descriptions.Item label={t('updated-by')} key="4">
              <i>{displayedProject.updatedBy}</i>
            </Descriptions.Item>
          </Descriptions>

          <Styled.Box>
            <Typography.Title level={4}>{t('description')}:</Typography.Title>
            <i>{displayedProject?.desc && displayedProject.desc.length > 0 ? displayedProject.desc : '--'}</i>
          </Styled.Box>

          <DeviceGroupListEditable project={displayedProject} />
        </>
      ) : (
        <LoadingSpinner />
      )}
    </PageLayout>
  );
};
