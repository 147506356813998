/* eslint-disable @typescript-eslint/restrict-template-expressions */

import { getTokenFromLocalStorage, getOrganizationsFromToken } from "./auth";

/**
 * @returns the application authorization headers for fetch operations.
 */
export const getAuthHeaders = (): Headers => {
  const token = getTokenFromLocalStorage();
  const organizations = getOrganizationsFromToken();
  const userOrg = organizations ? organizations[0] : "";

  return new Headers([
    ["Authorization", `Bearer ${token}`],
    ["content-type", "application/json"],
    ["x-organisation", userOrg],
  ]);
};
