import React, { useState, useEffect, FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Typography, message } from 'antd';
import { LoadingSpinner } from '@droidsolutions/ui-components';

import { PageLayout } from '../shared/layout/PageLayout';
import { ProjectList } from './ProjectList';
import { ProjectReference } from './Project.types';

import Styled from '../shared/ActionBar.styled';
import { fetchProjectList } from './Project.apifunctions';
import { useTranslation } from 'react-i18next';

/**
 * When a project has been updated on the server this function renders an appropriate message.
 */
const showProjectUpdateMessage = (t: (s: string) => string) => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const updateState = params.get('projectUpdate');

  switch (updateState) {
    case 'create-success': {
      return message.success(t('a-new-project-has-been-created-successfully'));
    }

    case 'update-success': {
      return message.success(t('a-project-has-been-updated-successfully'));
    }
  }
};

/**
 * This view renders an overview list for projects
 */
export const ProjectsOverviewPage: FC = () => {
  const { t } = useTranslation();

  const history = useHistory();
  const [projects, setProjects] = useState<Array<ProjectReference>>([]);
  const [dataFetched, setDataFetched] = useState<boolean>(false);

  showProjectUpdateMessage(t);

  useEffect(() => {
    if (!dataFetched) {
      fetchProjectList().then((projectList) => {
        const updatedProjectList = Array.isArray(projectList) ? projectList : [];

        setProjects(updatedProjectList);
        setDataFetched(true);
      });
    }
  }, [dataFetched, projects]);

  const createProjectUrl = '/project/new';
  const createProjectEventHandler = () => history.push(createProjectUrl);

  return (
    <PageLayout>
      {dataFetched ? (
        <>
          <Typography.Title level={2}>Projects</Typography.Title>
          <ProjectList projects={projects} />
          <Styled.ActionBar>
            <Styled.AddPhoneButton icon="addSmall" styling="important" onClick={() => createProjectEventHandler()}>
              {t('new-project')}
            </Styled.AddPhoneButton>
          </Styled.ActionBar>
        </>
      ) : (
        <LoadingSpinner />
      )}
    </PageLayout>
  );
};
