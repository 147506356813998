import React, { useState, FC } from 'react';

import { ColorPicker } from '@droidsolutions/ui-components';
import { ColorChangeHandler, ColorResult, SketchPicker } from 'react-color';
import { readableColor } from 'polished';

export interface MDMColorPickerProps {
  color: string;
  updateSettings: (color: string) => void;
}

/** remove the '#' character at the start of the color code */
const parseColor = (color: string) => (color.startsWith('#') ? color.substr(1) : color);

/**
 * Generic Color Picker for the MDM UI, based on the @droidsolutions/ui-components
 * Color Picker.
 */
export const MDMColorPicker: FC<MDMColorPickerProps> = (props: MDMColorPickerProps) => {
  const [color, setcolor] = useState<string>(props.color);
  const [colorPickerVisible, setColorPickerVisible] = useState<boolean>(false);

  // eslint-disable-next-line no-console
  const onSelect = (color: ColorResult) => {
    const parsedColor = parseColor(color.hex);
    props.updateSettings(parsedColor);
    setcolor(parsedColor);
  };

  return (
    <>
      <div
        style={{
          padding: '5px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
        }}
        onClick={() => setColorPickerVisible(true)}
      >
        <div
          style={{
            padding: '5px',
            borderRadius: '2px',
            background: '#' + color,
            fontFamily: 'monospace',
          }}
        >
          <span style={{ color: readableColor(`#${color}`) }}>{`#${color}`}</span>
        </div>
      </div>

      {colorPickerVisible ? (
        <div style={{ position: 'relative', zIndex: 2 }}>
          <div
            style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0 }}
            onClick={() => setColorPickerVisible(false)}
          />
          <SketchPicker disableAlpha={true} color={`#${color.toLowerCase()}`} onChange={onSelect} />
        </div>
      ) : null}
    </>
  );
};

/**
 * Generic Color Picker for the MDM UI, based on the @droidsolutions/ui-components
 * Color Picker.
 */
export const MDMColorPicker2: FC<MDMColorPickerProps> = (props: MDMColorPickerProps) => {
  const [color, setcolor] = useState<string>(props.color);

  // eslint-disable-next-line no-console
  const onClose = () => console.log('colorpicker: close');
  const onSelect = (color: string) => {
    const parsedColor = parseColor(color.toLowerCase());
    props.updateSettings(parsedColor);
    setcolor(parsedColor);
  };

  return (
    <>
      <ColorPicker
        disableTransparent={true}
        embedded={true}
        color={`#${color.toLowerCase()}`}
        onClose={onClose}
        onSelect={onSelect}
        title=""
      />
    </>
  );
};
