/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react-hooks/rules-of-hooks */

import React, { useState, FC } from 'react';
import { Row, Col, Modal, Typography, InputNumber, Table, Tooltip } from 'antd';
import { arrayMove } from '@dnd-kit/sortable';

import { MenuOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table/index';

import { Button } from '@droidsolutions/ui-components';

import { MediaAppConfig } from '../DeviceSettings.types';

import { DeleteButton } from '../../shared/Buttons/DeleteButton';
import { EditButton } from '../../shared/Buttons/EditButton';
import { CheckIcon } from '../../shared/Icons/CheckIcon';

import { useTranslation } from 'react-i18next';
import { AddMediaAppModal } from './AddMediaAppModal';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';

import hash from 'object-hash';
import styled from 'styled-components';

class InternalMediaAppConfig implements MediaAppConfig {
  package_name!: string;
  apk_url!: string;
  files_url!: string;
  logo_url!: string;
  update!: boolean;
  last_update!: number;
  version_code!: number;
  browser_url?: string | undefined;
  browser_mode_active?: boolean | undefined;
  app_label?: string | undefined;
  display_in_kiosk?: boolean | undefined;

  constructor(raw: MediaAppConfig) {
    Object.assign(this, { ...raw });
  }

  get hash(): string {
    return hash(this.toJSON());
  }

  toJSON() {
    const raw = { ...this, hash: undefined };
    return raw;
  }
}

export interface MediaListProps {
  mediaList: MediaAppConfig[];
  updateMediaList: (updated: MediaAppConfig[]) => void;
}
const MySortableItem = SortableElement((props: any) => <tr {...props} />);
const MySortableContainer = SortableContainer((props: any) => <tbody {...props} />);
const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);

export const MediaList: FC<MediaListProps> = (props: MediaListProps) => {
  const { t } = useTranslation();
  /**
   * States and Updaters
   */

  const [modalConfig, setModalConfig] = useState<{
    visible: boolean;
    edit: boolean;
    configIndex: number;
  }>({ visible: false, edit: false, configIndex: -1 });

  const [mediaItems, setMediaItems] = useState<InternalMediaAppConfig[]>(
    props.mediaList.map((m) => new InternalMediaAppConfig(m))
  );

  /** Updates an entry from the List */
  const updateMediaAppHandler = (updated: MediaAppConfig) => {
    const updatedList = [...mediaItems];

    if (updatedList[modalConfig.configIndex]) {
      updatedList[modalConfig.configIndex] = new InternalMediaAppConfig(updated);
      props.updateMediaList(updatedList);
      setMediaItems(updatedList);
    }
  };

  /** Deletes an entry from the List */
  const deleteFromList = (index: number): void => {
    const newMediaList = [...mediaItems];

    if (index > -1 && !isNaN(index)) {
      newMediaList.splice(index, 1);
      setTimeout(() => {
        props.updateMediaList(newMediaList);
      }, 10);
      setMediaItems(newMediaList);
    }
  };

  /** Adds a new entry to the List */
  const addNewMediaAppHandler = (newEntry: MediaAppConfig): void => {
    const updated = [...mediaItems];
    updated.push(new InternalMediaAppConfig(newEntry));
    props.updateMediaList(updated);
    setMediaItems(updated);
  };

  const columns: ColumnsType<MediaAppConfig> = [
    {
      title: 'Sort',
      dataIndex: 'sort',
      width: 30,
      className: 'drag-visible',
      render: () => <DragHandle />,
    },
    {
      title: 'Label',
      dataIndex: 'app_label',
    },
    {
      title: 'Type',
      dataIndex: 'browser_mode_active',
      render: (text, config) => {
        return config.browser_mode_active ? 'Browser' : 'Native App';
      },
    },
    {
      title: 'Display in KIOKS',
      dataIndex: 'display_in_kiosk',
      render: (text, config) => {
        return (
          <>
            <CheckIcon state={config.display_in_kiosk} /> {config.display_in_kiosk ? 'Yes' : 'No'}
          </>
        );
      },
    },
    {
      title: 'Open App',
      dataIndex: 'package_id',
      render: (text, config: MediaAppConfig) => {
        return config.browser_mode_active
          ? config.browser_url
          : `${config.package_name} (VersionCode: ${config.version_code})`;
      },
    },
    {
      title: 'Update native App',
      dataIndex: 'update',
      render: (text, config) => {
        if (config.browser_mode_active) return '---';
        return (
          <>
            <CheckIcon state={config.update} /> {config.update ? 'Yes' : 'No'}
          </>
        );
      },
    },
    {
      title: 'Last updated',
      dataIndex: 'last_update',
      render: (text, config: MediaAppConfig) => {
        return new Date(config.last_update).toDateString();
      },
    },
    {
      title: t('actions'),
      dataIndex: 'app_label',
      render: (text, wifi, index) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <EditButton
              index={index}
              onClick={() => {
                setModalConfig({
                  visible: true,
                  edit: true,
                  configIndex: index,
                });
              }}
            />
            <DeleteButton index={index} onClick={() => deleteFromList(index)} />
          </div>
        );
      },
    },
  ];

  const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMove([...mediaItems], oldIndex, newIndex).filter((el) => !!el);
      props.updateMediaList(newData);
      setMediaItems(newData);
    }
  };

  const DraggableContainer = (props: any) => (
    <MySortableContainer useDragHandle disableAutoscroll helperClass="row-dragging" onSortEnd={onSortEnd} {...props} />
  );

  const DraggableBodyRow = ({
    className,
    style,
    ...restProps
  }: {
    className: any;
    style: any;
    'data-row-key': string;
  }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = mediaItems.findIndex((x) => x.hash === restProps['data-row-key']);
    return <MySortableItem index={index} {...restProps} />;
  };

  const emptyAppConfig: MediaAppConfig = {
    update: false,
    apk_url: '',
    files_url: '',
    last_update: Date.now(),
    logo_url: '',
    package_name: '',
    version_code: 0,
    app_label: '',
    browser_mode_active: false,
    browser_url: '',
    display_in_kiosk: true,
  };
  /**
   * Rendering
   */
  return (
    <>
      <AddMediaAppModal
        visible={modalConfig.visible}
        edit={modalConfig.edit}
        config={{ ...(mediaItems[modalConfig.configIndex] || emptyAppConfig) }}
        onCancel={() => {
          setModalConfig({
            visible: false,
            edit: false,
            configIndex: -1,
          });
        }}
        onFinish={(config) => {
          if (modalConfig.edit) {
            updateMediaAppHandler(config);
          } else {
            addNewMediaAppHandler(config);
          }
          setModalConfig({
            visible: false,
            edit: false,
            configIndex: -1,
          });
        }}
      ></AddMediaAppModal>

      <Table
        pagination={false}
        columns={columns}
        dataSource={mediaItems}
        rowKey={'hash'}
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
          },
        }}
      ></Table>

      <Row>
        <Col span={21} />
        <Col span={3}>
          <Button
            icon="addSmall"
            styling="blue"
            onClick={() => {
              setModalConfig({
                visible: true,
                edit: false,
                configIndex: -1,
              });
            }}
          >
            Add Media Package
          </Button>
        </Col>
      </Row>
    </>
  );
};
