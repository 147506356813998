import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

// common
import { LandingPage } from "./components/views/LandingPage";
import { LoginRegistrationPage } from "./components/login/LoginRegistrationPage";
import { LogoutPage } from "./components/login/LogoutPage";
import { LoginUpdatePasswordPage } from "./components/login/LoginUpdatePasswordPage";
import { LoginPage } from "./components/login/LoginPage";
import { Dashboard } from "./components/views/Dashboard";
import { ErrorPage } from "./components/views/ErrorPage";

// project
import { ProjectsOverviewPage } from "./components/project/ProjectOverviewPage";
import { ProjectsDetailPage } from "./components/project/ProjectDetailPage";
import { ProjectEditPage } from "./components/project/ProjectEditPage";
import { CreateProjectPage } from "./components/project/ProjectCreatePage";

// device group
import { DeviceGroupPage } from "./components/devicegroup/DeviceGroupPage";
import { CreateGroupPage } from "./components/devicegroup/CreateGroupPage";

// device
import { DeviceRegistrationPage } from "./components/device/DeviceRegistrationPage";
import { DeviceDetailPage } from "./components/device/DeviceDetailPage";
import { DeviceSettingsPage } from "./components/deviceSettingsPage/DeviceSettingsPage";
import { GroupSettingsPage } from "./components/groupSettingsPage/GroupSettingsPage";

// statistics
import { DeviceStatisticsPage } from "./components/statistics/DeviceStatisticsPage";

export const Router: React.FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={LandingPage} />
        <Route exact path="/dashboard" component={Dashboard} />
        <Route exact path="/login" component={LoginPage} />
        <Route exact path="/login/register" component={LoginRegistrationPage} />
        <Route exact path="/login/logout" component={LogoutPage} />
        <Route
          exact
          path="/login/changepw"
          component={LoginUpdatePasswordPage}
        />
        <Route
          exact
          path="/register/device/:groupslug"
          component={DeviceRegistrationPage}
        />
        <Route exact path="/projects" component={ProjectsOverviewPage} />
        <Route path="/project/new" component={CreateProjectPage} />
        <Route path="/project/:slug/edit" component={ProjectEditPage} />
        <Route path="/project/:slug" component={ProjectsDetailPage} />
        <Route path="/project/:slug/new-group" component={CreateGroupPage} />

        <Route path="/devicegroup/:slug" component={DeviceGroupPage} />

        <Route
          path="/device/settings/:deviceId"
          component={DeviceSettingsPage}
        />

        <Route
          path="/group/settings/:groupSlug"
          component={GroupSettingsPage}
        />

        <Route path="/device/:deviceId" component={DeviceDetailPage} />
        <Route
          path="/statistics/device/:deviceId"
          component={DeviceStatisticsPage}
        />
        <Route component={ErrorPage} />
      </Switch>
    </BrowserRouter>
  );
};
