import React, { FC } from "react";
import { red, green } from "@ant-design/colors";
import { Device } from "../../device/Device.types";

export interface DeviceActiveProps {
  device: Device;
}

export const DeviceActiveIcon: FC<DeviceActiveProps> = (
  props: DeviceActiveProps
) => {
  const DeviceActivityState = (props: DeviceActiveProps) =>
    props.device.active ? (
      <span style={{ color: green.primary }}>✓</span>
    ) : (
      <span style={{ color: red.primary }}>✗</span>
    );

  return <DeviceActivityState device={props.device} />;
};
