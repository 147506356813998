import React, { FC } from "react";
import { StatisticsData, MonitoringInformation } from "./Statistics.types";

import { Collapse } from "antd";
import { StaticDataTimelineItem } from "./StaticDataTimelineItem";
import { Unwrapped, Unit } from "../../types/Monads";
import { toDateString } from "./time";

/** Statistics item: statistics collection */
export interface StaticDataTimelineProps {
  stats: StatisticsData;
}

export const StaticDataTimeline: FC<StaticDataTimelineProps> = (
  props: StaticDataTimelineProps
) => {
  const entries: Unwrapped<MonitoringInformation> = Object.entries(
    props.stats.stats
  );

  // eslint-disable-next-line no-console
  console.log("entries: ", entries);

  return (
    <Collapse accordion>
      {entries.map(([_key, item]: Unit<MonitoringInformation>, i: number) => (
        <Collapse.Panel header={toDateString(item.date)} key={i}>
          <StaticDataTimelineItem key={`key-${i}`} index={+i} statItem={item} />
        </Collapse.Panel>
      ))}
    </Collapse>
  );
};
