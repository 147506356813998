import { Typography, Descriptions } from 'antd';
import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { LoadingSpinner } from '@droidsolutions/ui-components';

import Styled from './DeviceGroupPage.styled';

import { PageLayout } from '../shared/layout/PageLayout';
import { DeviceGroup } from './DeviceGroup.types';
import { DeviceGroupDeviceList } from './DeviceGroupDeviceList';
import { fetchDevices, fetchGroup } from './DeviceGroup.apifunctions';
import { useTranslation } from 'react-i18next';
import { Device } from '../device/Device.types';

const { Title } = Typography;

export interface DeviceGroupPageProps {
  group: DeviceGroup;
}

export const DeviceGroupPage = (props: DeviceGroupPageProps) => {
  const { t } = useTranslation();

  const { slug } = useParams<{ slug: string }>();

  const history = useHistory();

  const [selectedGroup, setSelectedGroup] = useState<DeviceGroup>();
  const [devices, setDevices] = useState<Device[]>([]);
  const [devicesCount, setDevicesCount] = useState<number>(0);
  const [dataFetched, setDataFetched] = useState<boolean>(false);

  const [searchString, setSearchString] = useState('');

  const addPhoneEventHandler = () => {
    const registerGroupUrl = `/register/device/${slug}`;
    history.push(registerGroupUrl);
  };

  const groupSettingsEventHandler = () => {
    const groupSettingsUrl = `/group/settings/${slug}`;
    history.push(groupSettingsUrl);
  };

  const updateGroupHandler = (groupSlug: string) => {
    fetchGroup(groupSlug).then((fetchedGroup) => {
      // eslint-disable-next-line no-console
      console.log('UPDATED: ', groupSlug);

      if (fetchedGroup) {
        setSelectedGroup(fetchedGroup);
        setDataFetched(true);
      }
    });
  };

  useEffect(() => {
    fetchDevices(slug, 0, 10 * 1000).then((resp) => {
      setDevices(resp.devices);
      setDevicesCount(resp.count);
    });
  }, []);

  useEffect(() => {
    if (props.group === undefined && slug !== undefined && !dataFetched) {
      updateGroupHandler(slug);
    }
  }, [dataFetched, props.group, slug]);

  return (
    <PageLayout>
      {selectedGroup ? (
        <>
          <Title level={4}>
            {selectedGroup.name}
            &nbsp; <small style={{ fontSize: 12 }}>({(selectedGroup && selectedGroup.desc) || ''})</small>
          </Title>

          <Styled.ActionBar>
            <Styled.RegistrationButton icon="phoneIconBig" styling="important" onClick={addPhoneEventHandler}>
              {t('device-registration')}
            </Styled.RegistrationButton>

            <Styled.ConfigButton icon="settings" styling="important" onClick={groupSettingsEventHandler}>
              {t('group-settings')}
            </Styled.ConfigButton>
          </Styled.ActionBar>

          <Styled.Box>
            <i>{selectedGroup?.scope && selectedGroup.scope.length > 0 ? selectedGroup.scope : '--'}</i>
          </Styled.Box>
          <Styled.Box>
            <Styled.SearchBox>
              <Title level={4}>
                {t('devices')} ( {devices.length} )
              </Title>

              <Styled.Input
                onChange={(event) => setSearchString(event.target.value)}
                placeholder={t('search-by-device-hw-id')}
                value={searchString}
              />
            </Styled.SearchBox>
            <DeviceGroupDeviceList
              group={selectedGroup}
              devices={devices}
              updateGroup={(newGroup: string) => updateGroupHandler(newGroup)}
              searchString={searchString}
            />
          </Styled.Box>
        </>
      ) : (
        <LoadingSpinner />
      )}
    </PageLayout>
  );
};
