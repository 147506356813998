import { Device } from "../../components/device/Device.types";

export const getDisplayedDevices = (devices: Device[], searchString: string) =>
  devices.filter(
    (devices) =>
      devices.deviceId
        .toLocaleLowerCase()
        .includes(searchString.toLocaleLowerCase()) ||
      devices.hwId
        .toLocaleLowerCase()
        .includes(searchString.toLocaleLowerCase())
  );
