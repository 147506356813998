import { message } from "antd";
import React, { FC } from "react";
import { saveAs } from "file-saver";

import { Button } from "@droidsolutions/ui-components";
import { getAuthHeaders } from "../../../lib/auth/headers";

export interface FileDownloadProps {
  disabled: boolean;
  buttonText: string;
  downloadUrl: string;
  reference: string;
}

export const FileDownloadButton: FC<FileDownloadProps> = (
  props: FileDownloadProps
) => {
  const handleSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();

    try {
      const response = await fetch(props.downloadUrl, {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        headers: getAuthHeaders(),
      });

      if (response.status !== 200) {
        throw new Error(
          "Could not find registration corresponding to the selected group."
        );
      } else {
        const blob = await response.blob();
        saveAs(blob, `initial-configuration-${props.reference}.json`);
      }
    } catch (err) {
      message.error("An error occured while fetching the QR Response");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Button icon="downloadSmall" type="submit" disabled={props.disabled}>
        {props.buttonText}
      </Button>
    </form>
  );
};
