import React, { FC, Dispatch, useState } from "react";

import { TimePicker } from "@droidsolutions/ui-components";
import { Time } from "@droidsolutions/ui-components/build/types/Time";
import { toTime, toTs } from "./TimeField.functions";

/**
 * Time-Field properties
 */
export interface TimeFieldProps {
  range: [number, number];
  value: number;
  onChange: Dispatch<number>;
}

/**
 * A field that handles the input of times-deltas.
 * @param props time field properties
 */
export const TimeField: FC<TimeFieldProps> = (props: TimeFieldProps) => {
  const min: number = props.range[0];
  const max: number = props.range[1];

  const [time, setTime] = useState<Time>(toTime(props.value));

  return (
    <TimePicker
      cycle
      value={time}
      secondStep={10}
      onChange={(newValue: Time) => {
        const updatedTimestamp = toTs(newValue);

        if (updatedTimestamp < min) {
          setTime(toTime(min));
          props.onChange(min);
        } else if (updatedTimestamp > max) {
          setTime(toTime(max));
          props.onChange(max);
        } else {
          setTime(newValue);
          props.onChange(updatedTimestamp);
        }
      }}
    />
  );
};
