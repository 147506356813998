import React, { ReactElement, FC } from "react";
import { ProjectContext } from "./ProjectContext";

export interface ContextProviderProps {
  children: ReactElement;
}

export const ContextProvider: FC<ContextProviderProps> = (
  props: ContextProviderProps
) => {
  const context: ProjectContext = {
    saved: { success: false, message: "..." },
  };

  const ProjectContext = React.createContext(context.saved);

  return (
    <ProjectContext.Provider value={context.saved}>
      {props.children}
    </ProjectContext.Provider>
  );
};
