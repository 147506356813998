import React, { FC, useState, ChangeEvent } from 'react';
import { useHistory } from 'react-router-dom';
import { List, Typography, message } from 'antd';
import { Slider, NumberPicker, Toggle, DropdownMenu, Input } from '@droidsolutions/ui-components';

import Styled from './DeviceConfigBox.styled';
import { Device } from '../device/Device.types';
import { DeviceConfiguration, DisplayRotationMode, DeviceUpdateDTO } from './DeviceConfig.types';
import Url from '../../lib/utils/Url';
import { getAuthHeaders } from '../../lib/auth/headers';
import { useTranslation } from 'react-i18next';

export interface DeviceConfigProps {
  device: Device;
}

export const DeviceConfigBox: FC<DeviceConfigProps> = (props: DeviceConfigProps) => {
  const { t } = useTranslation();

  const history = useHistory();
  const config: DeviceConfiguration = props.device.configuration;

  const DisplayRotationSelection = [
    { name: 'portrait', text: t('portrait') },
    { name: 'landscape', text: t('landscape') },
  ];

  const [brightness_control, updateBrightnessControl] = useState(config.brightness_control);
  const [brightness, updateBrightness] = useState(config.brightness);
  const [disp_timeout, updateDispTimeout] = useState(config.disp_timeout);
  const [disp_rotation_lock, updateDispRotationLock] = useState(config.disp_rotation_lock);
  const [disp_rotation, updateDispRotation] = useState(config.disp_rotation);
  const [bluetooth_active, updateBluetoothActive] = useState(config.bluetooth_active);
  const [wifi_active, updateWifiActive] = useState(config.wifi_active);
  const [wifi_ssid, updateSSID] = useState(config.wifi_ssid);
  const [wifi_pw, updateWifiPw] = useState(config.wifi_pw);
  const [nfc_active, updateNfcActive] = useState(config.nfc_active);

  const handleSave = async () => {
    const updateConfigURL = Url.api(`configuration/for-device/${props.device.deviceId}`);

    const deviceId = '6a7db6f7-d4d4-47e7-8bb2-b0e88d0b382d';
    // const updateConfigURL = `https://mdm.droidnet.de/configuration/by-device/${deviceId}`;

    const deviceConfig: DeviceUpdateDTO = {
      active: true,
      lock_task: true,
      logo_url: '',
      configuration: {
        brightness,
        brightness_control,
        disp_timeout,
        disp_rotation,
        disp_rotation_lock,
        bluetooth_active,
        wifi_active,
        wifi_ssid,
        wifi_pw,
        nfc_active,
      },
    };

    const saveResponse = await fetch(updateConfigURL, {
      method: 'POST',
      headers: getAuthHeaders(),
      cache: 'no-cache',
      mode: 'cors',
      body: JSON.stringify(deviceConfig),
    });

    const savedProject = await saveResponse.json();

    if (saveResponse.status >= 200 && saveResponse.status < 300) {
      history.push({ pathname: '/projects', search: '?projectUpdate=success' });
    } else {
      const opMessage = savedProject.message || t('an-internal-server-error-occurred');
      message.error(opMessage, 3);
    }
  };

  return (
    <Styled.Box>
      <Typography.Title level={4}>Device Configuration</Typography.Title>
      <List itemLayout="horizontal">
        <List.Item>
          <span>{t('allow-brightness-changes')}:</span>
          <Toggle enabled={brightness_control} onTrigger={(newState) => updateBrightnessControl(newState)} />
        </List.Item>
        <List.Item>
          <span>{t('brightness')} (%)</span>
          <Slider
            min={0}
            max={100}
            step={1}
            value={brightness}
            onChange={(newBrightness) => updateBrightness(newBrightness)}
          />
        </List.Item>
        <List.Item>
          <span>{t('display-timeout')} (s)</span>
          <NumberPicker
            value={disp_timeout}
            min={0}
            max={100}
            cycle={true}
            onChange={(newdT) => updateDispTimeout(newdT)}
          />
        </List.Item>
        <List.Item>
          <span>{t('display-rotation-lock')}</span>
          <Toggle enabled={disp_rotation_lock} onTrigger={(newState) => updateDispRotationLock(newState)} />
        </List.Item>
        <List.Item>
          <span>{t('display-rotation')}</span>
          <DropdownMenu
            items={DisplayRotationSelection}
            selected={disp_rotation}
            onSelectItem={(newItem: string) => updateDispRotation(newItem as DisplayRotationMode)}
          />
        </List.Item>
        <List.Item>
          <span>{t('bluetooth')}</span>
          <Toggle enabled={bluetooth_active} onTrigger={(newState) => updateBluetoothActive(newState)} />
        </List.Item>
        <List.Item>
          <span>{t('wifi-enabled')}</span>
          <Toggle enabled={wifi_active} onTrigger={(newState) => updateWifiActive(newState)} />
        </List.Item>
        <List.Item>
          <span>{t('main-wifi-network-ssid')}</span>
          <Input
            value={wifi_ssid}
            placeholder={t('wifi-ssid')}
            onChange={(e) => {
              const newSSID = e.target.value;
              updateSSID(newSSID);
            }}
          />
        </List.Item>
        <List.Item>
          <span>{t('wifi-password')}</span>
          <Input
            value={wifi_pw}
            type="password"
            onChange={(event: ChangeEvent<HTMLInputElement>) => updateWifiPw(event.target.value)}
          />
        </List.Item>
        <List.Item>
          <span>{t('nfc-enabled')}</span>
          <Toggle enabled={nfc_active} onTrigger={(newState) => updateNfcActive(newState)} />
        </List.Item>
      </List>
      <Styled.ActionBar>
        <Styled.Button icon="saveProject" styling="important" onClick={handleSave}>
          {t('save')}
        </Styled.Button>
        <Styled.Button icon="closeBig" styling="important" onClick={() => history.goBack()}>
          {t('discard')}
        </Styled.Button>
      </Styled.ActionBar>
    </Styled.Box>
  );
};
