import React, { FC, useState } from "react";
import Axios from "axios";
import { EditorField, Button, Input } from "@droidsolutions/ui-components";

import Styled from "./Login.styled";
import Url from "../../lib/utils/Url";

/** Properties for the registration form. */
export interface RegisterProps {
  /** The base url for the identity provider */
  idpUrl: string;
}

/**
 * A registration form to create new users on the identity provider.
 * @param props the base url for the identity provider
 */
export const RegisterForm: FC<RegisterProps> = (props: RegisterProps) => {
  const axios = Axios.create({});

  const [firstname, setFirstname] = useState<string>("");
  const [surname, setSurname] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const registerUserPath = Url.to("register");

    const params = new URLSearchParams();
    params.append("username", email);
    params.append("password", password);

    // TODO: auf cors gedöns aufpassen, header mitnehmen, x-org...?
    axios.post(registerUserPath, params, {
      baseURL: props.idpUrl,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Styled.Outer>
        <Styled.Inner>
          <EditorField
            onBlur={(newValue) => setFirstname(newValue)}
            initialContent={firstname}
            label="First name"
            placeholder="First name..."
          />
        </Styled.Inner>

        <Styled.Inner>
          <EditorField
            onBlur={(newSurname) => setSurname(newSurname)}
            initialContent={surname}
            label="Surname"
            placeholder="Surname..."
          />
        </Styled.Inner>

        <Styled.Inner>
          <EditorField
            onBlur={(newMail) => setEmail(newMail)}
            initialContent={email}
            label="E-Mail"
            placeholder="E-Mail..."
          />
        </Styled.Inner>

        <Styled.Inner>
          <Styled.Label>Password</Styled.Label>
          <Input
            onChange={(evt) => setPassword(evt.target.value)}
            value={password}
            type="password"
            placeholder="Password..."
          />
        </Styled.Inner>
      </Styled.Outer>

      <Button styling="important" type="submit" className="button buttonBlue">
        Sign Up
      </Button>
    </form>
  );
};
