import React, { FC } from "react";
import { red, green } from "@ant-design/colors";

import { Device } from "../../device/Device.types";

export interface DeviceRegisteredTextProps {
  device?: Device;
  state?: boolean;
}

export const DeviceRegisteredText: FC<DeviceRegisteredTextProps> = (
  props: DeviceRegisteredTextProps
) => {
  if (props.state || props.device?.registered) {
    return <span style={{ color: green.primary }}>registered</span>;
  } else {
    return <span style={{ color: red.primary }}>not registered</span>;
  }
};
