import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { Modal, List, Select, Row, Col, Table, Space, Popconfirm } from 'antd';
import { ColumnsType } from 'antd/es/table/index';
import { QuestionCircleOutlined } from '@ant-design/icons';

import { Device, DeviceMonitoring, DeviceReference } from '../device/Device.types';

import Url from '../../lib/utils/Url';
import { DeviceOnlineIcon } from '../shared/Icons/DeviceOnlineIcon';
import Styled from './DeviceGroupDeviceList.styled';

import { DeviceGroup, DeviceGroupReference } from './DeviceGroup.types';
import { deleteMultipleDevices, transferMultipleDevices } from './DeviceGroup.apifunctions';

import { ProjectDetail } from '../project/Project.types';
import { fetchProjectList, fetchProjectDetail } from '../project/Project.apifunctions';
import { PromptModal, Button } from '@droidsolutions/ui-components';
import { getDisplayedDevices } from '../../lib/utils/DeviceGroupDeviceListUtils';
import { useTranslation } from 'react-i18next';
import { exportToCsv } from '../../lib/utils/CSVUtils';

export interface DeviceGroupDeviceListProps {
  group: DeviceGroup;
  devices: Device[];
  updateGroup: (groupSlug: string) => void;
  searchString: string;
}

/** Renders a link to a device detail page */
const deviceLink = (deviceId: string, text: string) => (
  <Link
    to={{
      pathname: Url.slug2url(deviceId, 'device/settings'),
      state: location.pathname,
    }}
  >
    {text}
  </Link>
);

/** Returns an url for the device group */
const getDeviceGroupUrl = (slug: string) => Url.api(`devicegroup/by-name/${slug}`);

/** Retrieves a list of devices from a single group. */
export const DeviceGroupDeviceList: React.FC<DeviceGroupDeviceListProps> = (props: DeviceGroupDeviceListProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [transferModalActive, setTransferModalActive] = useState<boolean>(false);

  // device array for multiple select operation for transfer and delete
  const [devicesMultipleAction, setDevicesMultipleAction] = useState<string[]>([]);

  // Variabled for device delete confirmation modal
  const [modalVisible, setModalVisible] = useState(false);
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
  const [modalContent, setModalContent] = useState('');

  const [selectedDevices, setSelectedDevices] = useState<Device[]>([]);

  const [selectedProject, setSelectedProject] = useState<string>('');
  const [possibleProjects, setPossibleProjects] = useState<ProjectDetail[]>([]);

  const [selectedGroup, setSelectedGroup] = useState<string>('');
  const [possibleGroups, setPossibleGroups] = useState<DeviceGroupReference[]>([]);

  useEffect(() => {
    setSelectedDevices(
      getDisplayedDevices(
        props.devices.map((d: Device) => {
          const dist = Date.now() - (d.lastContact || 0);
          d.online = Math.abs(Date.now() - (d.lastContact || 0)) < 3 * 1000 * 3600;
          return d;
        }),
        props.searchString.trim()
      )
    );
  }, [props.devices, props.searchString]);

  const exportDeviceList = () => {
    const rows = selectedDevices.map((device) => {
      return [
        device.hwId,
        device.deviceId,
        (device.monitoring || {}).mac_address,
        new Date(device.lastContact || '').toLocaleString(undefined, { dateStyle: 'medium', timeStyle: 'medium' }),
      ];
    });

    exportToCsv('device-list.csv', [['HW-ID', 'Device-ID', 'MAC', 'Last contact'], ...rows]);
  };

  const transferModalHandler = async () => {
    if (devicesMultipleAction.length > 0) {
      setSelectedGroup('');

      const allProjects = await fetchProjectList();
      setPossibleProjects(allProjects);
      setTransferModalActive(true);
    } else {
      setModalContent(t('please-select-atleast-one-device-to-transfer'));
      setModalVisible(true);
    }
  };

  const transferHandler = async (devices: string[]): Promise<void> => {
    if (devices) {
      const transferResponse = await transferMultipleDevices(devices, selectedGroup);

      if (transferResponse) {
        const targetGroup = transferResponse.transferredToGroup || '--';
        setModalContent(
          t('transferred-devicecount-devices-to-targetgroup', { devicecount: devices.length, targetgroup: targetGroup })
        );
        setModalVisible(true);
      }
    } else {
      setModalContent(t('no-device-selected-to-transfer'));
      setModalVisible(true);
      // message.error("No device selected");
    }

    setTransferModalActive(false);
  };

  const deleteButtonHandler = () => {
    if (devicesMultipleAction.length > 0) {
      setModalContent(t('do-you-want-to-delete-count-devices', { count: devicesMultipleAction.length }));
      setModalVisible(true);
      setDeleteConfirmModal(true);
      //setDeleteDeviceId(d.deviceId);
    } else {
      setModalContent(t('please-select-atleast-one-device-to-delete'));
      setModalVisible(true);
    }
  };

  const createDeleteHandler = (deviceIds: string[]) => {
    const failMessage = t('an-internal-server-error-occurred');
    deleteMultipleDevices(deviceIds).then((deleteResponse) => {
      deleteResponse?.deleted
        ? deleteNotification(t('successfully-deleted-count-device-s', { count: deviceIds.length }))
        : deleteNotification(failMessage);

      props.updateGroup(props.group.slug);
    });
  };

  const deleteSingleDevice = (deviceId: string) => {
    const failMessage = t('an-internal-server-error-occurred');
    return deleteMultipleDevices([deviceId]).then((deletedResponse) => {
      deletedResponse?.deleted
        ? deleteNotification(t('successfully-deleted-deviceid', { deviceId: deviceId }))
        : deleteNotification(failMessage);
    });
  };

  const deleteNotification = (modaltext: string) => {
    setModalContent(modaltext);
    setModalVisible(true);
    setDeleteConfirmModal(false);
  };

  const getLatestMonitoringEvent = (device: Device) => {
    if (!device.monitoring) return null;
    return device.monitoring;
  };

  const handleStats = (deviceId: string) => {
    history.push(`/statistics/device/${deviceId}`);
  };

  const columns: ColumnsType<Device> = [
    {
      title: t('hardware-id'),
      dataIndex: 'hwId',
      sorter: (a: Device, b: Device) => {
        if (a.hwId < b.hwId) return -1;
        if (a.hwId > b.hwId) return 1;
        return 0;
      },
      render: (text: string, device: Device) => {
        return deviceLink(device.deviceId, text);
      },
    },
    {
      title: t('device-id'),
      dataIndex: 'deviceId',
      sorter: (a: Device, b: Device) => {
        if (a.deviceId < b.deviceId) return -1;
        if (a.deviceId > b.deviceId) return 1;
        return 0;
      },
      render: (text: string, device: Device) => {
        return deviceLink(device.deviceId, text);
      },
    },
    {
      title: t('mac-address'),
      dataIndex: 'macAddress',
      render: (text: string, device: Device) => {
        return deviceLink(device.deviceId, (getLatestMonitoringEvent(device) || { mac_address: '' }).mac_address);
      },
    },
    {
      title: t('config-version'),
      dataIndex: 'configVersion',
      render: (text: string, device: Device) => {
        return (getLatestMonitoringEvent(device) || { config_version: 0 }).config_version;
      },
      filters: Object.values(
        selectedDevices.reduce((prev: Record<string, any>, d: Device) => {
          if (d.monitoring) {
            const m = getLatestMonitoringEvent(d) || { config_version: 0 };
            if (m.config_version && !prev[m.config_version])
              prev[m.config_version] = {
                text: m.config_version,
                value: m.config_version,
              };
          }

          return prev;
        }, {})
      ),
      onFilter: (value: any, record: Device) => getLatestMonitoringEvent(record)?.config_version == value,
      sorter: (a: Device, b: Device) =>
        (getLatestMonitoringEvent(a)?.config_version || 0) - (getLatestMonitoringEvent(b)?.config_version || 0),
    },
    {
      title: t('online'),
      dataIndex: 'online',
      filters: [
        {
          text: t('online'),
          value: true,
        },
        {
          text: t('offline'),
          value: false,
        },
      ],
      onFilter: (value: any, record: Device) => record.online == value,
      sorter: (a: Device, b: Device) => +!!a.online - +!!b.online,
      render: (text: string, device: Device) => {
        return (
          <div>
            <DeviceOnlineIcon device={device} /> {device.online ? t('online') : t('offline')}
          </div>
        );
      },
    },
    {
      title: t('last-contact'),
      dataIndex: 'lastContact',
      sorter: (a: Device, b: Device) => (a.lastContact || 0) - (b.lastContact || 0),
      render: (text: number) => {
        return new Date(+text).toLocaleString(undefined, { dateStyle: 'medium', timeStyle: 'medium' });
      },
    },
    {
      title: t('action'),
      dataIndex: 'deviceId',
      sorter: false,
      render: (deviceId: string) => (
        <Space size="middle">
          <Button icon="statistics" onClick={() => handleStats(deviceId)}>
            {t('statistics')}
          </Button>
          <Popconfirm
            title={t('are-you-sure-you-want-to-delete-this-device')}
            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
            onConfirm={() => deleteSingleDevice(deviceId)}
          >
            <Button icon="delete">{t('remove-device')}</Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Modal
        visible={transferModalActive}
        title={t('transfer-device-s')}
        onCancel={() => setTransferModalActive(false)}
        onOk={() => transferHandler(devicesMultipleAction)}
      >
        <Row>
          <Col span={4}>{t('project')}</Col>
          <Col span={16}>
            <Select
              value={selectedProject}
              style={{ width: 320 }}
              onChange={async (project: string) => {
                setSelectedProject(project);
                const filteredProjectList = possibleProjects.filter((p) => p.slug === project);

                const p = filteredProjectList[0];
                const projectDetail = await fetchProjectDetail(p.slug);

                if (projectDetail) {
                  setPossibleGroups(projectDetail.groups);

                  if (projectDetail.groups.length > 0) {
                    setSelectedGroup(projectDetail.groups[0].slug);
                  }
                }
              }}
            >
              {possibleProjects.map((project, i) => (
                <Select.Option key={`selected-project-${i}`} value={project.slug}>
                  {project.name}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col span={4} />
        </Row>
        <Row>
          <Col span={4}>{t('group')}</Col>
          <Col span={16}>
            <Select
              disabled={selectedGroup === ''}
              value={selectedGroup}
              style={{ width: 320 }}
              onChange={(group: string) => setSelectedGroup(group)}
            >
              {possibleGroups.map((group, i) => (
                <Select.Option key={`selected-group-${i}`} value={group.slug}>
                  {group.name}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col span={4} />
        </Row>
      </Modal>

      {/* TOP LEVEL Buttons */}
      <Styled.Box>
        <Styled.Button icon="share" onClick={() => exportDeviceList()}>
          {t('export-device-list')}
        </Styled.Button>
        <Styled.Button icon="arrowNext" onClick={() => transferModalHandler()}>
          {t('transfer')}
        </Styled.Button>
        <Styled.Button icon="delete" onClick={() => deleteButtonHandler()}>
          {t('remove')}
        </Styled.Button>
      </Styled.Box>

      <Table
        pagination={{ position: ['bottomCenter'] }}
        rowSelection={{
          type: 'checkbox',
          onChange: (selectedRowKeys: React.Key[], selectedRows: Device[]) => {
            setDevicesMultipleAction(selectedRowKeys as string[]);
          },
          getCheckboxProps: (record: Device) => ({
            disabled: false, //record.name === 'Disabled User', // Column configuration not to be checked
            name: record.deviceId,
          }),
        }}
        columns={columns}
        rowKey="deviceId"
        dataSource={selectedDevices}
      />
      <PromptModal
        onClosed={() => !deleteConfirmModal && history.go(0)}
        cancelButtonText={deleteConfirmModal ? t('cancel') : undefined}
        content={modalContent}
        onCancel={() => setModalVisible(false)}
        onSubmit={deleteConfirmModal ? () => createDeleteHandler(devicesMultipleAction) : () => setModalVisible(false)}
        open={modalVisible}
        submitButtonText={deleteConfirmModal ? t('submit') : t('close')}
      />
    </>
  );
};
