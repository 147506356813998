import { message } from "antd";
import { Maybe } from "../../../types/Monads";

/**
 * @returns a parsed numeric value from a possibly undefined or string-like field value
 *
 * @param value the field value
 * @param errmsg the error message that should be shown by antd
 */
export const parseNumberField = (
  value: Maybe<number | string>,
  defaultValue: number,
  errmsg: string
): number => {
  let parsed: number;

  switch (typeof value) {
    case "number":
      return value;

    case "string":
      parsed = +value;
      if (isNaN(parsed)) {
        message.error(errmsg);
        return defaultValue;
      }

      return parsed;

    default: {
      message.error(errmsg);
      return defaultValue;
    }
  }
};
