/* eslint-disable no-console */
// import { Table } from "antd";
import React, { FC } from "react";
import { Table, Typography } from "antd";

import {
  GeoPoint,
  MonitoringInformation,
  MonitoringInstalledMediaApps,
  StatisticsResponse,
} from "../Statistics.types";
import { Unit, Maybe } from "../../../types/Monads";
import { toDateString } from "./../time";
import { Colspec, DataTableProps } from "./DataTable.types";

type MonitorData = {
  key: number;
  date: number;
  build: string;
  battery_level: number;
  config_version: number;
  mac_address: string;
  os_version: string;
  position: string;
  installed_media_apps: string;
};

// rounds n to d decimals
const round = (n: number, d: number) =>
  Math.round(n * Math.pow(10, d)) / Math.pow(10, d);

// rounding precision of geographic reference points
const geoPrecision = 6;

// string representation of a GeoPoint
const geoPoint = (point?: GeoPoint) =>
  point
    ? `${round(point.lat, geoPrecision)}, ${round(point.lon, geoPrecision)}`
    : "--";

// string representation of the list of installed media apps
const installedMediaApps = (appList: MonitoringInstalledMediaApps[]) =>
  appList.map((i) => `${i.package_name} (${i.version_code})`).join("; ");

// unwrapped statistics response data for the data table
const unwrapStatisticsObject = (
  data: Maybe<StatisticsResponse>
): Array<MonitorData> => {
  const stats: Array<Unit<MonitoringInformation>> = data
    ? Object.entries(data.stats.stats)
    : [];

  return stats.map(([_, data], index) => {
    return {
      key: index,
      date: data.date,
      build: data.build,
      battery_level: data.battery_level,
      config_version: data.config_version,
      mac_address: data.mac_address,
      os_version: data.os_version,
      position: geoPoint(data.position),
      installed_media_apps: installedMediaApps(data.installed_media_apps),
    };
  });
};

// column specification for the data table
const dataColumns = (): Colspec<MonitorData> => {
  return [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      sorter: (a: MonitorData, b: MonitorData) => a.date - b.date,
      render: (date: number) => toDateString(date),
    },
    {
      title: "Build",
      dataIndex: "build",
      key: "build",
      sorter: (a: MonitorData, b: MonitorData) => +a.build - +b.build,
    },
    {
      title: "Battery Level",
      dataIndex: "battery_level",
      key: "battery_level",
      sorter: (a: MonitorData, b: MonitorData) =>
        a.battery_level - b.battery_level,
    },
    {
      title: "Config Version",
      dataIndex: "config_version",
      key: "config_version",
      sorter: (a: MonitorData, b: MonitorData) =>
        a.config_version - b.config_version,
    },
    {
      title: "MAC Address",
      dataIndex: "mac_address",
      key: "mac_address",
    },
    {
      title: "OS Version",
      dataIndex: "os_version",
      key: "os_version",
    },
    {
      title: "Position",
      dataIndex: "position",
      key: "position",
    },
    {
      title: "Media Apps",
      dataIndex: "installed_media_apps",
      key: "installed_media_apps",
    },
  ];
};

export const DataTable: FC<DataTableProps> = (props: DataTableProps) => {
  console.log("DataTable::props", props);

  const data: MonitorData[] = unwrapStatisticsObject(props.data);
  const columns: Colspec<MonitorData> = dataColumns();

  console.log("DataTable::data", data);
  console.log("DataTable::columns", columns);

  return (
    <>
      <Typography.Title level={4}>Data Table</Typography.Title>
      <Table dataSource={data} columns={columns} />
    </>
  );
};
