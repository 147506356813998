import React, { FC } from "react";
import { CartesianGrid, XAxis, Legend, YAxis, Bar, BarChart } from "recharts";

import { InstalledAppsCollection } from "./InstalledAppsChart.types";
import {
  MonitoringInformation,
  StatisticsData,
  StatisticsResponse,
} from "../Statistics.types";
import { toTimeString } from "../time";

const numInstalledAppsData = (
  data: StatisticsData
): InstalledAppsCollection => {
  const baseData = Object.values(data.stats);
  const sorted = baseData.sort((a, b) => a.date - b.date);
  return {
    collection: sorted.map((entry: MonitoringInformation) => ({
      date: toTimeString(entry.date),
      numApps: entry.installed_media_apps
        ? entry.installed_media_apps.length
        : 0,
    })),
  };
};

/** Statistics item: statistics collection */
export interface InstalledAppsChartProps {
  data: StatisticsResponse;
}

export const InstalledAppsChart: FC<InstalledAppsChartProps> = (
  props: InstalledAppsChartProps
) => {
  const appsData = numInstalledAppsData(props.data.stats);

  return (
    <>
      <BarChart
        width={900}
        height={300}
        data={appsData.collection}
        margin={{ top: 25, right: 55, left: 25, bottom: 35 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="date"
          interval="preserveStartEnd"
          angle={30}
          dx={20}
          dy={20}
        />
        <YAxis interval="preserveStartEnd" minTickGap={1} />
        <Legend verticalAlign="top" />
        <Bar
          name="Number of installed Apps"
          dataKey="numApps"
          stroke="#111111"
          fill="#1890ff"
        />
      </BarChart>
    </>
  );
};
