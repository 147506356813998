/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */

import { Icon, PromptModal } from '@droidsolutions/ui-components';
import { List } from 'antd';
import React, { FC, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getAuthHeaders } from '../../lib/auth/headers';
import DateUtils from '../../lib/utils/DateUtils';
import Url from '../../lib/utils/Url';
import { ProjectReference } from './Project.types';
import Styled from './ProjectList.styled';
import { Maybe } from '../../types/Monads';
import { deleteMultipleProjects } from './Project.apifunctions';
import { useTranslation } from 'react-i18next';

export interface ProjectsOverviewProps {
  projects: ProjectReference[];
}

/** @returns a new event handler that invokes the project creation dialogue */
const createEditHandler = (history: any, p: ProjectReference) => () => history.push(`/project/${p.slug}/edit`);

export const ProjectList: FC<ProjectsOverviewProps> = (props: ProjectsOverviewProps) => {
  const { t } = useTranslation();

  const history = useHistory();
  const projects = props.projects;
  const [modalVisible, setModalVisible] = useState(false);
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
  const [modalContent, setModalContent] = useState('');

  // Pagination
  const [maxTiles, _setMaxTiles] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPages, setMaxPages] = useState(1);

  // device array for multiple select operation for transfer and delete
  const [projectsMultipleAction, setProjectsMultipleAction] = useState<string[]>([]);
  const [selectAllCheckBox, setSelectAllCheckbox] = useState<boolean>(false);

  useEffect(() => {
    setMaxPages(Math.ceil(projects.length / maxTiles));
  }, [projects, maxTiles]);

  const displayedProjects = projects.slice(maxTiles * (currentPage - 1), currentPage * maxTiles);

  // const [pRef, setPref] = useState<Maybe<ProjectReference>>(
  //   props.projects.length > 0 ? props.projects[0] : null
  // );

  const deleteButtonHandler = () => {
    if (projectsMultipleAction.length > 0) {
      setModalContent(`Do you want to delete ${projectsMultipleAction.length} project(s) ?`);
      setModalVisible(true);
      setDeleteConfirmModal(true);
      //setPref(p);
    } else {
      setModalContent(`Please select atleast one project to delete !`);
      setModalVisible(true);
    }
  };

  const deleteNotification = (modaltext: string, p?: ProjectReference) => {
    setModalContent(modaltext);
    setModalVisible(true);
    setDeleteConfirmModal(false);
  };

  const handleSelectAllCheckBoxChange = () => {
    const addProjects: string[] = [];

    if (!selectAllCheckBox) {
      setSelectAllCheckbox(true);
      projects.forEach((p) => addProjects.push(p.slug));
      setProjectsMultipleAction(addProjects);
    } else {
      setSelectAllCheckbox(false);
      setProjectsMultipleAction([]);
    }
  };

  const handleCheckBoxChange = (p: ProjectReference) => {
    if (projectsMultipleAction.includes(p.slug)) {
      setProjectsMultipleAction(projectsMultipleAction.filter((project) => project !== p.slug));
    } else {
      setProjectsMultipleAction([...projectsMultipleAction, p.slug]);
    }
  };

  const createDeleteHandler = () => {
    const failMessage = t('an-internal-server-error-occurred');

    deleteMultipleProjects(projectsMultipleAction).then((deleteResponse) => {
      if (deleteResponse.status >= 200 && deleteResponse.status < 300) {
        deleteNotification(`${t('successfully-deleted-the-project-s')} ${projectsMultipleAction.length}`);
      } else if (deleteResponse.status == 404) {
        deleteNotification(deleteResponse.message);
      } else {
        deleteNotification(failMessage || deleteResponse.message);
      }
    });
  };

  const projectDescription = (p: ProjectReference) =>
    p.updatedAt && p.updatedBy
      ? `${t('last-updated-at')}: ${DateUtils.ts2datestring(p.updatedAt)}, ${t('by')}: ${p.updatedBy}`
      : '';

  return (
    <>
      <Styled.DeleteBox>
        <Styled.Button icon="delete" onClick={() => deleteButtonHandler()}>
          Delete
        </Styled.Button>
        <Styled.SelectAllCheckbox
          label={t('select-all')}
          checked={selectAllCheckBox}
          onChange={() => handleSelectAllCheckBoxChange()}
        />
      </Styled.DeleteBox>
      <List>
        {displayedProjects.map((p) => (
          <Styled.ListItem key={p.slug}>
            <Styled.Meta
              avatar={<Icon icon="folderSmall" color="black" />}
              title={Url.slug2link(p, 'project')}
              description={projectDescription(p)}
            />
            <Styled.Button icon="edit" onClick={createEditHandler(history, p)}>
              {t('edit')}
            </Styled.Button>

            <Styled.Checkbox
              label={''}
              checked={projectsMultipleAction.includes(p.slug)}
              onChange={() => {
                handleCheckBoxChange(p);
              }}
            />
          </Styled.ListItem>
        ))}
        <PromptModal
          onClosed={
            !deleteConfirmModal
              ? () => {
                  history.push('/');
                  history.go(0);
                }
              : () => {
                  /** */
                }
          }
          cancelButtonText={deleteConfirmModal ? t('cancel') : undefined}
          content={modalContent}
          onCancel={() => setModalVisible(false)}
          onSubmit={deleteConfirmModal ? () => createDeleteHandler() : () => setModalVisible(false)}
          open={modalVisible}
          submitButtonText={deleteConfirmModal ? t('submit') : t('close')}
        />
        {
          /* if this is the last page, fill up the rest of the slots 
      with empty elements so the row is aligned on the left */
          maxTiles - displayedProjects.length > 0 &&
            projects.length > 0 &&
            Array.from({ length: maxTiles - displayedProjects.length }, () => Math.random()).map((i) => (
              <Styled.Spacer key={i} />
            ))
        }
        {projects.length > 0 && (
          <Styled.Pagination currentPage={currentPage} total={maxPages} onChange={(page) => setCurrentPage(page)} />
        )}
      </List>
    </>
  );
};
