import React from 'react';
import Logo from '../../../assets/logo_2.svg';
import Styled from './DashboardHeader.styled';
import { NavigationBar } from './NavigationBar';

const navItems = [
  {
    path: '/',
    label: 'Dashboard',
  },
  {
    path: '/projects',
    label: 'Projects',
  },
];
export const DashboardHeader: React.FC = () => {
  return (
    <Styled.DashboardHeader>
      <Styled.Logo>
        <Logo />
        <span>KULDIG MDM</span>
      </Styled.Logo>
      <Styled.Content>
        <NavigationBar items={navItems} />
      </Styled.Content>
    </Styled.DashboardHeader>
  );
};
