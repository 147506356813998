import React, { FC } from "react";
import { Button } from "@droidsolutions/ui-components";

export interface EditButtonProps {
  index: number;
  onClick: (i: number) => void;
}

export const EditButton: FC<EditButtonProps> = (props: EditButtonProps) => {
  return <Button icon="edit" onClick={() => props.onClick(props.index)} />;
};
