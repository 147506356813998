import { styled } from '../../style/theme';
import { List } from 'antd';
import { Box } from '@droidsolutions/ui-components';

export default {
  Box: styled(Box)`
    margin-top: 32px;
    padding: 24px;
    border: none;
  `,

  Meta: styled(List.Item.Meta)`
    display: flex;
    flex-direction: row;
  `,
};
