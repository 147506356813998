/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react-hooks/rules-of-hooks */

import React, { useState, FC } from 'react';
import { Row, Col, Divider, Typography, Tooltip } from 'antd';
import { InfoCircleOutlined, InfoCircleTwoTone } from '@ant-design/icons';
import { Checkbox, NumberPicker, Slider } from '@droidsolutions/ui-components';

import { SubPanelProps } from './SubPanelProps';
import Styled from '../Settings.styled';
import { useTranslation } from 'react-i18next';

export const AudioPanel: FC<SubPanelProps> = (props: SubPanelProps) => {
  const { t } = useTranslation();
  /**
   * States and Updaters.
   */
  const [volume, setvolume] = useState<number>(props.settings.volume);
  const [volume_static, setvolume_static] = useState<boolean>(props.settings.volume_static);

  const [equalizerEnabled, setEqualizerEnabled] = useState<boolean>(
    props.settings.equalizer_configuration?.enabled ?? false
  );

  const [equalizerBands, setEqualizerBands] = useState<number[]>(
    props.settings.equalizer_configuration?.bands ?? [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
  );

  /**
   * Rendering.
   */
  return (
    <>
      <Divider orientation="left">
        <Typography.Text strong>{t('audio')}</Typography.Text>
      </Divider>

      <Styled.Row>
        <Col span={4}>
          <Tooltip title={t('audio-volume-override-description')}>
            <Typography.Text>{t('audio-volume-override')}</Typography.Text> <InfoCircleTwoTone />
          </Tooltip>
        </Col>
        <Col span={20}>
          <Checkbox
            label=""
            checked={volume_static}
            onChange={(value: boolean) => {
              setvolume_static(value);
              props.settings.volume_static = value;
              props.updateSettings(props.settings);
            }}
          />
        </Col>
      </Styled.Row>

      <Styled.Row>
        <Col span={4}>
          <Tooltip title={t('audio-volume-description')}>
            <Typography.Text>{t('audio-volume')}</Typography.Text> <InfoCircleTwoTone />
          </Tooltip>
        </Col>
        <Col span={20}>
          <Row>
            <Col span={10}>
              <Slider
                value={volume}
                min={0}
                max={15}
                step={1}
                onChange={(percentValue: number) => {
                  setvolume(percentValue);
                  props.settings.volume = percentValue;
                  props.updateSettings(props.settings);
                }}
              />
            </Col>
            <Col span={2}>{props.settings.volume}</Col>
          </Row>
        </Col>
      </Styled.Row>

      <Styled.Row>
        <Col span={4}>
          <Tooltip title={t('audio-equalizer-enabled-description')}>
            <Typography.Text>{t('audio-equalizer-enabled')}</Typography.Text> <InfoCircleTwoTone />
          </Tooltip>
        </Col>
        <Col span={20}>
          <Checkbox
            label=""
            checked={equalizerEnabled}
            onChange={(value: boolean) => {
              setEqualizerEnabled(value);
              if (props.settings.equalizer_configuration == null) {
                props.settings.equalizer_configuration = {
                  enabled: value,
                  bands: equalizerBands,
                };
              }
              props.settings.equalizer_configuration.enabled = value;
              props.updateSettings(props.settings);
            }}
          />
        </Col>
      </Styled.Row>
      <Styled.Row>
        <Col span={4}>
          <Tooltip title={t('audio-equalizer-bands-description')}>
            <Typography.Text>{t('audio-equalizer-bands')}</Typography.Text> <InfoCircleTwoTone />
          </Tooltip>
        </Col>
        <Col span={20}>
          {equalizerBands.map((value: number, index: number) => {
            return (
              <Row key={index}>
                <Col span={2}>
                  <Typography.Text>Band {index + 1}</Typography.Text>
                </Col>
                <Col span={10}>
                  <input
                    type="number"
                    min={-1500}
                    max={1500}
                    step={1}
                    value={value}
                    onChange={(event) => {
                      const newValue = parseInt(event.target.value);
                      setEqualizerBands([
                        ...equalizerBands.slice(0, index),
                        newValue,
                        ...equalizerBands.slice(index + 1),
                      ]);
                      if (props.settings.equalizer_configuration == null) {
                        props.settings.equalizer_configuration = {
                          enabled: equalizerEnabled,
                          bands: [...equalizerBands.slice(0, index), newValue, ...equalizerBands.slice(index + 1)],
                        };
                      }
                      props.settings.equalizer_configuration.bands[index] = newValue;
                      console.log(props.settings.equalizer_configuration.bands);
                      props.updateSettings(props.settings);
                    }}
                  ></input>{' '}
                  mdB <small>(Range: -1500 to 1500 for XCover5)</small>
                </Col>
              </Row>
            );
          })}

          {/* <Row>
            <Col span={10}>
              <Slider
                value={volume}
                min={0}
                max={15}
                step={1}
                onChange={(percentValue: number) => {
                  setvolume(percentValue);
                  props.settings.volume = percentValue;
                  props.updateSettings(props.settings);
                }}
              />
            </Col>
            <Col span={2}>{props.settings.volume}</Col>
          </Row> */}
        </Col>
      </Styled.Row>
    </>
  );
};
