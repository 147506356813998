/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react-hooks/rules-of-hooks */

import React, { useState, FC } from 'react';
import { Row, Col, Divider, Typography, InputNumber, Tooltip } from 'antd';
import { InfoCircleOutlined, InfoCircleTwoTone } from '@ant-design/icons';
import { Checkbox, Slider } from '@droidsolutions/ui-components';

import { SubPanelProps } from './SubPanelProps';
import { parseNumberField } from './configpanels.functions';
import { Maybe } from '../../../types/Monads';
import { useTranslation } from 'react-i18next';
import Styled from '../Settings.styled';

const displayTimeoutErrorMessage = 'Display Timeout value has the incorrect format! Value has been set to 1000ms.';

export const DisplayPanel: FC<SubPanelProps> = (props: SubPanelProps) => {
  const { t } = useTranslation();

  /**
   * States and Updaters.
   */
  const [display_brightness, setdisplay_brightness] = useState<number>(props.settings.display_brightness);
  const [display_timeout, setdisplay_timeout] = useState<number>(props.settings.display_timeout);
  const [display_brightness_static, setdisplay_brightness_static] = useState<boolean>(
    props.settings.display_brightness_static
  );
  const [display_landscape_mode, setdisplay_landscape_mode] = useState<boolean>(props.settings.display_landscape_mode);
  const [display_orientation_lock, setdisplay_orientation_lock] = useState<boolean>(
    props.settings.display_orientation_lock
  );

  /**
   * Rendering.
   */
  return (
    <>
      <Divider orientation="left">
        <Typography.Text strong>{t('display')}</Typography.Text>
      </Divider>

      <Styled.Row>
        <Col span={4}>
          <Tooltip title={t('brightness-override-description')}>
            <Typography.Text>{t('brightness-override')}</Typography.Text> <InfoCircleTwoTone />
          </Tooltip>
        </Col>
        <Col span={20}>
          <Checkbox
            label=""
            checked={display_brightness_static}
            onChange={(value: boolean) => {
              setdisplay_brightness_static(value);
              props.settings.display_brightness_static = value;
              props.updateSettings(props.settings);
            }}
          />
        </Col>
      </Styled.Row>

      <Styled.Row>
        <Col span={4}>
          <Tooltip title={t('landscape-mode-description')}>
            <Typography.Text>{t('landscape-mode')}</Typography.Text> <InfoCircleTwoTone />
          </Tooltip>
        </Col>
        <Col span={20}>
          <Checkbox
            label=""
            checked={display_landscape_mode}
            onChange={(value: boolean) => {
              setdisplay_landscape_mode(value);
              props.settings.display_landscape_mode = value;
              props.updateSettings(props.settings);
            }}
          />
        </Col>
      </Styled.Row>

      <Styled.Row>
        <Col span={4}>
          <Tooltip title={t('orientation-lock-description')}>
            <Typography.Text>{t('orientation-lock')}</Typography.Text> <InfoCircleTwoTone />
          </Tooltip>
        </Col>
        <Col span={20}>
          <Checkbox
            label=""
            checked={display_orientation_lock}
            onChange={(value: boolean) => {
              setdisplay_orientation_lock(value);
              props.settings.display_orientation_lock = value;
              props.updateSettings(props.settings);
            }}
          />
        </Col>
      </Styled.Row>

      <Styled.Row>
        <Col span={4}>
          <Tooltip title={t('display-brightness-description')}>
            <Typography.Text>{t('display-brightness')}</Typography.Text> <InfoCircleTwoTone />
          </Tooltip>
        </Col>
        <Col span={20}>
          <Row>
            <Col span="10">
              <Slider
                value={display_brightness}
                min={0}
                max={255}
                step={1}
                onChange={(percentValue: number) => {
                  setdisplay_brightness(percentValue);
                  props.settings.display_brightness = percentValue;
                  props.updateSettings(props.settings);
                }}
              />
            </Col>
            <Col span="2">
              <span>{Math.round((props.settings.display_brightness / 255) * 100)} %</span>
            </Col>
          </Row>
        </Col>
      </Styled.Row>

      <Styled.Row>
        <Col span={4}>
          <Tooltip title={t('display-timeout-description')}>
            <Typography.Text>{t('display-timeout')}</Typography.Text> <InfoCircleTwoTone />
          </Tooltip>
        </Col>
        <Col span={20}>
          <InputNumber
            min={10 * 1000}
            max={60 * 60 * 1000} // 1h
            value={display_timeout}
            placeholder={t('display-timeout')}
            formatter={(value: Maybe<string | number>) => (value ? `${value} ms` : '10 ms')}
            parser={(value) => (value ? value.replace(' ms', '') : 1000)}
            onChange={(value: Maybe<number | string>) => {
              const actualValue: number = parseNumberField(value, 1000, displayTimeoutErrorMessage);

              setdisplay_timeout(actualValue);
              props.settings.display_timeout = actualValue;
              props.updateSettings(props.settings);
            }}
          />
        </Col>
      </Styled.Row>
    </>
  );
};
