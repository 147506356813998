import React, { FC } from 'react';
import { Typography } from 'antd';

import { MediaAppConfig } from './DeviceSettings.types';
import { MediaList } from './mediapanel/MediaList';
import { useTranslation } from 'react-i18next';

export interface MediaAppConfigPanel {
  settings: MediaAppConfig[];
  updateSettings: (updated: MediaAppConfig[]) => void;
}

export const MediaAppConfigPanel: FC<MediaAppConfigPanel> = (props: MediaAppConfigPanel) => {
  const { t } = useTranslation();
  return (
    <>
      <Typography.Title level={3}>{t('app-media-settings')}</Typography.Title>
      <hr />
      <MediaList
        mediaList={props.settings}
        updateMediaList={(updated: MediaAppConfig[]) => props.updateSettings(updated)}
      />
    </>
  );
};
