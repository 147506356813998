/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react-hooks/rules-of-hooks */

import React, { useState } from "react";
import { Row, Col, Modal, Typography, Select } from "antd";

import { AppPermissionConfig } from "../DeviceSettings.types";
import { AndroidPermission } from "../../../lib/permissions/permissions.type";
import {
  permissionsList,
  permission2label,
} from "../../../lib/permissions/permissions";
import { ExtensibleStringList } from "../../shared/ExtensibleStringList";
import { Button } from "@droidsolutions/ui-components";

export interface PermissionsListProps {
  permissions: AppPermissionConfig[];
  updatePermissions: (updated: AppPermissionConfig[]) => void;
}

const defaultPermission: AndroidPermission = "android.permission.READ_CALENDAR";
const defaultPermissionConfig: AppPermissionConfig = {
  permission_name: defaultPermission,
  permitted_apps: [],
};

export const PermissionsList = (props: PermissionsListProps) => {
  /**
   * States and Updaters
   */
  const [add_mp_modal_visible, set_mp_modal_visible] = useState<boolean>(false);
  const [
    new_permissions_entry,
    set_new_permissions_entry,
  ] = useState<AppPermissionConfig>(defaultPermissionConfig);

  const [
    selectedPermission,
    setSelectedPermission,
  ] = useState<AndroidPermission>(permissionsList[0]);

  const [selectedPermAppList, setSelectedPermAppList] = useState<string[]>([]);

  const resetNewEntry = () => {
    setSelectedPermission(defaultPermission);
    setSelectedPermAppList([]);
  };

  /** Adds a new entry to the List View */
  const addNewEntryToListHandler = (newEntry: AppPermissionConfig): void => {
    const updated = props.permissions;
    updated.push(newEntry);
    props.updatePermissions(updated);

    resetNewEntry();
  };

  /** Removes an item from the permissions list */
  const removeListItem = (index: number) => {
    const updatedPermissionsList = props.permissions;

    if (index > -1 && !isNaN(index)) {
      updatedPermissionsList.splice(index, 1);
      setTimeout(() => {
        props.updatePermissions(updatedPermissionsList);
      }, 10);
    }
  };

  /**
   * Rendering
   */
  return (
    <>
      <Modal
        visible={add_mp_modal_visible}
        title="Add App Permission"
        onOk={() => {
          const newEntry: AppPermissionConfig = {
            permission_name: selectedPermission,
            permitted_apps: selectedPermAppList,
          };

          set_mp_modal_visible(false);
          addNewEntryToListHandler(newEntry);
        }}
        onCancel={() => {
          set_mp_modal_visible(false);
          resetNewEntry();
        }}
        okText="Add"
        cancelText="Cancel"
      >
        <Row>
          <Col span={6}>
            <Typography.Text>Permission</Typography.Text>
          </Col>
          <Col span={18}>
            <Select
              value={selectedPermission}
              style={{ width: 320 }}
              onChange={(permission: AndroidPermission) =>
                setSelectedPermission(permission)
              }
            >
              {permissionsList.map((perm, i) => (
                <Select.Option key={`selected-group-${i}`} value={perm}>
                  {permission2label(perm)}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <Typography.Text>App List</Typography.Text>
          </Col>
          <Col span={18}>
            <ExtensibleStringList
              header="Apps valid for the permission"
              list={selectedPermAppList}
              onAppend={(updated: string[]) => setSelectedPermAppList(updated)}
            />
          </Col>
        </Row>
      </Modal>

      {props.permissions.map((perm: AppPermissionConfig, i: number) => (
        <div key={`permission-${i}`}>
          <Row>
            <Col span={6}>
              <Typography.Text>
                {permission2label(perm.permission_name)}
              </Typography.Text>
            </Col>
            <Col span={16}>
              <ExtensibleStringList
                header="Apps valid for the permission"
                list={perm.permitted_apps}
                onAppend={(updated: string[]) =>
                  setSelectedPermAppList(updated)
                }
              />
            </Col>
            <Col span={2}>
              {" "}
              <Button
                icon="delete"
                styling="blue"
                onClick={() => removeListItem(i)}
              >
                Remove
              </Button>
            </Col>
          </Row>
        </div>
      ))}
      <Row>
        <Col span={21} />
        <Col span={3}>
          <Button
            icon="addSmall"
            styling="blue"
            onClick={() => set_mp_modal_visible(true)}
          >
            Add Permission
          </Button>
        </Col>
      </Row>
    </>
  );
};
