/* eslint-disable @typescript-eslint/no-unsafe-return */
import {
  DeleteDeviceResponse,
  TransferDeviceResponse,
  DeviceGroupResponse,
  DeviceGroupCreateRequest,
  MultipleGroups,
  GroupDeleteResponse,
  DevicesResponse,
} from './DeviceGroup.types';
import { Maybe } from '../../types/Monads';
import Url from '../../lib/utils/Url';

import { message } from 'antd';

import { getAuthHeaders } from '../../lib/auth/headers';
import { checkResponseStatus } from '../../lib/api-errorhandler';
import { MultipleDevices } from '../device/Device.types';

const nameConflictMessage =
  'Name Conflict: The name you choose is too similar to an already existing one, please rename your group.';

const noAuthorizationMessage = 'You are not authorized to perform this operation';

const isError = 'Internal Server Error';

const notFoundError = 'Group not found!';

export const addNewDeviceGroup = async (group: DeviceGroupCreateRequest): Promise<Maybe<DeviceGroupResponse>> => {
  const createGroupUrl = Url.api('devicegroup/new');

  const newGroupResponse = await fetch(createGroupUrl, {
    method: 'PUT',
    mode: 'cors',
    cache: 'no-cache',
    headers: getAuthHeaders(),
    body: JSON.stringify(group),
  });

  if (newGroupResponse.status === 201) {
    return newGroupResponse.json();
  } else {
    message.error(isError);
  }
};

/**
 * Creates a new group, on the server.
 */
export const addGroup = async (group: DeviceGroupCreateRequest): Promise<Maybe<DeviceGroupResponse>> => {
  const addGroupUrl = Url.api('devicegroup/new');
  const data = await fetch(addGroupUrl, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: getAuthHeaders(),
    body: JSON.stringify(group),
  });

  if (data.status === 409) {
    message.error(nameConflictMessage);
  } else if (data.status === 401) {
    message.error(noAuthorizationMessage);
  } else if (data.status === 500) {
    message.error(isError);
  } else {
    return data.json();
  }
};

/**
 * Removes a group from the server.
 */
export const removeGroup = async (groupSlug: string): Promise<Response> => {
  const removeGroupUrl = Url.api(`devicegroup/delete/${groupSlug}`);
  return fetch(removeGroupUrl, {
    method: 'DELETE',
    mode: 'cors',
    cache: 'no-cache',
    headers: getAuthHeaders(),
  });

  // if (data.status === 409) {
  //   message.error(nameConflictMessage);
  // } else if (data.status === 401) {
  //   message.error(noAuthorizationMessage);
  // } else if (data.status === 500) {
  //   message.error(isError);
  // } else {
  //   return data.json();
  // }
};

/**
 * Fetches a group, identified by its slug
 */
export const fetchGroup = async (slug: string): Promise<Maybe<DeviceGroupResponse>> => {
  const projectDetailUrl = Url.api(`devicegroup/by-name/${slug}`);
  const data = await fetch(projectDetailUrl, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    headers: getAuthHeaders(),
  });

  if (data.status === 200) {
    return data.json();
  } else if (data.status === 404) {
    message.error(notFoundError);
  } else {
    message.error(isError);
  }
};

/**
 * Fetches a group, identified by its slug
 */
export const fetchDevices = async (slug: string, start: number = 0, limit: number = 50): Promise<DevicesResponse> => {
  const projectDetailUrl = Url.api(`devicegroup/by-name/${slug}/devices?start=${start}&limit=${limit}`);
  const data = await fetch(projectDetailUrl, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    headers: getAuthHeaders(),
  });

  if (data.status === 200) {
    return data.json();
  } else if (data.status === 404) {
    message.error(notFoundError);
    throw Error(notFoundError);
  } else {
    message.error(isError);
    throw Error(isError);
  }
};

/**
 * Deletes a device, referenced by its device id on the server.
 */
export const deleteDevice = async (deviceId: string): Promise<Maybe<DeleteDeviceResponse>> => {
  const deviceDeleteUrl = Url.api(`device/remove/${deviceId}`);
  const response = await fetch(deviceDeleteUrl, {
    method: 'DELETE',
    mode: 'cors',
    cache: 'no-cache',
    headers: getAuthHeaders(),
  });

  // eslint-disable-next-line no-console
  console.log(response);

  const responseOK = checkResponseStatus('device', deviceId, response);

  if (responseOK) {
    return response.json();
  }
};

/**
 * Transfers a device (referenced by its device id) to another group (referenced by targetGroupSlug) on the server.
 */
export const transferDevice = async (
  deviceId: string,
  targetGroupSlug: string
): Promise<Maybe<TransferDeviceResponse>> => {
  const deviceSettingsUrl = Url.api(`device/transfer/${deviceId}/${targetGroupSlug}`);
  const response = await fetch(deviceSettingsUrl, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: getAuthHeaders(),
  });

  const responseOK = checkResponseStatus('device', deviceId, response);

  if (responseOK) {
    return response.json();
  }
};

/**
 * Transfers a multiple devices (referenced by its device id) to another group (referenced by targetGroupSlug) on the server.
 */
export const transferMultipleDevices = async (
  devices: string[],
  targetGroupSlug: string
): Promise<Maybe<TransferDeviceResponse>> => {
  const deviceSettingsUrl = Url.api(`device/transfer-multiple/${targetGroupSlug}`);

  const requestBody: MultipleDevices = {
    deviceIds: devices,
  };

  requestBody.deviceIds = devices;

  const response = await fetch(deviceSettingsUrl, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: getAuthHeaders(),
    body: JSON.stringify(requestBody),
  });

  const responseOK = checkResponseStatus('device', 'multiple', response);

  if (responseOK) {
    return response.json();
  }
};

/**
 * Deletes multiple devices in a device group
 */
export const deleteMultipleDevices = async (devices: string[]): Promise<Maybe<DeleteDeviceResponse>> => {
  const deviceSettingsUrl = Url.api(`device/remove-multiple`);

  const requestBody: MultipleDevices = {
    deviceIds: devices,
  };

  requestBody.deviceIds = devices;

  const response = await fetch(deviceSettingsUrl, {
    method: 'DELETE',
    mode: 'cors',
    cache: 'no-cache',
    headers: getAuthHeaders(),
    body: JSON.stringify(requestBody),
  });

  const responseOK = checkResponseStatus('device', 'multiple', response);

  if (responseOK) {
    return response.json();
  }
};

/**
 *  Deletes a list of device groups
 */
export const deleteMultipleGroups = async (groups: string[]): Promise<GroupDeleteResponse> => {
  const projectDeletetUrl = Url.api('devicegroup/delete-multiple');

  const requestBody: MultipleGroups = {
    groupIds: groups,
  };

  const data = await fetch(projectDeletetUrl, {
    method: 'DELETE',
    mode: 'cors',
    cache: 'no-cache',
    headers: getAuthHeaders(),
    body: JSON.stringify(requestBody),
  });

  return data.json();
};
