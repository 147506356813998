import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Descriptions, Typography } from 'antd';
import { EditorField, LoadingSpinner, PromptModal } from '@droidsolutions/ui-components';

import DateUtils from '../../lib/utils/DateUtils';
import Styled from './ProjectEditPage.styled';

import { PageLayout } from '../shared/layout/PageLayout';
import { ProjectDetail } from './Project.types';
import { fetchProjectDetail, postProject } from './Project.apifunctions';
import { DeviceGroupListEditable } from '../devicegroup/DeviceGroupListEditable';
import { useTranslation } from 'react-i18next';

export interface ProjectsEditProps {
  project: ProjectDetail;
}

export const ProjectEditPage = (props: ProjectsEditProps) => {
  const { t } = useTranslation();

  const history = useHistory();
  const { slug } = useParams<{ slug: any }>();

  const [project, setProject] = useState<ProjectDetail>();
  const [dataFetched, setDataFetched] = useState<boolean>(false);

  const [modalVisible, setModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState('An internal server error occured');

  useEffect(() => {
    if (props.project === undefined && slug !== undefined && !dataFetched) {
      fetchProjectDetail(slug).then((fetchedProject) => {
        if (fetchedProject) {
          setProject(fetchedProject);
          setDataFetched(true);
        }
      });
    }
  }, [dataFetched, props.project, slug]);

  const displayedProject = props.project ?? project;

  const handleNameEdit = (newName: string) => {
    if (displayedProject) {
      displayedProject.name = newName;
    }
  };

  const handleDescEdit = (newDesc: string) => {
    if (displayedProject) {
      displayedProject.desc = newDesc;
    }
  };

  const dismissHander = () => history.goBack();

  const handleModal = (modalOpen: boolean, modalText: string) => {
    setModalContent(modalText);
    setModalVisible(modalOpen);
  };

  const saveHander = async (project: ProjectDetail) => {
    // eslint-disable-next-line no-console
    console.log('handling project save: ', project);
    const saveResponse = await postProject(project);

    if (saveResponse.status >= 200 && saveResponse.status < 300) {
      handleModal(true, `${t('project-edited-successfully')} : ${displayedProject.name}`);
    } else {
      const errMessage = (await saveResponse.json()).message;
      handleModal(true, errMessage || t('an-internal-server-error-occurred'));
    }
  };

  return (
    <PageLayout>
      {displayedProject ? (
        <>
          <Typography.Title level={3}>Edit: {displayedProject.name}</Typography.Title>
          <Descriptions column={2}>
            <Descriptions.Item label={t('number-of-groups')}>{displayedProject.groups.length}</Descriptions.Item>
            <Descriptions.Item label={t('updated-at')}>
              <i>{DateUtils.ts2datestring(displayedProject.updatedAt)}</i>
            </Descriptions.Item>

            {/*<Descriptions.Item label={t('number-of-devices')}>
              {displayedProject.groups.map((g) => g.numDevices).reduce((p, c) => p + c, 0)}
      </Descriptions.Item>*/}
            <Descriptions.Item label={t('updated-by')}>
              <i>{displayedProject.updatedBy}</i>
            </Descriptions.Item>
          </Descriptions>

          <EditorField
            initialContent={displayedProject.name}
            label="Name"
            placeholder="The project name."
            onBlur={(newName: string) => handleNameEdit(newName)}
          />

          <EditorField
            initialContent={displayedProject.desc}
            label={t('description')}
            placeholder={t('a-short-description-of-the-project')}
            fieldHeight={210}
            onBlur={(newDesc: string) => handleDescEdit(newDesc)}
          />

          <DeviceGroupListEditable project={displayedProject} />

          <Styled.ActionBar>
            <Styled.Button icon="saveProject" styling="important" onClick={() => saveHander(displayedProject)}>
              Save
            </Styled.Button>
            <Styled.Button icon="closeBig" styling="important" onClick={() => dismissHander()}>
              Discard
            </Styled.Button>
          </Styled.ActionBar>
        </>
      ) : (
        <LoadingSpinner />
      )}
      <PromptModal
        onClosed={() => history.push('/')}
        content={modalContent}
        onSubmit={() => setModalVisible(false)}
        open={modalVisible}
        submitButtonText={'Close'}
      />
    </PageLayout>
  );
};
