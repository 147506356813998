/* eslint-disable no-console */
import React from "react";
import CodeBox from "qrcode.react";

import Styled from "./QRCode.styled";

export interface QRCodeProps {
  code: string;
  enabled: boolean;
}

export const QRCode = (props: QRCodeProps) => {
  if (!props.enabled) {
    return <Styled.Box />;
  } else {
    return (
      <Styled.Box>
        <CodeBox size={624} value={props.code} renderAs="canvas" level="L" />
      </Styled.Box>
    );
  }
};
