import { styled } from "../../style/theme";
import { Box } from "@droidsolutions/ui-components";

export default {
  Box: styled(Box)`
    background-color: #fff;
    box-shadow: none;
    display: flex;
    flex-flow: row wrap;
    align-content: center;
    justify-content: center;
  `,
};
