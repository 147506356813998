import { antDesignOverridesCss, globalStyleCss, theme as uiComponentsTheme } from '@droidsolutions/ui-components';
import baseStyled, { createGlobalStyle, ThemedStyledInterface } from 'styled-components';

import { tint } from 'polished';

const kuldigBlue = uiComponentsTheme.color.kuldigBlue;

export const theme = {
  ...uiComponentsTheme,
  color: {
    ...uiComponentsTheme.color,
    kuldigBlue,
    highlight: kuldigBlue,
    highlight10: tint(0.9)(kuldigBlue),
    highlight20: tint(0.8)(kuldigBlue),
    highlight50: tint(0.5)(kuldigBlue),
  },

  creation: {
    footer: {
      height: 42,
    },
    header: {
      height: 70,
      margin: 28,
    },
  },
  dashboard: {
    actionBar: {
      height: 63,
      zIndex: 1,
    },
    footer: {
      height: 300,
      overflowInVh: 5,
    },
    header: {
      height: 70,
      zIndex: 2,
    },
    projectTile: {
      height: 294,
      margin: 21,
      width: 378,
    },
  },
  datePicker: {
    inputFontSize: 12,
    tableFontSize: 11,
    tableHeaderFontSize: 12,
  },
  header: {
    height: 70,
  },
};

export type Theme = typeof theme;

export const styled = baseStyled as ThemedStyledInterface<Theme>;

export interface ThemedGlobalStyle {
  theme: Theme;
}

export const GlobalStyle = createGlobalStyle<ThemedGlobalStyle>`
${antDesignOverridesCss}
${globalStyleCss}
`;
