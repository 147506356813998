/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/ban-types */

import { message } from "antd";

import Url from "../../lib/utils/Url";
import { getAuthHeaders } from "../../lib/auth/headers";
import { RegisterQRCodeResponse, RegisterDeviceResponse } from "./Device.types";

/** Generate a new QRCode on the backend */
export const putQRCode = async (
  groupSlug: string,
  setRegistrationId: Function,
  wifi_ssid: string,
  wifi_pw: string,
  wifi_type: string
) => {
  const qrCodeUrl = Url.api(`registration/qrcode/${groupSlug}`);
  const fetched = await fetch(qrCodeUrl, {
    method: "PUT",
    mode: "cors",
    cache: "no-cache",
    headers: getAuthHeaders(),
    body: JSON.stringify({
      wifi_active: true,
      wifi_ssid: wifi_ssid,
      wifi_pw: wifi_pw,
      wifi_type: wifi_type,
    }),
  });

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const result = (await fetched.json()) as RegisterQRCodeResponse;

  if (result.success && result.qrCode) {
    setRegistrationId(result.registrationId || "");
    return result.qrCode;
  } else {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const errMessage =
      result.message || "Network error while fetching registration code!";

    throw new Error(errMessage);
  }
};

/** Generates a fake HwID for debugging purposes */
export const getFakeHardwareId = (length: number) => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

/** Registers a device */
export const putRegistration = async (
  qrCode: string,
  registrationId: string
) => {
  console.log("Posting Fake Registration for: ", qrCode);
  const registerNewUrl = Url.api(`registration/new-ne/${registrationId}`);

  const registrationBody = {
    hwId: getFakeHardwareId(16),
    type: "Android",
    osVersion: "9.2",
    registrationId: registrationId,
  };

  const fetched = await fetch(registerNewUrl, {
    method: "PUT",
    mode: "cors",
    cache: "no-cache",
    body: JSON.stringify(registrationBody),
    headers: getAuthHeaders(),
  });

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const result = (await fetched.json()) as RegisterDeviceResponse;

  if (!result.success) {
    return result;
  } else {
    throw new Error("The registration code was refused by the server!");
  }
};

/** Registers a device */
export const newRegistrationHandler = async (
  qrCode: string,
  register: Function,
  registrationId: string
) => {
  try {
    await putRegistration(qrCode, registrationId);
    register(true);
  } catch (err) {
    message.error(
      err.message || "Network error while fetching registration status"
    );
  }
};
