/* eslint-disable @typescript-eslint/no-unsafe-return */

import { LoadingSpinner, NoData } from '@droidsolutions/ui-components';

import React, { FC, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Tabs, Typography } from 'antd';

import { DataTable } from './DataTable/DataTable';
import { PageLayout } from '../shared/layout/PageLayout';
import { StatisticsResponse } from './Statistics.types';
import { StaticDataTimeline } from './StaticDataTimeline';
import { Maybe } from '../../types/Monads';
import { DeviceMetaDataDisplay } from '../device/DeviceMetaDataDisplay';
import { BatteryChart } from './BatteryChart/BatteryChart';
import { InstalledAppsChart } from './InstalledAppsChart/InstalledAppsChart';

import { getAuthHeaders } from '../../lib/auth/headers';
import Url from '../../lib/utils/Url';
import { TrackingMap } from './TrackingMap/TrackingMap';
// import { TrackingMap } from "./TrackingMap/TrackingMap";

/**
 * Makes an POST-query for statistical data.
 */
export const fetchDeviceStatistics = async (deviceId: string): Promise<Maybe<StatisticsResponse>> => {
  const statisticsUrl = Url.api(`status-ping/statistics/${deviceId}`);

  const apiReturn = await fetch(statisticsUrl, {
    method: 'POST',
    headers: getAuthHeaders(),
    cache: 'no-cache',
    mode: 'cors',
  });

  if (apiReturn.status === 200) {
    return apiReturn.json();
  } else {
    throw new Error('Network error while fetching device statistics!');
  }
};

export const DeviceStatisticsPage: FC = () => {
  // const history = useHistory();
  const { deviceId } = useParams<{ deviceId: string }>();

  const [selectedStatistics, setSelectedStatistics] = useState<Maybe<StatisticsResponse>>();

  useEffect(() => {
    if (selectedStatistics === undefined && deviceId !== undefined) {
      fetchDeviceStatistics(deviceId).then((newStats) => {
        if (newStats) {
          setSelectedStatistics(newStats);
        }
      });
    }
  }, [selectedStatistics, deviceId]);

  const hasStaticData = selectedStatistics ? Object.values(selectedStatistics.stats.stats).length > 0 : false;

  const hasGeographicData = selectedStatistics
    ? Object.values(selectedStatistics.stats.stats).filter((v) => v.position !== undefined).length > 0
    : false;

  const dataSource = [
    {
      key: '1',
      name: 'Mike',
      age: 32,
      address: '10 Downing Street',
    },
    {
      key: '2',
      name: 'John',
      age: 42,
      address: '10 Downing Street',
    },
  ];

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Age',
      dataIndex: 'age',
      key: 'age',
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
    },
  ];

  return (
    <PageLayout>
      {deviceId && selectedStatistics ? (
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab="Statistics" key="1">
            <Typography.Title level={2}>Statistics for device: {deviceId}</Typography.Title>
            <Typography.Title level={3}>Metadata</Typography.Title>
            <DeviceMetaDataDisplay span={8} deviceId={deviceId} />

            <hr />
            {hasStaticData ? (
              <>
                <Typography.Title level={4}>Battery Level</Typography.Title>
                <BatteryChart data={selectedStatistics} />
                <Typography.Title level={4}>Installed Apps</Typography.Title>
                <InstalledAppsChart data={selectedStatistics} />
              </>
            ) : (
              <NoData type="bar" />
            )}

            <hr />
            <Typography.Title level={3}>Geographic Data</Typography.Title>
            <TrackingMap data={selectedStatistics.stats}></TrackingMap>
            {/* {hasGeographicData ? (
            <TrackingMap
              zoomLvl={13}
              data={selectedStatistics.stats}
              xyzLayerUrl="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attributionMessage="&copy; <a href='http://osm.org/copyright'>OpenStreetMap</a> contributors"
            />
          ) : (
            <NoData type="bar" />
          )} */}

            <hr />
            {hasStaticData ? (
              <>
                <Typography.Title level={3}>Static Data</Typography.Title>
                <StaticDataTimeline stats={selectedStatistics.stats} />
              </>
            ) : (
              <NoData type="bar" />
            )}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Data Table" key="2">
            <Typography.Title level={2}>Data Table for device: {deviceId}</Typography.Title>
            {hasStaticData ? (
              <>
                <Typography.Title level={4}>Data Table</Typography.Title>
                <DataTable data={selectedStatistics} />
              </>
            ) : (
              <NoData type="bar" />
            )}
          </Tabs.TabPane>
        </Tabs>
      ) : (
        <LoadingSpinner />
      )}
    </PageLayout>
  );
};
