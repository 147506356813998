import { styled } from "../../style/theme";
import { List } from "antd";
import { Box, Button } from "@droidsolutions/ui-components";

export default {
  Box: styled(Box)`
    margin-top: 24px;
    padding: 24px;
  `,

  Meta: styled(List.Item.Meta)`
    display: flex;
    flex-direction: row;
  `,

  RegistrationButton: styled(Button)`
    width: 190px;
    margin-right: 24px;
  `,

  ConfigButton: styled(Button)`
    width: 190px;
    margin-right: 24px;
  `,

  ActionBar: styled(Box)`
    margin-left: 24px;
    box-shadow: none;
    display: flex;
    flex-flow: row wrap;
    align-content: center;
    justify-content: start;
  `,
  SearchBox: styled.div`
    display: flex;
    background-color: #fff;
    box-shadow: none;
    display: flex;
    margin-bottom: 0px;
    align-content: center;
    justify-content: space-between;
  `,
  Input: styled.input`
    border-radius: 4px;
    border: solid 1px ${(props) => props.theme.color.primary};
    color: ${(props) => props.theme.color.primary};
    font-size: 18px;
    height: 40px;
    width: 300px;
    padding: 0 5px 0 5px;
    margin: 14px;

    :focus {
      outline: none;
    }

    ::placeholder {
      color: ${(props) => props.theme.color.primary25};
      font-style: italic;
      text-align: center;
    }
  `,
};
