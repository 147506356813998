import React, { FC } from "react";
import { red, green } from "@ant-design/colors";
import { Device } from "../../device/Device.types";

export interface DeviceRegisteredProps {
  device?: Device;
  state?: boolean;
}

export const DeviceRegisteredIcon: FC<DeviceRegisteredProps> = (
  props: DeviceRegisteredProps
) => {
  if (props.state || props.device?.registered) {
    return <span style={{ color: green.primary }}>✓</span>;
  } else {
    return <span style={{ color: red.primary }}>✗</span>;
  }
};
