/** creates a date string from a number */
export const toDateString = (n: number | undefined) =>
  n !== undefined
    ? `${new Date(n).toLocaleDateString()} (${new Date(
        n
      ).toLocaleTimeString()})`
    : "";

/** creates a date string from a number */
export const toTimeString = (n: number | undefined) =>
  n !== undefined ? new Date(n).toLocaleTimeString() : "";
