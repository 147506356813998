import { getAuthHeaders } from "../../lib/auth/headers";
import {
  DeviceMetaData,
  DeviceSettings,
} from "../settings/DeviceSettings.types";

import Url from "../../lib/utils/Url";
import { Maybe } from "../../types/Monads";
import { checkResponseStatus } from "../../lib/api-errorhandler";

const resourceName = "device settings";
/**
 *
 * @returns the meta data for a single device, identified by its URL.
 */
export const fetchDeviceMetaData = async (
  deviceId: string
): Promise<Maybe<DeviceMetaData>> => {
  const deviceSettingsUrl = Url.api(`metadata/${deviceId}`);
  const response = await fetch(deviceSettingsUrl, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    headers: getAuthHeaders(),
  });

  const responseOK = checkResponseStatus(
    "device meta data",
    deviceId,
    response
  );

  if (responseOK) {
    return response.json() as Promise<DeviceMetaData>;
  }
};

/**
 * @returns the settings document for a single device, identified by its URL.
 */
export const fetchDeviceSettings = async (
  deviceId: string
): Promise<Maybe<DeviceSettings>> => {
  const deviceSettingsUrl = Url.api(`device/ne/${deviceId}`);
  const response = await fetch(deviceSettingsUrl, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    headers: getAuthHeaders(),
  });

  const responseOK = checkResponseStatus(resourceName, deviceId, response);

  if (responseOK) {
    return response.json() as Promise<DeviceSettings>;
  }
};

/**
 * @returns true or false for posting the settings document for a device, identified by its URL
 */
export const postDeviceSettings = async (
  deviceId: string,
  deviceSetting: DeviceSettings
): Promise<Maybe<DeviceSettings>> => {
  const deviceSettingsUrl = Url.api(`device/for-device-ne/${deviceId}`);
  const response = await fetch(deviceSettingsUrl, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    headers: getAuthHeaders(),
    body: JSON.stringify(deviceSetting),
  });

  const responseOK = checkResponseStatus(resourceName, deviceId, response);

  if (responseOK) {
    return response.json() as Promise<DeviceSettings>;
  } else {
    return null;
  }
};
