import { styled } from '../../style/theme';
import { Box, Button } from '@droidsolutions/ui-components';

export default {
  Outer: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    align-content: flex-start;
  `,

  Section: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    align-content: flex-start;
  `,

  Inner: styled.div`
    width: 350px;
    flex-basis: 24px;
    margin: 12px 12px;
  `,

  Box: styled(Box)`
    margin: 12px 12px;
    padding: 24px 36px;
    border: none;
  `,

  ActionBar: styled(Box)`
    margin-left: 12px;
    box-shadow: none;
    display: flex;
    flex-flow: row wrap;
    align-content: center;
    justify-content: start;
    border: none;
  `,

  Button: styled(Button)`
    width: 90px;
    margin-right: 24px;
    border: none;
  `,
};
