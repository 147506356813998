/* eslint-disable max-len */
import React, { useState, FC } from 'react';
import { Col, Row, Typography, message, Tooltip } from 'antd';
import { Checkbox } from '@droidsolutions/ui-components';
import { InfoCircleOutlined, InfoCircleTwoTone } from '@ant-design/icons';

import { KnoxConfig } from './DeviceSettings.types';
import { SmallDescription } from '../shared/SmallDescription';
import { useTranslation } from 'react-i18next';

import Styled from './Settings.styled';

export interface KnoxConfigProps {
  settings: KnoxConfig;
  updateSettings: (updated: KnoxConfig) => void;
}

/**
 * Panel for the MDM Configuration.
 */
export const KnoxConfigPanel: FC<KnoxConfigProps> = (props: KnoxConfigProps) => {
  const { t } = useTranslation();

  const [knoxEnabled, setKnoxEnabled] = useState<boolean>(props.settings?.knox_active ?? false);
  const [prokioskEnabled, setProkioskEnabled] = useState<boolean>(props.settings?.prokiosk_active ?? false);
  const [btnPowerEnabled, setBtnPowerEnabled] = useState<boolean>(props.settings?.hardwareButtonPOWER?.enabled ?? true);
  const [btnVolumeEnabled, setBtnVolumeEnabled] = useState<boolean>(
    props.settings?.hardwareButtonVOLUME?.enabled ?? true
  );
  const [btnPTTEnabled, setBtnPTTEnabled] = useState<boolean>(props.settings?.hardwareButtonPTT?.enabled ?? true);
  const [btnEmergencyEnabled, setBtnEmergencyEnabled] = useState<boolean>(
    props?.settings.hardwareButtonEMERGENCY?.enabled ?? true
  );
  const [btnHomeEnabled, setBtnHomeEnabled] = useState<boolean>(props.settings?.hardwareButtonHOME?.enabled ?? true);
  const [btnMenuEnabled, setBtnMenuEnabled] = useState<boolean>(props.settings?.hardwareButtonMENU?.enabled ?? true);
  const [btnAppSwitchEnabled, setBtnAppSwitchEnabled] = useState<boolean>(
    props.settings?.hardwareButtonAPP_SWITCH?.enabled ?? true
  );

  return (
    <>
      <Typography.Title level={3}>{t('knox-settings')}</Typography.Title>
      <hr />

      <Styled.Row>
        <Col span={4}>
          <Tooltip title={t('knox-enabled-description')}>
            <Typography.Text>{t('knox-enabled')}</Typography.Text> <InfoCircleTwoTone />
          </Tooltip>
        </Col>
        <Col span={20}>
          <Checkbox
            label=""
            checked={knoxEnabled}
            onChange={(updated: boolean) => {
              setKnoxEnabled(updated);
              props.settings.knox_active = updated;

              props.updateSettings(props.settings);
            }}
          />
        </Col>
      </Styled.Row>
      <Styled.Row>
        <Col span={4}>
          <Tooltip title={t('prokiosk-enabled-description')}>
            <Typography.Text>{t('prokiosk-enabled')}</Typography.Text> <InfoCircleTwoTone />
          </Tooltip>
        </Col>
        <Col span={20}>
          <Checkbox
            label=""
            checked={prokioskEnabled}
            onChange={(updated: boolean) => {
              setProkioskEnabled(updated);
              props.settings.prokiosk_active = updated;

              props.updateSettings(props.settings);
            }}
          />
        </Col>
      </Styled.Row>
      <Styled.Row>
        <Col span={4}>
          <Tooltip title={t('knox-btn-power-enabled-description')}>
            <Typography.Text>{t('knox-btn-power-enabled')}</Typography.Text> <InfoCircleTwoTone />
          </Tooltip>
        </Col>
        <Col span={20}>
          <Checkbox
            label=""
            checked={btnPowerEnabled}
            onChange={(updated: boolean) => {
              setBtnPowerEnabled(updated);
              if (props.settings.hardwareButtonPOWER === undefined) {
                props.settings.hardwareButtonPOWER = { enabled: updated };
              }
              props.settings.hardwareButtonPOWER.enabled = updated;

              props.updateSettings(props.settings);
            }}
          />
        </Col>
      </Styled.Row>
      <Styled.Row>
        <Col span={4}>
          <Tooltip title={t('knox-btn-volume-enabled-description')}>
            <Typography.Text>{t('knox-btn-volume-enabled')}</Typography.Text> <InfoCircleTwoTone />
          </Tooltip>
        </Col>
        <Col span={20}>
          <Checkbox
            label=""
            checked={btnVolumeEnabled}
            onChange={(updated: boolean) => {
              setBtnVolumeEnabled(updated);
              if (props.settings.hardwareButtonVOLUME === undefined) {
                props.settings.hardwareButtonVOLUME = { enabled: updated };
              }
              props.settings.hardwareButtonVOLUME.enabled = updated;

              props.updateSettings(props.settings);
            }}
          />
        </Col>
      </Styled.Row>
      <Styled.Row>
        <Col span={4}>
          <Tooltip title={t('knox-btn-ptt-enabled-description')}>
            <Typography.Text>{t('knox-btn-ptt-enabled')}</Typography.Text> <InfoCircleTwoTone />
          </Tooltip>
        </Col>
        <Col span={20}>
          <Checkbox
            label=""
            checked={btnPTTEnabled}
            onChange={(updated: boolean) => {
              setBtnPTTEnabled(updated);
              if (props.settings.hardwareButtonPTT === undefined) {
                props.settings.hardwareButtonPTT = { enabled: updated };
              }
              props.settings.hardwareButtonPTT.enabled = updated;

              props.updateSettings(props.settings);
            }}
          />
        </Col>
      </Styled.Row>
      <Styled.Row>
        <Col span={4}>
          <Tooltip title={t('knox-btn-home-enabled-description')}>
            <Typography.Text>{t('knox-btn-home-enabled')}</Typography.Text> <InfoCircleTwoTone />
          </Tooltip>
        </Col>
        <Col span={20}>
          <Checkbox
            label=""
            checked={btnHomeEnabled}
            onChange={(updated: boolean) => {
              setBtnHomeEnabled(updated);
              if (props.settings.hardwareButtonHOME === undefined) {
                props.settings.hardwareButtonHOME = { enabled: updated };
              }
              props.settings.hardwareButtonHOME.enabled = updated;

              props.updateSettings(props.settings);
            }}
          />
        </Col>
      </Styled.Row>

      <Styled.Row>
        <Col span={4}>
          <Tooltip title={t('knox-btn-menu-enabled-description')}>
            <Typography.Text>{t('knox-btn-menu-enabled')}</Typography.Text> <InfoCircleTwoTone />
          </Tooltip>
        </Col>
        <Col span={20}>
          <Checkbox
            label=""
            checked={btnMenuEnabled}
            onChange={(updated: boolean) => {
              setBtnMenuEnabled(updated);
              if (props.settings.hardwareButtonMENU === undefined) {
                props.settings.hardwareButtonMENU = { enabled: updated };
              }
              props.settings.hardwareButtonMENU.enabled = updated;

              props.updateSettings(props.settings);
            }}
          />
        </Col>
      </Styled.Row>

      <Styled.Row>
        <Col span={4}>
          <Tooltip title={t('knox-btn-app-switch-enabled-description')}>
            <Typography.Text>{t('knox-btn-app-switch-enabled')}</Typography.Text> <InfoCircleTwoTone />
          </Tooltip>
        </Col>
        <Col span={20}>
          <Checkbox
            label=""
            checked={btnAppSwitchEnabled}
            onChange={(updated: boolean) => {
              setBtnAppSwitchEnabled(updated);
              if (props.settings.hardwareButtonAPP_SWITCH === undefined) {
                props.settings.hardwareButtonAPP_SWITCH = { enabled: updated };
              }
              props.settings.hardwareButtonAPP_SWITCH.enabled = updated;

              props.updateSettings(props.settings);
            }}
          />
        </Col>
      </Styled.Row>

      <Styled.Row>
        <Col span={4}>
          <Tooltip title={t('knox-btn-emergency-enabled-description')}>
            <Typography.Text>{t('knox-btn-emergency-enabled')}</Typography.Text> <InfoCircleTwoTone />
          </Tooltip>
        </Col>
        <Col span={20}>
          <Checkbox
            label=""
            checked={btnEmergencyEnabled}
            onChange={(updated: boolean) => {
              setBtnEmergencyEnabled(updated);
              if (props.settings.hardwareButtonEMERGENCY === undefined) {
                props.settings.hardwareButtonEMERGENCY = { enabled: updated };
              }
              props.settings.hardwareButtonEMERGENCY.enabled = updated;

              props.updateSettings(props.settings);
            }}
          />
        </Col>
      </Styled.Row>
    </>
  );
};
