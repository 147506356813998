import React from "react";
import { Link } from "react-router-dom";

import config from "../../config";

export interface Slugged {
  name: string;
  slug: string;
}

/**
 * Url helper utilities, provides a canonical way to create URLs with pre configured hostnames.
 */
export default class Url {
  public static idpBaseUrl = config.IP_API;
  public static mdmApi = config.MDM_API;
  public static host = config.BASE_URL;

  public static getBaseUrl = () => Url.host;

  public static getApiUrl = () => Url.mdmApi;

  public static slug2url = (slug: string, section: string) =>
    section ? `/${section}/${slug}` : slug;

  public static slug2link = (s: Slugged, section: string) => (
    <Link to={Url.slug2url(s.slug, section)}>{s.name}</Link>
  );

  public static to = (path: string) => `${Url.getBaseUrl()}/${path}`;

  public static api = (path: string) => `${Url.getApiUrl()}/${path}`;

  public static logout = () => `${Url.idpBaseUrl}/session/end`;
}
