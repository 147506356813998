import React, { FC } from "react";
import { LineChart, CartesianGrid, XAxis, Legend, Line, YAxis } from "recharts";

import { BatteryLevelsCollection } from "./BatteryChart.types";
import {
  MonitoringInformation,
  StatisticsData,
  StatisticsResponse,
} from "../Statistics.types";
import { toTimeString } from "../time";

const deviceStats2BatteryData = (
  data: StatisticsData
): BatteryLevelsCollection => {
  const baseData = Object.values(data.stats);
  const sorted = baseData.sort((a, b) => a.date - b.date);
  return {
    collection: sorted.map((entry: MonitoringInformation) => ({
      date: toTimeString(entry.date),
      blevel: entry.battery_level || 0,
    })),
  };
};

/** Statistics item: statistics collections */
export interface BatteryChartProps {
  data: StatisticsResponse;
}

export const BatteryChart: FC<BatteryChartProps> = (
  props: BatteryChartProps
) => {
  const batteryData = deviceStats2BatteryData(props.data.stats);

  return (
    <>
      <LineChart
        width={900}
        height={300}
        data={batteryData.collection}
        margin={{ top: 25, right: 55, left: 25, bottom: 35 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="date"
          interval="preserveStartEnd"
          angle={30}
          dx={20}
          dy={20}
        />
        <YAxis interval="preserveStartEnd" domain={[0, 100]} />
        <Legend verticalAlign="top" />
        <Line
          name="Battery Level"
          type="monotone"
          dataKey="blevel"
          stroke="#1890ff"
          activeDot={{ r: 8 }}
        />
      </LineChart>
    </>
  );
};
