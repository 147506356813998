import React, { FC } from "react";
import { Button } from "@droidsolutions/ui-components";

export interface DeleteButtonProps {
  index: number;
  onClick: (i: number) => void;
}

export const DeleteButton: FC<DeleteButtonProps> = (
  props: DeleteButtonProps
) => {
  return <Button icon="delete" onClick={() => props.onClick(props.index)} />;
};
