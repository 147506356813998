import React, { useEffect, useState, FC } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Tabs, Typography, message } from 'antd';

import { LoadingSpinner, Modal, PromptModal } from '@droidsolutions/ui-components';
import { fetchDeviceSettings, postDeviceSettings } from './DeviceSettings.apifunctions';

import { PageLayout } from '../shared/layout/PageLayout';
import { MDMAppConfigPanel } from '../settings/MDMAppConfigPanel';
import { KioskAppConfigPanel } from '../settings/KioskAppConfigPanel';
import { BrowserSettingsPanel } from '../settings/BrowserSettingsPanel';
import { BaseSettingsPanel } from '../settings/BaseSettingsPanel';
import { MediaAppConfigPanel } from '../settings/MediaAppConfigPanel';
import { AppPermissionConfigPanel } from '../settings/AppPermissionConfigPanel';
import { DeviceConfigurationPanel } from '../settings/DeviceConfigurationPanel';

import { Maybe } from '../../types/Monads';
import {
  DeviceSettings,
  MDMAppConfig,
  KioskAppColorConfig,
  KioskAppConfig,
  BrowserConfig,
  DeviceConfigurationDTO,
  MediaAppConfig,
  AppPermissionConfig,
  DeviceMetaData,
} from '../settings/DeviceSettings.types';
import Styled from './DeviceSettingsPage.styled';
import { DeviceMetaDataDisplay } from '../device/DeviceMetaDataDisplay';
import { useTranslation } from 'react-i18next';

const { TabPane } = Tabs;

const uninitializedSaveErrorMessage = 'Warning, settings document is undefined!';

const postSettingsSuccessMessage = 'Successfully updated device Settings!';

const postSettingsFailMessage = 'Failed to updat device Settings!';

// Defaults for Maybe<T> values
const kioskColorConfig: KioskAppColorConfig = {
  button_color: '#00F',
  button_text_color: '#FFF',
  text_heading_color: '#33C',
  text_normal_color: '#333',
  background_color: '#FFF',
};

const defaultKioskSettings: KioskAppConfig = {
  main_icon: '',
  main_text: '',
  show_kuldig_brand: true,
  colors: kioskColorConfig,
};

const defaultBrowserSettings: BrowserConfig = {
  browser_domain_whitelist: [],
  colors: {
    button_color: '#000000',
    navigationbar_background: '#FFFFFF',
    navigationbar_label: '#FFFFFF',
    background_color: '#FFFFFF',
  },
  start_url: '',
  navigationbar_label: 'Navigation',
  show_label: true,
  navigationbar_active: false,
  navigation_button_whitelist: [],
};

export const DeviceSettingsPage: FC = (props) => {
  const { t } = useTranslation();

  const history = useHistory();
  const { deviceId } = useParams<{ deviceId: string }>();

  // stateful object: main settings json
  const [settings, setSettings] = useState<DeviceSettings>();

  const [postModalActive, setPostSettingsModalActive] = useState<boolean>(false);

  // varibales to handle prompt modal
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState<string>(t('an-internal-server-error-occurred'));

  // varibale to render save modal and discard modal
  const [saveModal, setSaveModal] = useState(false);

  // stateful params for tab-panel
  const [kioskTabActive, setKioskTabActive] = useState<boolean>(false);
  const [browserTabActive, setBrowserTabActive] = useState<boolean>(false);

  useEffect(() => {
    if (settings === undefined && deviceId !== undefined) {
      fetchDeviceSettings(deviceId).then((fetchedSettings) => {
        if (fetchedSettings) {
          setSettings(fetchedSettings);
          setKioskTabActive(fetchedSettings.mdm_app.kiosk_mode_active);
          setBrowserTabActive(fetchedSettings.mdm_app.browser_mode_active);
        }
      });
    }
  }, [settings, deviceId]);

  /**
   * Handles the case that the updated settings object is returned successfully and well-defined.
   */
  const handlePostSuccess = (updatedSettings: DeviceSettings) => {
    handleModal(true, postSettingsSuccessMessage);
    setSettings(updatedSettings);
  };

  const handleModal = (showModal: boolean, modalText: string) => {
    setShowModal(showModal);
    setModalContent(modalText);
  };

  const handleDiscard = () => {
    setShowModal(true);
    setModalContent(t('do-you-want-to-discard-unsaved-changes'));
  };

  /**
   * POSTs the settings document to the backend.
   *
   * @param deviceId the id of the device that should be POSTed to the backend
   * @param settings the settings JSON document
   *
   * TODO: get rid of timeout
   */
  const saveHandler = (deviceId: Maybe<string>, settings: Maybe<DeviceSettings>): void => {
    if (deviceId && settings) {
      setSaveModal(true);
      setPostSettingsModalActive(true);
      setTimeout(() => {
        postDeviceSettings(deviceId, settings)
          .then((updatedSettings) => {
            updatedSettings ? handlePostSuccess(updatedSettings) : handleModal(true, postSettingsFailMessage); //message.error(postSettingsFailMessage);
          })
          .catch((err) => message.error(err))
          .finally(() => setPostSettingsModalActive(false));
      }, 5000);
    } else {
      message.error(uninitializedSaveErrorMessage);
    }
  };

  return (
    <PageLayout>
      <Typography.Title level={2}>
        {t('device-settings')}: {deviceId}
      </Typography.Title>
      <Styled.Box>
        <Styled.Button icon="saveProject" styling="important" onClick={() => saveHandler(deviceId, settings)}>
          {t('save')}
        </Styled.Button>
        <Styled.Button icon="closeBig" styling="important" onClick={handleDiscard}>
          {t('discard')}
        </Styled.Button>
      </Styled.Box>
      {settings ? (
        <>
          <Modal open={postModalActive}>
            <LoadingSpinner />
          </Modal>

          <DeviceMetaDataDisplay span={4} deviceId={deviceId} />

          <Tabs defaultActiveKey="1">
            <TabPane tab={t('base-settings')} key="1">
              <BaseSettingsPanel
                settings={settings}
                updateSettings={(updated: DeviceSettings) => setSettings(updated)}
              />
            </TabPane>

            <TabPane tab={t('mdm-settings')} key="2">
              <MDMAppConfigPanel
                settings={settings.mdm_app}
                updateSettings={(updated: MDMAppConfig) => {
                  settings.mdm_app = updated;
                  setSettings(settings);
                  setKioskTabActive(updated.kiosk_mode_active);
                  setBrowserTabActive(updated.browser_mode_active);
                }}
              />
            </TabPane>

            <TabPane tab={t('kiosk-settings')} key="3" disabled={!kioskTabActive}>
              <KioskAppConfigPanel
                settings={settings.kiosk_configuration || defaultKioskSettings}
                updateSettings={(updated: KioskAppConfig) => {
                  settings.kiosk_configuration = updated;
                  setSettings(settings);
                }}
              />
            </TabPane>

            <TabPane tab={t('browser-settings')} key="4" disabled={!browserTabActive}>
              <BrowserSettingsPanel
                settings={settings.browser_configuration || defaultBrowserSettings}
                updateSettings={(updated: BrowserConfig) => {
                  settings.browser_configuration = updated;
                  setSettings(settings);
                }}
              />
            </TabPane>

            <TabPane tab={t('media-settings')} key="5">
              <MediaAppConfigPanel
                settings={settings.media_apps}
                updateSettings={(updated: MediaAppConfig[]) => {
                  settings.media_apps = updated;
                  setSettings(settings);
                }}
              />
            </TabPane>

            <TabPane tab={t('permissions-settings')} key="6">
              <AppPermissionConfigPanel
                settings={settings.permissions}
                updateSettings={(updated: AppPermissionConfig[]) => {
                  settings.permissions = updated;
                  setSettings(settings);
                }}
              />
            </TabPane>

            <TabPane tab={t('hardware-configuration')} key="7">
              <DeviceConfigurationPanel
                settings={settings.configuration}
                updateSettings={(updated: DeviceConfigurationDTO) => {
                  settings.configuration = updated;
                  setSettings(settings);
                }}
              />
            </TabPane>
          </Tabs>
        </>
      ) : (
        <LoadingSpinner />
      )}

      <PromptModal
        cancelButtonText={!saveModal ? t('cancel') : undefined}
        onCancel={() => setShowModal(false)}
        onClosed={() => {
          setSaveModal(false);
          if (saveModal) {
            history.go(0);
          }
        }}
        content={modalContent}
        onSubmit={() => {
          if (!saveModal) {
            setShowModal(false);
          } else {
            setShowModal(false);
            history.go(0);
          }
        }}
        open={showModal}
        submitButtonText={!saveModal ? t('yes') : t('close')}
      />
    </PageLayout>
  );
};
